import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, Card, CardContent } from "@mui/material";
import "./Dashboard.css"; // Custom CSS for the design
import Header from "../../components/Header";

const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Updated routes with multiple locations
  const tabsData = [
    {
      tab: "Order",
      backgroundColor: "#f9f9f9",
      routes: [
        {
          route: "JYT-BHT",
          locations: [
            { name: "Jyothi", status: "completed" },
            { name: "Bharath Mall", status: "completed" },
            { name: "Hampankatta", status: "in-progress" },
            { name: "Pandeshwar", status: "pending" },
            { name: "Kudroli", status: "pending" },
          ],
        },
        {
          route: "MAQ-ERS",
          locations: [
            { name: "Mangaluru Central", status: "completed" },
            { name: "Kankanady", status: "completed" },
            { name: "Padil", status: "in-progress" },
            { name: "Kadri", status: "pending" },
            { name: "Falnir", status: "pending" },
          ],
        },
      ],
    },
    {
      tab: "Delivery",
      backgroundColor: "#eef6ff",
      routes: [
        {
          route: "HMK-TPD",
          locations: [
            { name: "Hampankatta", status: "completed" },
            { name: "Tokkottu", status: "in-progress" },
            { name: "Jeppu", status: "pending" },
            { name: "Kulshekar", status: "pending" },
            { name: "Nantoor", status: "pending" },
          ],
        },
        {
          route: "LHV-BJP",
          locations: [
            { name: "Lalbagh", status: "completed" },
            { name: "Bejai", status: "pending" },
            { name: "Kavoor", status: "pending" },
            { name: "Derebail", status: "pending" },
            { name: "Urwa Store", status: "pending" },
          ],
        },
      ],
    },
    {
      tab: "Collection",
      backgroundColor: "#fff5f5",
      routes: [
        {
          route: "DER-SKR",
          locations: [
            { name: "Derebail", status: "completed" },
            { name: "Surathkal", status: "completed" },
            { name: "Kulur", status: "completed" },
            { name: "Baikampady", status: "in-progress" },
            { name: "Panambur", status: "pending" },
          ],
        },
        {
          route: "KPT-KUL",
          locations: [
            { name: "Kulur", status: "completed" },
            { name: "KPT (Kadri Park)", status: "completed" },
            { name: "Bondel", status: "completed" },
            { name: "Urwa", status: "in-progress" },
            { name: "Ashok Nagar", status: "pending" },
          ],
        },
      ],
    },
  ];

  return (
    <Box m="20px">
       <Header title="Dashboard" subtitle="" />

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="Dashboard Tabs"
        style={{ marginBottom: "20px" }}
      >
        {tabsData.map((tab, index) => (
          <Tab key={index} label={tab.tab} />
        ))}
      </Tabs>

      {tabsData[selectedTab].routes.map((route, routeIndex) => (
        <Card
          key={routeIndex}
          className="status-card"
          style={{ backgroundColor: tabsData[selectedTab].backgroundColor }}
        >
          <CardContent>
            <Typography variant="h6">{route.route}</Typography>
            <Box display="flex" alignItems="center" className="horizontal-status">
              {route.locations.map((location, locationIndex) => (
                <React.Fragment key={locationIndex}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    style={{ textAlign: "center" }}
                  >
                    <Box
                      className={`status-circle ${location.status}`}
                      title={`${location.name} - ${location.status}`}
                    ></Box>
                    <Typography
                      variant="caption"
                      style={{ fontSize: "10px", marginTop: "4px" }}
                    >
                      {location.name}
                    </Typography>
                  </Box>
                  {locationIndex < route.locations.length - 1 && (
                    <Box
                      className={`status-connector ${
                        location.status === "completed"
                          ? "completed"
                          : location.status === "in-progress"
                          ? "in-progress"
                          : ""
                      }`}
                    ></Box>
                  )}
                </React.Fragment>
              ))}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default Dashboard;
