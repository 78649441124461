import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  MenuItem,
  IconButton
} from "@mui/material";

import { useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../../constants/global";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";

const ViewLocations = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0); // To manage active tab
  const navigate = useNavigate(); // For page navigation

  const [loading, setLoading] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const defaultCities = [{ city: "Select City", state: "Select State" }];
  const [cities, setCities] = useState(defaultCities);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error
  const [locationOptions, setLocationOptions] = useState(null);
  const [routeOptions, setRouteOptions] = useState(null);

  const [contactId, setContactId] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const location = useLocation();

  const { selectedRows, locations } = location.state || {};

  console.log('selectedRows', selectedRows, locations)

  // When selectedRows change, update the selectedProduct
  useEffect(() => {
    if (!!selectedRows && selectedRows.length === 1) {
      // Assuming selectedRows contains an array with one object that has an 'id' field
      const selectedId = selectedRows[0];
      const location = locations.find((p) => p.id === selectedId);
      setSelectedProduct(location);
    } else {
      setSelectedProduct(null); // Reset if no product is selected
    }
  }, [selectedRows, locations]); // Re-run whenever selectedRows or products change

  console.log('selectedProduct', selectedProduct)
  // Handle dynamic initial values based on selectedProduct
  const initialValues = selectedProduct ? {
    locationCode: selectedProduct ? selectedProduct.code || '' : '',
    locationPincode: selectedProduct ? selectedProduct.zipCode || '' : '',
   locationName: selectedProduct ? selectedProduct.name || '' : '',
   
  } : {
    locationCode: '',
    locationPincode: '',
    locationName: ''  
  };

  const fetchCityState = async (pincode) => {
    setCities(defaultCities);
    console.log("Fetching Customers");
    fetch(
      `${iepaProd.iepaUserSrvAPI}/iepa/api/city/state/list/pincode/${pincode}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to check structure
        // setCustomers(data);
        setCities(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError("Error fetching City State information: " + error.message);
        }
      });
  };

  const fetchLocations = (pincode) => {
    if (pincode && pincode.length === 6) {
      setLoadingLocations(true);
      fetch(
        `${iepaProd.iepaInventorySrvAPI}/inventory/routes/destination/zipcode/${pincode}/org/1`
        // `${iepaUserSrvAPILocal}/inventory/routes/destination/zipcode/${pincode}/org/1`
        // "http://97.74.86.69:8192/inventory/routes/destination/zipcode/${values.pincode}/org/1"
      )
        .then((response) => response.json())

        .then((data) => {
          console.log(data);
          if (data) {
            setLocationOptions(
              data.map((location) => ({
                value: location.id,
                label: location.name,
                route: location.route.id,
                routeName: location.route.name,
              }))
            );
            setRouteOptions(
              data.map((location) => ({
                value: location.route.id,
                label: location.route.name,
              }))
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching locations:", error);
          setLocationOptions([]);
        })
        .finally(() => setLoadingLocations(false));
    }
  };

  const handleClose = () => {
    navigate("/Locations"); // Redirect to /CustomerList
  };

  const handleAddressFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Address Form");
    const data = {
      address_1: values.address,
      city: values.city,
      state: values.state,
      country: values.type,
      postalCode: values.pincode,
      orgId: "1",
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaUserSrvAPI}/customer/save/address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Address added successfully!");
        setError(null);
        resetForm();
        console.log("Address Id:" + data.id);

        setAddressId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  const handleContactFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Address Form");
    const data = {
      email: values.email,
      phone: values.phone,
      orgId: "1",
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaUserSrvAPI}/customer/save/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Contact added successfully!");
        setError(null);
        resetForm();
        console.log("Contact Id:" + data.id);

        setContactId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };


  const handleCustomerFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Customer Form");
    const data = {
      customerNumber: values.customerNo,
      name: values.companyName,
      ownerName: values.ownerName,
      defaultLocation: {
        shipToFlag: 1,
        soldToFlag: 1,
        billToFlag: 1,
        address: { id: addressId },
      },
      contact: { id: contactId },
      orgId: "1",
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaUserSrvAPI}/customer/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Address added successfully!");
        setError(null);
        resetForm();
        console.log("Address Id:" + data.id);

        setAddressId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  const onPinChange = (e) => {
    if (e.target.value && e.target.value.length === 6) {
      fetchCityState(e.target.value);
    }
  };
  useEffect(() => {
    fetchCityState();
    setLoading(true);
  }, []);
  console.log('initialValues', initialValues)
  const tabContent = [
    {
      label: "Location Details",
      content: selectedProduct ? (
        <Formik
          initialValues={initialValues}
          validationSchema={overviewSchema}
          onSubmit={handleCustomerFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log('values', values)}
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="locationCode"
                  label="Location Code"
                  value={values.locationCode || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.locationCode && Boolean(errors.locationCode)}
                  helperText={touched.locationCode && errors.locationCode}
                />
                <TextField
                  fullWidth
                  required
                  id="locationName"
                  label="Location Name"
                  value={values.locationName || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.locationName && Boolean(errors.locationName)}
                  helperText={touched.locationName && errors.locationName}
                />
                <TextField
                  fullWidth
                  id="locationPincode"
                  label="Location Pincode"
                  value={values.locationPincode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.locationPincode && Boolean(errors.locationPincode)}
                  helperText={touched.locationPincode && errors.locationPincode}
                />                
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>

                <Button
                  // type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm();  // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Typography>Loading...</Typography>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="View Location" subtitle="View/Edit Item Locations" />
      <Box display="flex" justifyContent="flex" mb="20px">
        <IconButton onClick={handleClose} sx={{
          position: 'absolute',
          top: 10,
          right: 10,
        }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '16px', marginRight: '5px' }}>
            Close
          </Typography> <CloseIcon />
        </IconButton>
        {/* <Button
          color="secondary"
          variant="contained"
          onClick={() => window.history.back()} // Navigates back in history
          sx={{
            backgroundColor: "black",
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Go Back
        </Button> */}
        <Button
          color="secondary"
          variant="contained"
          // onClick={() => navigate("../ProductList")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Edit
        </Button>
      </Box>
      <Card>
        <CardContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabContent.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{
                  color: activeTab === index ? "black" : colors.blueAccent[700], // Active tab color
                  fontWeight: activeTab === index ? "bold" : "normal", // Optional: Bold for active tab
                }}
              />
            ))}
          </Tabs>

          <Box mt="20px">{tabContent[activeTab].content}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const overviewSchema = yup.object().shape({
  customerNo: yup.string().required("Customer No is required"),
  companyName: yup.string().required("Company Name is required"),
  ownerName: yup.string().required("Owner Name is required"),
});

const contactSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNo: yup
    .string()
    .matches(/^\d+$/, "Phone number is not valid")
    .required("Phone No is required"),
});

const addressSchema = yup.object().shape({
  address: yup.string().required("Address is required"),
  pincode: yup
    .string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits")
    .required("Pincode is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
});



export default ViewLocations;
