import React, { useState, useEffect } from "react";
import { Box, Button, Modal, TextField, Radio } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { iepaProd } from "../../constants/global";
import { useNavigate } from "react-router-dom";
import ApiCall from "../../components/ApiCall";

const itemLocations = [
  { code: "R1", name: "Room No: 1" },
  { code: "R2", name: "Room No: 2" },
  { code: "SR1", name: "Store Room: 1" },
  { code: "SR2", name: "Store Room: 2" },
];

const ProductList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  const [filterText, setFilterText] = useState(""); // Filter text state
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [assignedLocation, setAssignedLocation] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAssignLocation, setSelectedAssignLocation] = useState(null);
  const [products, setProducts] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error
  const [selectedLocationCode, setSelectedLocationCode] = useState(null); // S

  const navigate = useNavigate(); // For page navigation

  const openModal = () => setOpenAssignModal(true);

  const fetchItems = async () => {
    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/items/active/all`)
      .then((response) => response.json())
      // .then((response) => {
      // if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }
      // console.log(response);
      // return response.json(); // Get raw response text
      // })
      .then((data) => {
        try {
          console.log(data);
          // const data = JSON.parse(json); // Attempt to parse JSON
          setProducts(data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          setProducts([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setProducts([]);
      });
  };

  const fetchWarehouse = async () => {
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/container/status/active/org/1`
    )
      .then((response) => response.json())
      .then((data) => {
        try {
          console.log(data);
          // const data = JSON.parse(json); // Attempt to parse JSON
          setWarehouse(data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          setWarehouse([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setWarehouse([]);
      });
  };

  const assignWarehouse = async (data) => {
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/stock/save/org/1`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage("Assign Item to Warehouse added successfully!");
        setError(null);
      })
      .catch((error) => {
        setError("Failed to Assign Item to Warehouse. Please try again.");
        setMessage(null);
      });
  };

  useEffect(() => {
    fetchItems();
  }, []);

  // Filter rows based on filterText
  const filteredRows = products.filter((product) => {
    const searchTerm = filterText.toLowerCase(); // Make sure filterText is always a string
    return (
      product.code?.toLowerCase().includes(searchTerm) ||
      product.itemName?.toLowerCase().includes(searchTerm) ||
      product.sku?.toLowerCase().includes(searchTerm) ||
      product.type?.toLowerCase().includes(searchTerm) ||
      product.brand?.toLowerCase().includes(searchTerm) ||
      product.uom?.toLowerCase().includes(searchTerm) ||
      product.unitWeight?.toString().toLowerCase().includes(searchTerm) ||
      product.standardMrp?.toString().toLowerCase().includes(searchTerm) ||
      // product.shippableFlag?.toLowerCase().includes(searchTerm) ||
      // product.orderableFlag?.toLowerCase().includes(searchTerm) ||
      product.itemStock[0]?.container?.description
        ?.toLowerCase()
        .includes(searchTerm) ||
      product.itemLocation?.toLowerCase().includes(searchTerm) ||
      product.itemStock?.name?.toLowerCase().includes(searchTerm) // Ensure accessing the 'name' property of itemStock
      // product.itemLocation.toLowerCase().includes(searchTerm) // Uncomment if itemLocation is also required
    );
  });

  const columns = [
    { field: "code", headerName: "Code", width: 80 }, // Fixed small width
    {
      field: "itemName",
      headerName: "Item Name",
      flex: 2, // More space for longer names
      cellClassName: "name-column--cell",
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Brand",
      flex: 1,
    },
    {
      field: "uom",
      headerName: "Unit of Measure",
      flex: 1,
    },
    {
      field: "unitWeight",
      headerName: "Unit Weight",
      type: "number",
      width: 120, // Fixed width for numerical data
      headerAlign: "left",
      align: "left",
    },
    {
      field: "standardMrp",
      headerName: "Standard MRP",
      type: "number",
      width: 120, // Fixed width for numerical data
      headerAlign: "left",
      align: "left",
    },
    {
      field: "shippableFlag",
      headerName: "Shippable",
      type: "boolean",
      width: 100, // Fixed small width
      headerAlign: "left",
      align: "left",
    },
    {
      field: "returnableFlag",
      headerName: "Returnable",
      type: "boolean",
      width: 100, // Fixed small width
      headerAlign: "left",
      align: "left",
    },
    {
      field: "itemLocation",
      headerName: "Item Location",
      flex: 1.5, // Adjust flex for dynamic width
      valueGetter: (params, row) =>
        row.itemStock ? row.itemStock[0]?.container.description : "",
    },
    {
      field: "itemCategory",
      headerName: "Item Category",
      flex: 1.5, // Adjust flex for dynamic width
      valueGetter: (params, row) =>
        row.itemStock ? row.itemStock[0]?.name : "",
    },
  ];

  const handleAssignClick = (row) => {
    fetchWarehouse();

    setOpenAssignModal(true);
    setAssignedLocation(row);
  };

  const handleViewClick = (row) => {
    // setSelectedLocation(row.itemLocation);
    setOpenViewModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
    setSearchTerm("");
    setSelectedAssignLocation(null);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectedLocation(null);
  };

  const handleSelectLocation = (location) => {
    setSelectedAssignLocation(location);
    setSelectedLocationCode(location.code); // Update selected location code
  };

  const handleSaveLocation = () => {
    if (selectedAssignLocation) {
      const warehouseData = {
        container: {
          id: selectedAssignLocation.id,
        },
        // "name": "Door 13 Frooti drink tetrapack",
        // "description": "Frooti drink tetrapack stock",
        // code: ,
        item: { id: selectedRows[0] },
        // lot: { id: 1 },
        // quantity: 7000,
        // type: "NEW",
        // status: "ACTIVE",
        // receivedDate: "2024-12-02",
        orgId: "1",
        createdBy: 1,
      };
      assignWarehouse(warehouseData);
      fetchItems();
    }
    handleCloseAssignModal();
  };
  const handleClose = () => {
    setOpenAssignModal(false); // Close the modal by updating the state in the parent
  };

  return (
    <Box m="20px">
      <Header title="Manage Items" subtitle="List of Items in the Inventory" />
      <Box display="flex" justifyContent="flex" mb="20px">
        {/* <Button
          color="secondary"
          variant="contained"
          onClick={() => window.history.back()} // Navigates back in history
          sx={{
            backgroundColor: "black",
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Go Back
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../AddProduct")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          + Add Item
        </Button>
        {/* Button enabled when at least one checkbox is selected */}
        <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1} // Enabled only if exactly one row is selected
          // onClick={() => navigate("../ViewItem")}/
          onClick={() => {
            navigate("/ViewItem", {
              state: { selectedRows, products }, // Passing selectedRows state to ViewItem component
            });
          }}
          sx={{
            backgroundColor:
              selectedRows.length > 0
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          View Item
        </Button>

        {/* Button enabled when exactly one checkbox is selected */}
        <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1} // Enabled only if exactly one row is selected
          sx={{
            backgroundColor:
              selectedRows.length === 1
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => handleAssignClick(selectedRows.row)}
        >
          Assign Item Location
        </Button>
        {/* Button enabled when exactly one checkbox is selected */}
        {/* <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1} // Enabled only if exactly one row is selected
          sx={{
            backgroundColor:
              selectedRows.length === 1
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => handleViewClick(selectedRows.row)}
        >
          View Warehouse Location
        </Button> */}
        {/* TextField for filtering */}
        <TextField
          label="Search"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{ width: "30%", margin: "10px" }}
        />
      </Box>
      <Box
        // m="-25px 0 0 0"
        height="78vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
            whiteSpace: "normal", // Allows text wrapping
            wordWrap: "break-word", // Ensures text breaks properly
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) =>
            setSelectedRows(newSelection)
          }
          // onSelectionModelChange={(newSelection) =>
          //   setSelectedRows(newSelection)
          // }
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      {/* Assign Location Modal */}
      <Modal open={openAssignModal} onClose={handleCloseAssignModal}>
        <Box sx={{ padding: 4, bgcolor: "background.paper", borderRadius: 2 }}>
          <h2>Assign Location</h2>
          <TextField
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Box
            sx={{
              maxHeight: 300,
              overflowY: "auto",
              marginBottom: 2,
              border: "1px solid lightgray",
              borderRadius: 1,
            }}
          >
            {warehouse
              .filter((location) =>
                location.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((location) => (
                <Box
                  key={location.code}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 1,
                    cursor: "pointer", // Add pointer cursor to indicate clickability
                    "&:hover": {
                      backgroundColor: "#f0f0f0", // Highlight item on hover
                    },
                  }}
                  onClick={() => handleSelectLocation(location)}
                >
                  <Radio
                    checked={selectedLocationCode === location.code} // Check if this location is selected
                    onChange={() => handleSelectLocation(location)} // Update selection on radio change
                    value={location.code}
                  />
                  <span>
                    {location.name} (Code: {location.code})
                  </span>
                  {/* <Button onClick={() => handleSelectLocation(location)}>
                    Select
                  </Button> */}
                </Box>
              ))}
          </Box>
          {selectedAssignLocation && (
            <p>
              Selected Location: {selectedAssignLocation.name} (Code:{" "}
              {selectedAssignLocation.code})
            </p>
          )}
          {/* <Button variant="contained" onClick={handleSaveLocation}>
            Save
          </Button> */}

          <Box display="flex" gap={2}>
            <Button
              type="submit"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[400],
                fontSize: "14px",
                fontWeight: "bold",
                margin: "10px",
              }}
              variant="contained"
              onClick={handleSaveLocation}
            >
              Save
            </Button>

            <Button
              type="button" // Change to 'button' instead of 'submit' since you're not submitting the form
              variant="outlined"
              sx={{
                mt: "10px", // Aligns it vertically with the "Save" button
                ml: "10px", // Ensures proper horizontal space between buttons
                fontSize: "14px",
                fontWeight: "bold",
                margin: "10px",
              }}
              onClick={handleClose}
              // Handle cancel action
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* View Location Modal */}
      <Modal open={openViewModal} onClose={handleCloseViewModal}>
        <Box sx={{ padding: 4, bgcolor: "background.paper", borderRadius: 2 }}>
          <h2>View Location</h2>
          <p></p>
          <Button variant="contained" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProductList;
