export const iepaProd = {
  iepaInventorySrvAPI: "https://97.74.86.69:8192",
  iepaOrderMgmtAPI: "https://97.74.86.69:8193",
  iepaUserSrvAPI: "https://97.74.86.69:8191",
};
export const iepaProd2 = {
  iepaInventorySrvAPI: "https://localhost:8480",
  iepaOrderMgmtAPI: "https://localhost:8380",
  iepaUserSrvAPI: "https://localhost:8280",
};
