import {
  Box,
  Button,
  TextField,
  useTheme,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Paper,
  InputAdornment,
  Typography,
  Grid,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { React, useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Switch } from "@mui/material"; // Import the Switch component
import Header from "../../components/Header";
import { Description } from "@mui/icons-material";
import { iepaProd } from "../../constants/global";
import CloseIcon from "@mui/icons-material/Close";

const initialValues = {
  displayName: "",
  username: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  role: "",
};

const userSchema = yup.object().shape({
  displayName: yup.string().required("Display Name is required"),
  username: yup.string().required("User Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup.string().required("Phone is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
});
const AddUser = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [routes, setRoutes] = useState(initialRoutes);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [rows, setRows] = useState(users);
  const [activeTab, setActiveTab] = useState(0); // To manage active tab

  const navigate = useNavigate(); // For page navigation

  const fetchUsers = () => {
    fetch(`${iepaProd.iepaUserSrvAPI}/user/all-users/org/1`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        return response.json();
      })
      .then((data) => {
        setUsers(data);
        // setRows(data);
      })
      .catch((error) => setError("Error fetching user data"));
  };

  useEffect(() => {
    fetchUsers();
    setRows(users);
  }, []);

  const handleFormSubmit = (values, { resetForm }) => {
    // console.log(values);

    const data = {
      username: values.username,
      passwordHash: values.password,
      contact: {
        displayName: values.displayName,
        email: values.email,
        phone: values.phone,
      },
      address: {
        address_1: values.address,
        city: values.city,
      },
      status: "NEW",
      orgId: "1",
      companyId: "1",
      routesIds: values.RouteId,
      createdBy: 1,
      userRoles: [{ id: values.role }],
    };

    fetch(`${iepaProd.iepaUserSrvAPI}/user/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save user");
        }
        return response.json();
      })
      .then(() => {
        setMessage("User added successfully!");
        setError(null);
        resetForm();
        fetchUsers(); // Refresh the user list
      })
      .catch(() => {
        setError("Failed to add user. Please try again.");
        setMessage(null);
      });
  };

  const handleClose = () => {
    navigate("/UserList"); // Redirect to /CustomerList
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabContent = [
    {
      label: "User Details",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={userSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box mt="16px" display="flex" flexDirection="column">
                <Box display="flex" gap={2}>
                  <TextField
                    //   fullWidth
                    sx={{ flex: 1 }}
                    required
                    id="displayName"
                    name="displayName"
                    label="Display Name"
                    value={values.displayName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.displayName && Boolean(errors.displayName)}
                    helperText={touched.displayName && errors.displayName}
                    margin="normal"
                  />
                  <TextField
                    //   fullWidth
                    sx={{ flex: 1 }}
                    required
                    id="username"
                    name="username"
                    label="User Name"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username && errors.username}
                    margin="normal"
                  />
                  <TextField
                    sx={{ flex: 1 }}
                    //   fullWidth
                    id="name"
                    label="Name"
                    value={values.name || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    margin="normal"
                  />
                </Box>
                <Box display="flex" gap={2}>
                  <TextField
                    sx={{ flex: 1 }}
                    //   fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    margin="normal"
                  />
                  <TextField
                    //   fullWidth
                    sx={{ flex: 1 }}
                    id="phone"
                    name="phone"
                    label="Phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                    margin="normal"
                  />
                  <TextField
                    //   fullWidth
                    sx={{ flex: 1 }}
                    id="address"
                    name="address"
                    label="Address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    margin="normal"
                  />
                </Box>
                <Box display="flex" gap={2}>
                  <TextField
                    fullWidth
                    select
                    id="city"
                    name="city"
                    label="Select City"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                    margin="normal"
                  >
                    <MenuItem value="Mangalore">Mangalore</MenuItem>
                    <MenuItem value="Bangalore">Bangalore</MenuItem>
                    <MenuItem value="Pune">Pune</MenuItem>
                  </TextField>
                  <TextField
                    fullWidth
                    select
                    id="role"
                    name="role"
                    label="Select Role"
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.role && Boolean(errors.role)}
                    helperText={touched.role && errors.role}
                    margin="normal"
                  >
                    <MenuItem value="5">Admin</MenuItem>
                    <MenuItem value="2">Back Office</MenuItem>
                    <MenuItem value="1">Field Agent</MenuItem>
                    <MenuItem value="3">Staff</MenuItem>
                  </TextField>
                  <TextField
                    id="password"
                    name="password"
                    label="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    margin="normal"
                    fullWidth
                  />
                    </Box> 
                     <Box display="flex" gap={2}>
                  <TextField
                    sx={{ flex: 1, maxWidth: 380 }}
                    //   fullWidth
                    id="route"
                    label="Route"
                    value={values.route || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.route && Boolean(errors.route)}
                    helperText={touched.route && errors.route}
                    margin="normal"
                  />
                </Box>
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      ),
    },
  ];
  return (
    <Box m="20px">
      <Header title="Add User" subtitle="Enter User Details" />
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "16px", marginRight: "5px" }}
        >
          Close
        </Typography>
        <CloseIcon />
      </IconButton>
      <Card>
        <CardContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabContent.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{
                  color: activeTab === index ? "black" : colors.blueAccent[700], // Active tab color
                  fontWeight: activeTab === index ? "bold" : "normal", // Optional: Bold for active tab
                }}
              />
            ))}
          </Tabs>
          <Box mt="20px">{tabContent[activeTab].content}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};
const overviewSchema = yup.object().shape({
  // customerNo: yup.string().required("Customer No is required"),
  // companyName: yup.string().required("Company Name is required"),
  // ownerName: yup.string().required("Owner Name is required"),
});


// Sample routes
const initialRoutes = [];

export default AddUser;
