import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  MenuItem,
  IconButton,
} from "@mui/material";

import { useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../../constants/global";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";

const ViewCustomer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0); // To manage active tab
  const navigate = useNavigate(); // For page navigation

  const [loading, setLoading] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const defaultCities = [{ city: "Select City", state: "Select State" }];
  const [cities, setCities] = useState(defaultCities);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error
  const [locationOptions, setLocationOptions] = useState(null);
  const [routeOptions, setRouteOptions] = useState(null);

  const [customerId, setCustomerId] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const location = useLocation();

  const { selectedRows, customers } = location.state || {};

  // When selectedRows change, update the selectedProduct
  useEffect(() => {
    if (selectedRows.length === 1) {
      // Assuming selectedRows contains an array with one object that has an 'id' field
      const selectedId = selectedRows[0];
      const customer = customers.find((p) => p.id === selectedId);
      setCustomerId(selectedId);
      console.log(customer);
      setSelectedProduct(customer);
    } else {
      setSelectedProduct(null); // Reset if no product is selected
    }
  }, [selectedRows, customers]); // Re-run whenever selectedRows or products change

  // Handle dynamic initial values based on selectedProduct
  const initialValues = selectedProduct
    ? {
        customerNo: selectedProduct.id || "",
        customerName: selectedProduct.name || "",
        ownerName: selectedProduct.ownerName || "",
        customerType: selectedProduct.type || "",
        email: selectedProduct.contact?.email || "",
        phone: selectedProduct.contact?.phone || "",
        address: selectedProduct.defaultLocation?.address?.address_1 || "",
        pincode: selectedProduct.defaultLocation?.address?.postalCode || "",
        city: selectedProduct.defaultLocation?.address?.city || "",
        state: selectedProduct.defaultLocation?.address?.state || "",
        location: selectedProduct.contact?.location || "",
      }
    : {
        customerNo: "",
        customerName: "",
        ownerName: "",
        customerType: "",
        email: "",
        phone: "",
        address: "",
        pincode: "",
        location: "",
        city: "",
        state: "",
      };
  const fetchCityState = async (pincode) => {
    setCities(defaultCities);
    fetch(
      `${iepaProd.iepaUserSrvAPI}/iepa/api/city/state/list/pincode/${pincode}`
    )
      .then((response) => response.json())
      .then((data) => {
        // setCustomers(data);
        setCities(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError("Error fetching City State information: " + error.message);
        }
      });
  };

  const fetchLocations = (pincode) => {
    if (true || (pincode && pincode.length === 6)) {
      setLoadingLocations(true);
      fetch(
        // pincode
        // ? `${iepaProd.iepaInventorySrvAPI}/inventory/routes/destination/zipcode/${pincode}/org/1`
        // :
        `${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/destinations/org/1`
      )
        .then((response) => response.json())

        .then((data) => {
          console.log(data);
          if (data) {
            setLocationOptions(
              data.map((location) => ({
                value: location.id,
                label: location.name,
                // route: location.route.id,
                // routeName: location.route.name,
              }))
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching locations:", error);
          setLocationOptions([]);
        });

      fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/org/1`)
        .then((response) => response.json())

        .then((data) => {
          console.log(data);
          if (data) {
            setRouteOptions(
              data.map((route) => ({
                value: route.id,
                label: route.name,
              }))
            );
            console.log(routeOptions);
          }
        })
        .catch((error) => {
          console.error("Error fetching Routes:", error);
          setRouteOptions([]);
        })
        .finally(() => setLoadingLocations(false));
    }
  };
  const handleAssignmentFormSubmit = (values, { resetForm }) => {
    console.log(values);
    const data = {
      routeDestinationId: values.location,
      routeId: values.route,
      orgId: "1",
      createdBy: 1,
      updatedBy: 1,
    };

    fetch(
      `${iepaProd.iepaUserSrvAPI}/customer/${customerId}/location/assign/route_destination/org/1`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage("Address added successfully!");
        setError(null);
        resetForm();
        console.log("Address Id:" + data.id);

        setAddressId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };
  const handleAddressFormSubmit = (values, { resetForm }) => {
    const data = {
      address_1: values.address,
      city: values.city,
      state: values.state,
      country: values.type,
      postalCode: values.pincode,
      orgId: "1",
      createdBy: 1,
      updatedBy: 1,
    };

    fetch(`${iepaProd.iepaUserSrvAPI}/customer/${customerId}/save/address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Address added successfully!");
        setError(null);
        resetForm();
        console.log("Address Id:" + data.id);

        setAddressId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  const handleContactFormSubmit = (values, { resetForm }) => {
    const data = {
      email: values.email,
      phone: values.phone,
      orgId: "1",
      createdBy: 1,
      updatedBy: 1,
    };
    console.log(data);
    fetch(`${iepaProd.iepaUserSrvAPI}/customer/${customerId}/save/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Contact added successfully!");
        setError(null);
        resetForm();
        console.log("Contact Id:" + data.id);

        setContactId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCustomerFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Customer Update Form");
    const data = {
      id: values.customerNo,
      name: values.companyName,
      ownerName: values.ownerName,
      type: values.customerType,
      orgId: "1",
      updatedBy: 1,
    };
    console.log(data);
    fetch(`${iepaProd.iepaUserSrvAPI}/customer/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Customer updated successfully!");
        setError(null);
        resetForm();
        console.log("Customer Id:" + data.id);

        // setUserId(data.id);
      })
      .catch((error) => {
        setError("Failed to update Customer. Please try again.");
        setMessage(null);
      });
  };

  const onPinChange = (e) => {
    if (e.target.value && e.target.value.length === 6) {
      fetchCityState(e.target.value);
      fetchLocations(e.target.value);
    }
  };
  useEffect(() => {
    fetchCityState();
    fetchLocations();
    setLoading(true);
  }, []);

  const handleNextTab = () => {
    setActiveTab((prevTab) => prevTab + 1); // Switch to next tab (Contact)
  };

  const handleClose = () => {
    navigate("/CustomerList"); // Redirect to /CustomerList
  };
  const tabContent = [
    {
      label: "Overview",
      content: selectedProduct ? (
        <Formik
          initialValues={initialValues}
          validationSchema={overviewSchema}
          onSubmit={handleCustomerFormSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  inputProps={{ readOnly: true }}
                  id="customerNo"
                  label="Customer No"
                  value={values.customerNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.customerNo && Boolean(errors.customerNo)}
                  helperText={touched.customerNo && errors.customerNo}
                />
                <TextField
                  fullWidth
                  // required
                  id="companyName"
                  label="Company Name"
                  value={values.customerName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />
                <TextField
                  fullWidth
                  required
                  id="ownerName"
                  label="Owner Name"
                  value={values.ownerName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ownerName && Boolean(errors.ownerName)}
                  helperText={touched.ownerName && errors.ownerName}
                />
                <TextField
                  fullWidth
                  id="customerType"
                  label="Customer Type"
                  value={values.customerType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.customerType && Boolean(errors.customerType)}
                  helperText={touched.customerType && errors.customerType}
                />

                <Box display="flex" gap={2}>
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.primary[400],
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button
                    type="reset"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.primary[400],
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                    variant="contained"
                    onClick={() => {
                      resetForm(); // Reset form when clicked
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Typography>Loading...</Typography>
      ),
    },
    {
      label: "Contact",
      content: selectedProduct ? (
        <Formik
          initialValues={initialValues}
          validationSchema={contactSchema}
          onSubmit={handleContactFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="email"
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  required
                  id="phone"
                  name="phone"
                  label="Phone No"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                >
                  Save
                </Button>

                <Button
                  type="reset"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Typography>Loading...</Typography>
      ),
    },
    {
      label: "Address",
      content: selectedProduct ? (
        <Formik
          initialValues={initialValues}
          validationSchema={addressSchema}
          onSubmit={handleAddressFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="address"
                  label="Address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                />
                <TextField
                  fullWidth
                  required
                  id="pincode"
                  label="Pincode"
                  value={values.pincode}
                  // onChange={handleChange}
                  onChange={(e) => {
                    handleChange(e);
                    onPinChange(e);
                  }}
                  onBlur={handleBlur}
                  error={touched.pincode && Boolean(errors.pincode)}
                  helperText={touched.pincode && errors.pincode}
                />
                <TextField
                  fullWidth
                  required
                  select
                  id="city"
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.city}>
                      {city.city}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  required
                  select
                  id="state"
                  name="state"
                  label="State"
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.state}>
                      {city.state}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value="0">Select State</MenuItem> */}
                </TextField>

                {/* <TextField
                  fullWidth
                  required
                  id="route"
                  label="Route"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                /> */}
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Typography>Loading...</Typography>
      ),
    },
    {
      label: "Assignment",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={assignmentSchema}
          onSubmit={handleAssignmentFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  //  required
                  select
                  id="location"
                  name="location"
                  label="Location"
                  // value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.location && Boolean(errors.location)}
                  helperText={touched.location && errors.location}
                >
                  {locationOptions?.map((destination, index) => (
                    <MenuItem
                      key={destination.value}
                      value={destination.value}
                      // selected={
                      //   values.city === destination.label ? "selected" : ""
                      // }
                    >
                      {destination.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  //  required
                  select
                  id="route"
                  name="route"
                  label="Route"
                  // value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.route && Boolean(errors.route)}
                  helperText={touched.route && errors.route}
                >
                  {routeOptions?.map((route, index) => (
                    <MenuItem key={route.value} value={route.value}>
                      {route.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  //  required
                  select
                  id="profile"
                  name="profile"
                  label="Profile"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.city}>
                      {city.city}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  //  required
                  select
                  id="taxCategory"
                  name="taxCategory"
                  label="Tax Category"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.city}>
                      {city.city}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="View Customer" subtitle="View/Edit a Customer Profile" />
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "16px", marginRight: "5px" }}
        >
          Close
        </Typography>
        <CloseIcon />
      </IconButton>
      <Card>
        <CardContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabContent.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{
                  color: activeTab === index ? "black" : colors.blueAccent[700], // Active tab color
                  fontWeight: activeTab === index ? "bold" : "normal", // Optional: Bold for active tab
                }}
              />
            ))}
          </Tabs>

          <Box mt="20px">{tabContent[activeTab].content}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const overviewSchema = yup.object().shape({
  // customerNo: yup.string().required("Customer No is required"),
  companyName: yup.string().required("Company Name is required"),
  ownerName: yup.string().required("Owner Name is required"),
});

const contactSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup
    .string()
    .matches(/^\d+$/, "Phone number is not valid")
    .required("Phone No is required"),
});

const addressSchema = yup.object().shape({
  address: yup.string().required("Address is required"),
  pincode: yup
    .string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits")
    .required("Pincode is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
});

const assignmentSchema = yup.object().shape({});


export default ViewCustomer;
