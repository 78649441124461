import React, { useState } from "react";
import {
    Box,
    Button,
    TextField,
    Tabs,
    Tab,
    Typography,
    Card,
    CardContent,
    MenuItem,
    IconButton
} from "@mui/material";

import { useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../../constants/global";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";

const ViewRoutes = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [activeTab, setActiveTab] = useState(0); // To manage active tab
    const navigate = useNavigate(); // For page navigation

    const [loading, setLoading] = useState(false);
    const [loadingLocations, setLoadingLocations] = useState(false);
    const defaultCities = [{ city: "Select City", state: "Select State" }];
    const [cities, setCities] = useState(defaultCities);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null); //Error
    const [locationOptions, setLocationOptions] = useState(null);
    const [routeOptions, setRouteOptions] = useState(null);

    const [contactId, setContactId] = useState(null);
    const [addressId, setAddressId] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const location = useLocation();

    const { selectedRows, routes } = location.state || {};

    console.log('selectedRows', selectedRows, routes)

    // When selectedRows change, update the selectedProduct
    useEffect(() => {
        if (!!selectedRows) {
            // Assuming selectedRows contains an array with one object that has an 'id' field
            const selectedId = selectedRows.id;
            const route = routes.find((p) => p.id === selectedId);
            setSelectedProduct(route);
        } else {
            setSelectedProduct(null); // Reset if no product is selected
        }
    }, [selectedRows, routes]); // Re-run whenever selectedRows or products change

    console.log('selectedProduct', selectedProduct)
    // Handle dynamic initial values based on selectedProduct
    const initialValues = selectedProduct ? {
        routeCode: selectedProduct ? selectedProduct.code || '' : '',
        routeCode: selectedProduct ? selectedProduct.name || '' : '',
        routeShortName: selectedProduct ? selectedProduct.shortName || '' : '',
        startDate: selectedProduct ? selectedProduct.activeStartDate || '' : '',
        endDate: selectedProduct ? selectedProduct.activeEndDate || '' : '',
    } : {
        routeCode: '',
        routeCode: '',
        routeShortName: '',
        startDate: '',
        endDate: '',

    };

    const fetchCityState = async (pincode) => {
        setCities(defaultCities);
        console.log("Fetching Customers");
        fetch(
            `${iepaProd.iepaUserSrvAPI}/iepa/api/city/state/list/pincode/${pincode}`
        )
            .then((response) => response.json())
            .then((data) => {
                console.log(data); // Log the data to check structure
                // setCustomers(data);
                setCities(data);
            })
            .catch((error) => {
                if (error.message.substring("JSON input") <= 0) {
                    setError("Error fetching City State information: " + error.message);
                }
            });
    };

    const fetchLocations = (pincode) => {
        if (pincode && pincode.length === 6) {
            setLoadingLocations(true);
            fetch(
                `${iepaProd.iepaInventorySrvAPI}/inventory/routes/destination/zipcode/${pincode}/org/1`
                // `${iepaUserSrvAPILocal}/inventory/routes/destination/zipcode/${pincode}/org/1`
                // "http://97.74.86.69:8192/inventory/routes/destination/zipcode/${values.pincode}/org/1"
            )
                .then((response) => response.json())

                .then((data) => {
                    console.log(data);
                    if (data) {
                        setLocationOptions(
                            data.map((location) => ({
                                value: location.id,
                                label: location.name,
                                route: location.route.id,
                                routeName: location.route.name,
                            }))
                        );
                        setRouteOptions(
                            data.map((location) => ({
                                value: location.route.id,
                                label: location.route.name,
                            }))
                        );
                    }
                })
                .catch((error) => {
                    console.error("Error fetching locations:", error);
                    setLocationOptions([]);
                })
                .finally(() => setLoadingLocations(false));
        }
    };

    const handleClose = () => {
        navigate("/RouteList"); // Redirect to /CustomerList
    };

    const handleAddressFormSubmit = (values, { resetForm }) => {
        console.log("Submitting the Address Form");
        const data = {
            address_1: values.address,
            city: values.city,
            state: values.state,
            country: values.type,
            postalCode: values.pincode,
            orgId: "1",
            createdBy: 1,
        };

        fetch(`${iepaProd.iepaUserSrvAPI}/customer/save/address`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                setMessage("Address added successfully!");
                setError(null);
                resetForm();
                console.log("Address Id:" + data.id);

                setAddressId(data.id);
            })
            .catch((error) => {
                setError("Failed to add Address. Please try again.");
                setMessage(null);
            });
    };

    const handleContactFormSubmit = (values, { resetForm }) => {
        console.log("Submitting the Address Form");
        const data = {
            email: values.email,
            phone: values.phone,
            orgId: "1",
            createdBy: 1,
        };

        fetch(`${iepaProd.iepaUserSrvAPI}/customer/save/contact`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                setMessage("Contact added successfully!");
                setError(null);
                resetForm();
                console.log("Contact Id:" + data.id);

                setContactId(data.id);
            })
            .catch((error) => {
                setError("Failed to add Address. Please try again.");
                setMessage(null);
            });
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };


    const handleCustomerFormSubmit = (values, { resetForm }) => {
        console.log("Submitting the Customer Form");
        const data = {
            customerNumber: values.customerNo,
            name: values.companyName,
            ownerName: values.ownerName,
            defaultLocation: {
                shipToFlag: 1,
                soldToFlag: 1,
                billToFlag: 1,
                address: { id: addressId },
            },
            contact: { id: contactId },
            orgId: "1",
            createdBy: 1,
        };

        fetch(`${iepaProd.iepaUserSrvAPI}/customer/save`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                setMessage("Address added successfully!");
                setError(null);
                resetForm();
                console.log("Address Id:" + data.id);

                setAddressId(data.id);
            })
            .catch((error) => {
                setError("Failed to add Address. Please try again.");
                setMessage(null);
            });
    };

    const onPinChange = (e) => {
        if (e.target.value && e.target.value.length === 6) {
            fetchCityState(e.target.value);
        }
    };
    useEffect(() => {
        fetchCityState();
        setLoading(true);
    }, []);
    console.log('initialValues', initialValues)

    const handleFormSubmit = (values, { resetForm }) => {
        const data = {
            name: values.routeName,
            shortName: values.routeShortName,
            description: "route description",
            code: values.routeCode,
            type: values.temporary,
            activeStartDate: values.startDate,
            activeEndDate: values.endDate,
            orgId: "1",
            createdBy: 1,
        };

        fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/save/org/1`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                setMessage("Route added successfully!");
                setError(null);
                resetForm();
            })
            .catch((error) => {
                setError("Failed to add Route. Please try again.");
                setMessage(null);
            });
    };

    const tabContent = [
        {
            label: "Route Details",
            content: selectedProduct ? (
                <Formik
                    initialValues={initialValues}
                    validationSchema={routeSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        resetForm
                    }) => (
                        <form onSubmit={handleSubmit}>
                            {console.log('values', values)}
                            <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                                <TextField
                                    fullWidth
                                    required
                                    id="routeCode"
                                    label="Route Code"
                                    value={values.routeCode || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.routeCode && Boolean(errors.routeCode)}
                                    helperText={touched.routeCode && errors.routeCode}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    id="routeName"
                                    label="Route Name"
                                    value={values.routeName || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.routeName && Boolean(errors.routeName)}
                                    helperText={touched.routeName && errors.routeName}
                                />
                                <TextField
                                    fullWidth
                                    id="routeShortName"
                                    label="Route Short Name"
                                    value={values.routeShortName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.routeShortName && Boolean(errors.routeShortName)}
                                    helperText={touched.routeShortName && errors.routeShortName}
                                />
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="startDate"
                                    label="Start Date"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={values.startDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.startDate && Boolean(errors.startDate)}
                                    helperText={touched.startDate && errors.startDate}
                                    margin="normal"
                                />
                                <TextField
                                    sx={{ flex: 1 }}
                                    id="endDate"
                                    label="End Date"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={values.endDate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.endDate && Boolean(errors.endDate)}
                                    helperText={touched.endDate && errors.endDate}
                                    margin="normal"
                                />
                            </Box>
                            <Box display="flex" gap={2} sx={{ mt: 3 }}>
                                <Button
                                    type="submit"
                                    sx={{
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.primary[400],
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        margin: "10px",
                                    }}
                                    variant="contained"
                                >
                                    Save
                                </Button>

                                <Button
                                    // type="submit"
                                    sx={{
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.primary[400],
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        margin: "10px",
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                        resetForm();  // Reset form when clicked
                                    }}
                                >
                                    Reset
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            ) : (
                <Typography>Loading...</Typography>
            ),
        }
    ];

    return (
        <Box m="20px">
            <Header title="View Routes" subtitle="View/Edit Route Details" />
            <Box display="flex" justifyContent="flex" mb="20px">
                <IconButton onClick={handleClose} sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', marginRight: '5px' }}>
                        Close
                    </Typography> <CloseIcon />
                </IconButton>
                {/* <Button
          color="secondary"
          variant="contained"
          onClick={() => window.history.back()} // Navigates back in history
          sx={{
            backgroundColor: "black",
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Go Back
        </Button> */}
                <Button
                    color="secondary"
                    variant="contained"
                    // onClick={() => navigate("../ProductList")}
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.primary[400],
                        fontSize: "14px",
                        fontWeight: "bold",
                        margin: "10px",
                    }}
                >
                    Edit
                </Button>
            </Box>
            <Card>
                <CardContent>
                    <Tabs value={activeTab} onChange={handleTabChange}>
                        {tabContent.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.label}
                                sx={{
                                    color: activeTab === index ? "black" : colors.blueAccent[700], // Active tab color
                                    fontWeight: activeTab === index ? "bold" : "normal", // Optional: Bold for active tab
                                }}
                            />
                        ))}
                    </Tabs>

                    <Box mt="20px">{tabContent[activeTab].content}</Box>
                </CardContent>
            </Card>
        </Box>
    );
};

const routeSchema = yup.object().shape({
    routeCode: yup.string().required("Route Code is required"),
    routeName: yup.string().required("Route Name is required"),
    routeShortName: yup.string().required("Route Short Name is required"),
    // startDate: yup.date().required("Start Date is required"),
    // endDate: yup.date().required("End Date is required"),
});



export default ViewRoutes;
