import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  MenuItem,
  IconButton,
} from "@mui/material";

import { useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../../constants/global";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

const CreateItemLocation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0); // To manage active tab
  const navigate = useNavigate(); // For page navigation

  const [loading, setLoading] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const defaultCities = [{ city: "Select City", state: "Select State" }];
  const [cities, setCities] = useState(defaultCities);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error
  const [locationOptions, setLocationOptions] = useState(null);
  const [routeOptions, setRouteOptions] = useState(null);

  const [contactId, setContactId] = useState(null);
  const [addressId, setAddressId] = useState(null);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFormSubmit = (values, { resetForm }) => {
    const data = {
      // id: selectedId,
      building: values.building,
      floor: values.floor,
      name: values.door,
      orgId: "1",
      createdBy: 1,
      warehouse: { id: 1 },
    };

    console.log(data);
    // const data = {
    //   code: values.LocCode,
    //   name: values.LocName,
    //   description: values.LocDescription,
    //   type: values.LocType,
    //   warehouse: {
    //     id: values.WarehouseId,
    //   },
    //   orgId: "1",
    //   createdBy: 1,
    // };

    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/container/save`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage("Item Location added successfully!");
        setError(null);
        resetForm();
      })
      .catch((error) => {
        setError("Failed to add Item Location. Please try again.");
        setMessage(null);
      });
  };

  const handleCustomerFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Customer Form");
    const data = {
      customerNumber: values.customerNo,
      name: values.companyName,
      ownerName: values.ownerName,
      defaultLocation: {
        shipToFlag: 1,
        soldToFlag: 1,
        billToFlag: 1,
        address: { id: addressId },
      },
      contact: { id: contactId },
      orgId: "1",
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaUserSrvAPI}/customer/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Address added successfully!");
        setError(null);
        resetForm();
        console.log("Address Id:" + data.id);

        setAddressId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  useEffect(() => {
    // fetchCityState();
    setLoading(true);
  }, []);

  const handleNextTab = () => {
    setActiveTab((prevTab) => prevTab + 1); // Switch to next tab (Contact)
  };

  const handleClose = () => {
    navigate("/ItemLocList"); // Redirect to /CustomerList
  };

  const tabContent = [
    {
      label: "Item Location Details",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={overviewSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="building"
                  label="Building"
                  value={values.building}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.building && Boolean(errors.building)}
                  helperText={touched.building && errors.building}
                />
                <TextField
                  fullWidth
                  required
                  id="floor"
                  label="Floor"
                  value={values.floor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.floor && Boolean(errors.floor)}
                  helperText={touched.floor && errors.floor}
                />
                <TextField
                  fullWidth
                  required
                  id="door"
                  label="Door No."
                  value={values.door}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.door && Boolean(errors.door)}
                  helperText={touched.door && errors.door}
                />

                <Box display="flex" gap={2}>
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.primary[400],
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                    variant="contained"
                  >
                    Save
                  </Button>

                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.primary[400],
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                    variant="contained"
                    onClick={() => {
                      resetForm(); // Reset form when clicked
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Add Item Location" subtitle="Create a New Item Location" />
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "16px", marginRight: "5px" }}
        >
          Close
        </Typography>{" "}
        <CloseIcon />
      </IconButton>
      <Card>
        <CardContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabContent.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{
                  color: activeTab === index ? "black" : colors.blueAccent[700], // Active tab color
                  fontWeight: activeTab === index ? "bold" : "normal", // Optional: Bold for active tab
                }}
              />
            ))}
          </Tabs>

          <Box mt="20px">{tabContent[activeTab].content}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const overviewSchema = yup.object().shape({
  building: yup.string().required("Building information is required"),
  floor: yup.string().required("Floor information is required"),
  door: yup.string().required("Door information is required"),
});

const contactSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNo: yup
    .string()
    .matches(/^\d+$/, "Phone number is not valid")
    .required("Phone No is required"),
});

const addressSchema = yup.object().shape({
  address: yup.string().required("Address is required"),
  pincode: yup
    .string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits")
    .required("Pincode is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
});

const initialValues = {
  building: "",
  floor: "",
  door: "",
};

export default CreateItemLocation;
