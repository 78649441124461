import { useTheme } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { tokens } from "../theme";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../constants/global";
import { Box, Button, Modal, TextField } from "@mui/material";

import Header from "./Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ItemLocList from "../scenes/pages/ItemLocList";

const IepaDataGrid = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  const [selectedApproveRows, setSelectedApproveRows] = useState([]); // Track selected rows
  const [filterText, setFilterText] = useState(""); // Filter text state
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAssignLocation, setSelectedAssignLocation] = useState(null);
  const [products, setProducts] = useState([]);
  const [rows, setRows] = useState([]);
  const [approvedItems, setApprovedItems] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error
  const navigate = useNavigate(); // For page navigation

  const fetchLoadingItems = async () => {
    console.log("Fetching the Order lines");
    await fetch(`${iepaProd.iepaOrderMgmtAPI}/orm/order/lines/entered/org/1`)
      .then((response) => {
        console.log(response);
        try {
          return response.json();
        } catch (error) {
          //do nothing
          console.log("Empty Json");
        }
      })
      .then((data) => {
        try {
          console.log(data);
          setProducts(data);
          setRows(data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          setProducts([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setProducts([]);
      });
  };

  const assignWarehouse = async (data) => {
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/stock/save/org/1`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage("Assign Item to Warehouse added successfully!");
        setError(null);
      })
      .catch((error) => {
        setError("Failed to Assign Item to Warehouse. Please try again.");
        setMessage(null);
      });
  };

  const approveLineItems = async (data) => {
    await fetch(
      `${iepaProd.iepaOrderMgmtAPI}/orm/order/lines/loading/approve/org/1`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage("Assign Item to Warehouse added successfully!");
        setError(null);
      })
      .catch((error) => {
        setError("Failed to Assign Item to Warehouse. Please try again.");
        setMessage(null);
      });
  };

  const generateTripSheet = async (data) => {
    console.log("generateTripSheet called");
    await fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/process/loadingsheet/org/1`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage("Generate TripSheet was added successfully!");
        setError(null);
      })
      .catch((error) => {
        setError("Failed to Generate TripSheet. Please try again.");
        setMessage(null);
      });
  };

  useEffect(() => {
    fetchLoadingItems();
    setRows(products);
  }, []);

  // Filter rows based on filterText
  const filteredRows = products?.filter((product) => {
    const searchTerm = filterText; // ? filterText.toLowerCase() : "";
    return (
      product.order?.orderNumber.toLowerCase().includes(searchTerm) ||
      product.orderedItemSku?.toLowerCase().includes(searchTerm) ||
      product.order?.customerId.toLowerCase().includes(searchTerm) //||
      //   product.type?.toLowerCase().includes(searchTerm) ||
      //   product.uom?.toLowerCase().includes(searchTerm) ||
      //   product.itemStock.nametoLowerCase().includes(searchTerm)
      // product.itemLocation.toLowerCase().includes(searchTerm)) // Check for itemLocation
    );
  });

  const updateOrderLinesForLoading = () => {
    if (selectedRows && selectedRows.length > 0) {
      let newProductList = [];
      let selectedItems = [];
      let newApprovedList = [];
      newProductList = products.filter(
        (item) => !selectedRows.includes(item.id)
      );
      selectedItems = products.filter((item) => selectedRows.includes(item.id));

      if (newProductList) {
        //} && newProductList.length > 0) {
        setProducts(newProductList);
        console.log("reSet products:: " + products.length);
        if (approvedItems && approvedItems.length > 0) {
          newApprovedList = [...approvedItems, ...selectedItems];

          setApprovedItems(newApprovedList);

          //   console.log("reSet ApprovedItems:: " + approvedItems.length);
        } else {
          setApprovedItems(selectedItems);
        }
      }
    }
  };

  const updateOrderLinesForApproval = () => {
    if (selectedApproveRows && selectedApproveRows.length > 0) {
      let newProductList = [];
      let selectedItems = [];
      let newApprovedList = [];

      //Remove the items from the Approved list
      newProductList = approvedItems.filter(
        (item) => !selectedApproveRows.includes(item.id)
      );

      //Get the items that will be removed from the Approved list
      selectedItems = approvedItems.filter((item) =>
        selectedApproveRows.includes(item.id)
      );

      if (newProductList) {
        setApprovedItems(newProductList);
        console.log("reSet products:: " + products.length);
        if (products && products.length > 0) {
          newApprovedList = [...products, ...selectedItems];
          setProducts(newApprovedList);
        } else {
          setProducts(selectedItems);
        }
      }
    }
  };

  const columns = [
    // { field: "order.orderNumber", headerName: "Sr#", width: 8 }, // Fixed small width
    // { field: "344kfglk", headerName: "SR#", width: 8 }, // Fixed small width
    // { field: "code", headerName: "Order#", width: 80 }, // Fixed small width
    {
      field: "order",
      headerName: "Order#",
      valueFormatter: (value) => value.orderNumber,
      flex: 1,
    }, // Fixed small width
    {
      field: "orderedItemSku",
      headerName: "Item",
      flex: 1,
      //   flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      flex: 1,
      //   flex: 1,
      //   width: 15, // Fixed width for numerical data
      //   width: 8,
    },
    {
      field: "orderQuantityUom",
      headerName: "UOM",
      headerAlign: "left",
      flex: 1,
      width: 12, // Fixed width for numerical data
    },
    {
      field: "listPrice",
      headerName: "Price",
      type: "number",
      flex: 1,
      //   width: 12, // Fixed width for numerical data
      headerAlign: "left",
      align: "left",
    },
    {
      field: "taxAmount",
      headerName: "Tax",
      type: "number",
      flex: 1,
      //   width: 12, // Fixed width for numerical data
      headerAlign: "left",
      align: "left",
      //   value: 0.18,
    },
    {
      field: "lineAmount",
      headerName: "Total",
      type: "number",
      //   width: 40, // Fixed small width
      headerAlign: "left",
      align: "left",
    },
  ];

  const initLoadingItems = () => {
    setProducts(rows);
    setApprovedItems([]);
  };
  const handleResetClick = (row) => {
    // fetchWarehouse();

    // setOpenAssignModal(true);
    // setAssignedLocation(row);
    console.log("Reset called");
    initLoadingItems();
  };

  const handleAssignClick = () => {
    // console.log("Add>> called");
    updateOrderLinesForLoading();
  };

  const handleGenerateTripsheetClick = () => {
    console.log("Generate TripSheet called");
    generateTripSheet();
  };

  const handleRemoveClick = () => {
    // console.log("<<Remove called");
    updateOrderLinesForApproval();
  };

  const handleApproveClick = (row) => {
    // setOpenViewModal(true);

    console.log("Approving Items for Loading " + approvedItems);
    if (approvedItems && approvedItems.length > 0) {
      const data = approvedItems.map((item) => item.id);
      console.log(data);
      setMessage("Line Items were Approved.");
      approveLineItems(data);
      fetchLoadingItems();
      handleResetClick();
    }
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
    setSearchTerm("");
    setSelectedAssignLocation(null);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    // setSelectedLocation(null);
  };

  const handleSelectLocation = (location) => {
    setSelectedAssignLocation(location);
  };

  const handleSaveLocation = () => {
    if (selectedAssignLocation) {
      console.log("Assigned Location:", selectedAssignLocation.id);
      console.log("Item selected::" + selectedRows);
      const warehouseData = {
        container: {
          id: selectedAssignLocation.id,
        },
        item: { id: selectedRows[0] },
        orgId: "1",
        createdBy: 1,
      };
      console.log("Save Warehouse Location::" + warehouseData);
      assignWarehouse(warehouseData);
      fetchLoadingItems();
    }
    handleCloseAssignModal();
  };

  return (
    <Box m="20px">
      <Header
        title="Approve Items for Loading"
        subtitle="List of Line Items aligned for Approval from Inventory Management"
      />
      <Box display="flex" justifyContent="flex" mb="20px">
        <Button
          color="secondary"
          variant="contained"
          onClick={() => window.history.back()} // Navigates back in history
          sx={{
            backgroundColor: "black",
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Go Back
        </Button>
        {/* <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../AddProduct")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Loading Sheet
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          disabled={approvedItems.length === 0} // Enabled only if exactly one row is selected
          onClick={() => handleApproveClick()}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Approve
        </Button>
        {/* Button enabled when at least one checkbox is selected */}
        <Button
          color="secondary"
          variant="contained"
          //   disabled={selectedRows.length === 0} // Enabled only if at least one row is selected
          onClick={() => handleGenerateTripsheetClick()}
          sx={{
            backgroundColor:
              //   selectedRows.length > 0
              // ?
              colors.blueAccent[700],
            // : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Generate TripSheet
        </Button>

        {/* Button enabled when exactly one checkbox is selected */}
        {/* <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1} // Enabled only if exactly one row is selected
          sx={{
            backgroundColor:
              selectedRows.length === 1
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => handleAssignClick()}
        >
          Assign Warehouse
        </Button> */}
        {/* Button enabled when exactly one checkbox is selected */}
        {/* <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1} // Enabled only if exactly one row is selected
          sx={{
            backgroundColor:
              selectedRows.length === 1
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => handleViewClick(selectedRows.row)}
        >
          View Warehouse Location
        </Button> */}
        {/* TextField for filtering */}
        <TextField
          label="Search"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{ width: "30%", margin: "10px" }}
        />
      </Box>

      <Box
        m="-25px 0 0 0"
        width="100%"
        // height="78vh"
        direction="row"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
            whiteSpace: "normal", // Allows text wrapping
            wordWrap: "break-word", // Ensures text breaks properly
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <table cellSpacing="0" cellPadding="0" border="0">
          <tr>
            <td valign="top" width="40%">
              <DataGrid
                sx={{
                  overflow: "scroll",
                  height: "650px",
                }}
                rows={filteredRows}
                columns={columns}
                checkboxSelection
                onRowSelectionModelChange={(newSelection) => {
                  console.log("newSelection::" + newSelection);
                  setSelectedRows(newSelection);
                }}
                components={{ Toolbar: GridToolbar }}
              />
            </td>
            <td width="10%">
              {/* Button enabled when exactly one checkbox is selected */}
              <Button
                color="secondary"
                variant="contained"
                disabled={selectedRows.length === 0} // Enabled only if exactly one row is selected
                sx={{
                  backgroundColor:
                    selectedRows.length > 0
                      ? colors.blueAccent[700]
                      : colors.grey[400],
                  color: colors.primary[400],
                  fontSize: "14px",
                  fontWeight: "bold",
                  margin: "10px",
                  width: "120px",
                  top: "50%",
                  position: "absolute",
                }}
                onClick={() => handleAssignClick()}
              >
                {"Add >>"}
              </Button>
              {/* Button enabled when exactly one checkbox is selected */}
              <Button
                color="secondary"
                variant="contained"
                disabled={selectedApproveRows.length === 0} // Enabled only if exactly one row is selected
                sx={{
                  backgroundColor:
                    selectedApproveRows.length > 0
                      ? colors.blueAccent[700]
                      : colors.grey[400],
                  color: colors.primary[400],
                  fontSize: "14px",
                  fontWeight: "bold",
                  margin: "10px",
                  width: "120px",
                  top: "60%",
                  position: "absolute",
                }}
                onClick={() => handleRemoveClick()}
              >
                {"<< Remove"}
              </Button>
              {/* Button enabled when exactly one checkbox is selected */}
              <Button
                color="secondary"
                variant="contained"
                disabled={approvedItems.length < 1} // Enabled only if exactly one row is selected
                sx={{
                  backgroundColor:
                    approvedItems.length > 0
                      ? colors.blueAccent[700]
                      : colors.grey[400],
                  color: colors.primary[400],
                  fontSize: "14px",
                  fontWeight: "bold",
                  margin: "10px",
                  width: "120px",
                  top: "70%",
                  position: "absolute",
                }}
                onClick={() => handleResetClick(selectedRows.row)}
              >
                Reset
              </Button>
            </td>

            <td valign="top" width="40%">
              <DataGrid
                sx={{
                  overflow: "scroll",
                  height: "650px",
                }}
                rows={approvedItems}
                columns={columns}
                checkboxSelection
                onRowSelectionModelChange={(newSelection) =>
                  setSelectedApproveRows(newSelection)
                }
                components={{ Toolbar: GridToolbar }}
              />
            </td>
          </tr>
        </table>
      </Box>

      {/* Assign Location Modal */}
      <Modal open={openAssignModal} onClose={handleCloseAssignModal}>
        <Box sx={{ padding: 4, bgcolor: "background.paper", borderRadius: 2 }}>
          <h2>Assign Location</h2>
          <TextField
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Box
            sx={{
              maxHeight: 300,
              overflowY: "auto",
              marginBottom: 2,
              border: "1px solid lightgray",
              borderRadius: 1,
            }}
          >
            {warehouse
              .filter((location) =>
                location.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((location) => (
                <Box
                  key={location.code}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 1,
                  }}
                >
                  <span>
                    {location.name} (Code: {location.code})
                  </span>
                  <Button onClick={() => handleSelectLocation(location)}>
                    Select
                  </Button>
                </Box>
              ))}
          </Box>
          {selectedAssignLocation && (
            <p>
              Selected Location: {selectedAssignLocation.name} (Code:{" "}
              {selectedAssignLocation.code})
            </p>
          )}
          <Button variant="contained" onClick={handleSaveLocation}>
            Save
          </Button>
        </Box>
      </Modal>

      {/* View Location Modal */}
      <Modal open={openViewModal} onClose={handleCloseViewModal}>
        <Box sx={{ padding: 4, bgcolor: "background.paper", borderRadius: 2 }}>
          <h2>The Line Items were approved for Loading.</h2>
          <p></p>
          <Button variant="contained" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default IepaDataGrid;
