import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Checkbox,
  TableContainer,
  InputAdornment,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { iepaProd } from "../../constants/global";
import { useNavigate } from "react-router-dom";

const ItemLocList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [assignedLocations, setAssignedLocations] = useState([]);
  const navigate = useNavigate();
  const [routes, setRoutes] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState({});
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/container/status/active/org/1`
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setRoutes(data); // Set the response as the routes array
        } else {
          console.error("Expected an array of routes, but got:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Filtered rows based on search query
  const filteredRows = routes.filter((routes) => {
    const searchTerm = filterText ? filterText.toLowerCase() : "";
    return (
      //routes.id.includes(searchTerm) ||
      routes.building?.toLowerCase().includes(searchTerm) ||
      routes.name?.toString().includes(searchTerm) ||
      routes.description?.toString().includes(searchTerm) ||
      routes.type?.toString().includes(searchTerm)
    );
  });

  const columns = [
    { field: "id", headerName: "Sl.No", width: 80 },
    { field: "building", headerName: "Building", flex: 2 },
    { field: "floor", headerName: "Floor", flex: 2 },
    { field: "name", headerName: "Door #", flex: 3 },

    // { field: "LocCode", headerName: "Location Code", flex: 2 },
    // { field: "name", headerName: "Location Name", flex: 2},
    // { field: "description", headerName: "Description", flex: 3 },
    // { field: "type", headerName: "Type", flex: 1 },
  ];

  return (
    <Box m="20px">
      <Header
        title="Manage Item Locations"
        subtitle="View and Manage Warehouse"
      />
      <Box display="flex" mb="20px">
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../CreateItemLocation")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          + Add Item Location
        </Button>

        <Button
          color="primary"
          variant="contained"
          disabled={selectedRows.length !== 1}
          // onClick={() => navigate("../ViewItemLocation")}
          onClick={() => {
            navigate("../ViewItemLocation", {
              state: { selectedRows: selectedRows, products: routes }, // Passing selectedRows state to ViewItem component
            });
          }}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          View Item Location
        </Button>

        <TextField
          label="Search"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{ width: "30%", margin: "10px" }}
        />
      </Box>
      <Box
        // m="-25px 0 0 0"
        height="78vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
            whiteSpace: "normal", // Allows text wrapping
            wordWrap: "break-word", // Ensures text breaks properly
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) =>
            setSelectedRows(newSelection)
          }
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default ItemLocList;
