import React, { useEffect, useRef } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AltRoute from "@mui/icons-material/AltRoute";
import AddLocationAlt from "@mui/icons-material/AddLocationAlt";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import WarehouseIcon from "@mui/icons-material/Warehouse";

const SideNav = ({ isOpen, onClose }) => {
  const sideNavRef = useRef(null);
  const location = useLocation(); // To get the current URL path

  const handleClickOutside = (event) => {
    if (!sideNavRef?.current?.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const menuItems = [
    { title: "Dashboard", to: "/dashboard", icon: <HomeOutlinedIcon /> },
    {
      title: "Manage Customer",
      to: "/CustomerList",
      icon: <PeopleOutlinedIcon />,
    },
    {
      title: "Manage Item",
      to: "/ProductList",
      icon: <Inventory2OutlinedIcon />,
    },
    { title: "Manage Route", to: "/RouteList", icon: <AltRoute /> },
    { title: "Manage Location", to: "/Locations", icon: <AddLocationAlt /> },
    { title: "Manage User", to: "/UserList", icon: <PeopleOutlinedIcon /> },
    {
      title: "Manage Item Location",
      to: "/ItemLocList",
      icon: <WarehouseIcon />,
    },
    { title: "Manage Orders", to: "/OrderList", icon: <ReceiptOutlinedIcon /> },
    { title: "Manage Taxation", to: "/Taxation", icon: <ReceiptOutlinedIcon /> },
    { title: "Manage Load", to: "/LoadList", icon: <ReceiptOutlinedIcon /> },
    {
      title: "Manage Loading",
      to: "/IepaGrid",
      icon: <ReceiptOutlinedIcon />,
    },
    { title: "Delivery", to: "/Delivery", icon: <ReceiptOutlinedIcon /> },
    { title: "Report", to: "/Report", icon: <CalendarTodayOutlinedIcon /> },
  ];

  return (
    <Box
      refs={sideNavRef}
      sx={{
        position: "fixed",
        top: "64px",
        right: isOpen ? 0 : "-250px",
        width: "250px",
        height: "calc(100vh - 64px)",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        transition: "right 0.3s ease-in-out",
        zIndex: 1000,
        overflowY: "auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{ p: 2, color: "#007bff", fontWeight: "bold" }}
      >
        Navigation
      </Typography>
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={Link}
            to={item.to}
            sx={{
              backgroundColor:
                location.pathname === item.to ? "#007bff" : "transparent",
              color: location.pathname === item.to ? "#fff" : "text.primary",
              "&:hover": {
                backgroundColor: "#007bff",
                color: "primary.contrastText",
              },
              transition: "all 0.3s ease",
            }}
          >
            <ListItemIcon
              sx={{
                color:
                  location.pathname === item.to ? "#fff" : "text.secondary",
                transition: "color 0.3s ease",
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.title}
              sx={{
                color: location.pathname === item.to ? "#fff" : "text.primary",
                transition: "color 0.3s ease",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SideNav;
