import { React, useState } from "react";

const UseToken = () => {
  const getToken = () => {
    let tokenString = localStorage.getItem("token");
    // console.log("Localstorage tokenString::" + tokenString);

    try {
      if (tokenString) {
        tokenString = JSON.parse(tokenString.replace(/{[a-z]*}/g, ""));
        // console.log("tokenString::" + tokenString);
        // userToken = tokenString; // ? JSON.parse(tokenString) : null;
      }
    } catch (error) {
      console.log("ERROR:" + error);
    }

    const userToken = tokenString;
    return userToken; //?.token;
  };
  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    // console.log("Trying to save Token::" + userToken);
    userToken = JSON.stringify(maskToken(userToken));
    // localStorage.setItem("token", JSON.stringify(userToken));
    // console.log("Trying to save stringified Token::" + userToken);
    localStorage.setItem("token", userToken);
    setToken(userToken);
  };

  const maskToken = (inToken) => {
    try {
      if (inToken) {
        inToken = inToken.replace(/{[a-z]*}/g, "");
        // console.log("inToken::" + inToken);
        // userToken = tokenString; // ? JSON.parse(tokenString) : null;
      }
    } catch (error) {
      console.log("ERROR:" + error);
    }
    return inToken;
  };
  return {
    setToken: saveToken,
    token,
  };
};
export default UseToken;
