import { React, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import CreateCustomer from "./scenes/pages/CreateCustomer";
import AddProduct from "./scenes/pages/AddProduct";
import TakeOrder from "./scenes/pages/TakeOrder";
import CreateLocation from "./scenes/pages/CreateLocation";
import CreateRoute from "./scenes/pages/CreateRoute";
import AddUser from "./scenes/pages/AddUser";
import Invoice from "./scenes/pages/Invoice";
import OrderList from "./scenes/pages/OrderList";
import ProductList from "./scenes/order/ProductList";
import CustomerList from "./scenes/order/CustomerList";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Report from "./scenes/pages/Report";
import Login from "./Login";
import UserList from "./scenes/pages/UserList";
import Locations from "./scenes/pages/Locations";
import RouteList from "./scenes/pages/RouteList";
import CreateItemLoc from "./scenes/pages/CreateItemLoc";
import ItemLocList from "./scenes/pages/ItemLocList";
import UseToken from "./components/UseToken";
import Logout from "./Logout";
import IepaDataGrid from "./components/IepaDataGrid";
import { Pages } from "@mui/icons-material";
import ViewItem from "./scenes/pages/ViewItem";
import LoadList from "./scenes/order/LoadList";
import ViewCustomer from "./scenes/pages/ViewCustomer";
import ViewUser from "./scenes/pages/ViewUser";
import Delivery from "./scenes/order/Delivery";
import ReturnOrder from "./scenes/pages/ReturnOrder";
import ViewRoutes from "./scenes/pages/ViewRoutes";
import ViewLocations from "./scenes/pages/ViewLocations";
import CreateItemLocation from "./scenes/pages/CreateItemLocation";
import ViewItemLocation from "./scenes/pages/ViewItemLocation";
import Taxation from "./scenes/pages/Taxation";
import CreateTaxation from "./scenes/pages/CreateTaxation";
import ViewTaxation from "./scenes/pages/ViewTaxation";
import TripSheet from "./scenes/pages/TripSheet";

import './global.css';

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { token, setToken } = UseToken();

  // console.log("Got the Token::" + token);
  if (!token) {
    return <Login setToken={setToken} />;
  }
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Topbar is now static */}
        <Topbar setIsSidebar={setIsSidebar} />

        <div
          className="app"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "64px", // Offset to avoid content overlapping Topbar
          }}
        >
          {/* Sidebar placed after the main content to appear on the right */}
          {/*isSidebar && <Sidebar isSidebar={isSidebar} />*/}

          <main
            className="content"
            style={{
              flexGrow: 1,
              padding: "20px",
              minHeight: "calc(100vh - 64px)", // Adjust height to fit with static Topbar
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/CreateCustomer" element={<CreateCustomer />} />
              <Route path="/AddProduct" element={<AddProduct />} />
              <Route path="/TakeOrder" element={<TakeOrder />} />
              <Route path="/Invoice" element={<Invoice />} />
              <Route path="/ProductList" element={<ProductList />} />
              <Route path="/CustomerList" element={<CustomerList />} />
              <Route path="/CreateLocation" element={<CreateLocation />} />
              <Route path="/CreateRoute" element={<CreateRoute />} />
              <Route path="/AddUser" element={<AddUser />} />
              <Route path="/Invoice" element={<Invoice />} />
              <Route path="/OrderList" element={<OrderList />} />
              <Route path="/Report" element={<Report />} />
              <Route path="/UserList" element={<UserList />} />
              <Route path="/Locations" element={<Locations />} />
              <Route path="/RouteList" element={<RouteList />} />
              <Route path="/CreateItemLoc" element={<CreateItemLoc />} />
              <Route path="/ItemLocList" element={<ItemLocList />} />
              <Route path="/Logout" element={<Logout />} />
              <Route path="/IepaGrid" element={<IepaDataGrid />} />
              <Route path="/ViewItem" element={<ViewItem />} />
              <Route path="/LoadList" element={<LoadList />} />
              <Route path="/ViewCustomer" element={<ViewCustomer />} />
              <Route path="/ViewUser" element={<ViewUser />} />
              <Route path="/Delivery" element={<Delivery />} />
              <Route path="/ReturnOrder" element={<ReturnOrder />} />
              <Route path="/ViewRoutes" element={<ViewRoutes />} />
              <Route path="/ViewLocations" element={<ViewLocations />} />
              <Route path="/CreateItemLocation" element={<CreateItemLocation />} />
              <Route path="/ViewItemLocation" element={<ViewItemLocation />} />
              <Route path="/Taxation" element={<Taxation />} />
              <Route path="/CreateTaxation" element={<CreateTaxation />} />
              <Route path="/ViewTaxation" element={<ViewTaxation />} />
              <Route path="/TripSheet" element={<TripSheet />} />

            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
