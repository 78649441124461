import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Switch,
  IconButton,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { React, useState, useEffect } from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../../constants/global";
import CloseIcon from "@mui/icons-material/Close";

const CreateLocation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [locations, setLocations] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // For page navigation

  const handleFormSubmit = (values, { resetForm }) => {
    const data = {
      destinationName: values.location,
      description: "route description",
      code: values.locationCode,
      type: "",
      status: "enabled",
      zipCode: values.pincode,
      activeStartDate: "",
      activeEndDate: "",
      orgId: "1",
      createdBy: 1,
    };
    // Clear previous messages
    setMessage("");
    setError("");

    // Save the new location details to the API
    // fetch("http://97.74.86.69:8192/inventory/routes/destination/save", {
    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/destination/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save location.");
        }
        return response.json();
      })
      .then((newLocation) => {
        // Update the table with the new data
        setLocations((prevLocations) => [...prevLocations, newLocation]);
        setMessage("Location added successfully!");
        resetForm(); // Reset the form fields after successful submission
      })
      .catch((error) => {
        console.error(error);
        setError("An error occurred while saving the location.");
      });
  };

  const handleClose = () => {
    navigate("/Locations"); // Redirect to /CustomerList
  };

  return (
    <Box m="20px">
      <Header
        title="Create Location"
        subtitle="Create a New Location and assign it to Route"
      />
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 10, right: 10 }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "16px", marginRight: "5px" }}
        >
          Close
        </Typography> <CloseIcon />
      </IconButton>
      <Card>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={locationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form fields for Location Code, Pincode, and Location Name */}
                <Box display="flex" justifyContent="space-between">
                  <TextField
                    fullWidth
                    id="locationCode"
                    label="Location Code"
                    value={values.locationCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.locationCode && Boolean(errors.locationCode)}
                    helperText={touched.locationCode && errors.locationCode}
                    margin="normal"
                    sx={{ mr: 2 }}
                  />
                  <TextField
                    fullWidth
                    id="pincode"
                    label="Pincode"
                    value={values.pincode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.pincode && Boolean(errors.pincode)}
                    helperText={touched.pincode && errors.pincode}
                    margin="normal"
                    sx={{ ml: 2 }}
                  />
                </Box>
                <TextField
                  fullWidth
                  id="location"
                  label="Location Name"
                  value={values.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.location && Boolean(errors.location)}
                  helperText={touched.location && errors.location}
                  margin="normal"
                  sx={{ mt: 2 }}
                />

                {/* Success and Error Messages */}
                {message && (
                  <Typography color="success.main">{message}</Typography>
                )}
                {error && <Typography color="error.main">{error}</Typography>}

                {/* Submit Button */}
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    mt: 2,
                  }}
                >
                  Add Location
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

// Validation schema for the form fields
const locationSchema = yup.object().shape({
  locationCode: yup.string().required("Location code is required"),
  pincode: yup.string().required("Pincode is required"),
  location: yup.string().required("Location name is required"),
});

// Initial form values
const initialValues = {
  locationCode: "",
  pincode: "",
  location: "",
};

export default CreateLocation;
