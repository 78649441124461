import {
  Box,
  Button,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
  IconButton,
} from "@mui/material";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataCustomers } from "../../data/productData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
// import CustomerData from "../../data/CustomerData";
import { iepaProd } from "../../constants/global";
import { useNavigate } from "react-router-dom"; // As
import CloseIcon from "@mui/icons-material/Close";

const CustomerList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows

  const [openModal, setOpenModal] = useState(false);
  const [priceRule, setPriceRule] = useState("default");
  const [paymentTerm, setPaymentTerm] = useState([]);
  const [creditCheck, setCreditCheck] = useState(false);
  const [creditAmount, setCreditAmount] = useState("");
  const [override, setOverride] = useState(false);
  const [overrideCount, setOverrideCount] = useState("");
  const [savedDetails, setSavedDetails] = useState([]);
  const [filterText, setFilterText] = useState(""); // Filter text state
  const [customers, setCustomers] = useState(null);
  const [error, setError] = useState(null); //Error
  const navigate = useNavigate(); // For page navigation
  const [paymentTermSelected, setPaymentTermSelected] = useState(null);
  const [message, setMessage] = useState(null);

  const [rows, setRows] = useState(customers);
  const [loading, setLoading] = useState(false);

  const [cityState, setCityState] = useState(null);
  const [cities, setCities] = useState(null);
  const [states, setStates] = useState(null);

  // const [opt, setOpt] = useState(null);
  const fetchCityState = async (pincode) => {
    setCityState(null);
    fetch(
      `${iepaProd.iepaUserSrvAPI}/iepa/api/city/state/list/pincode/${pincode}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to check structure
        // setCustomers(data);
        setCities(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError("Error fetching City State information: " + error.message);
        }
      });
  };

  const fetchCustomers = async () => {
    setRows(null);
    fetch(`${iepaProd.iepaUserSrvAPI}/customer/all-customers/org/1`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to check structure
        setCustomers(data);
        setRows(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError("Error fetching Customer data: " + error.message);
        }
      });
  };
  // const [orders, setOrders] = useState([]);

  const fetchPaymentTerms = async () => {
    // const opt = [];
    fetch(`${iepaProd.iepaOrderMgmtAPI}/orm/payment_terms/org/1`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to check structure
        setPaymentTerm(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError("Error fetching Payment Terms data: " + error.message);
        }
      });
  };

  const formatDate = (date = new Date()) => {
    return date.split("T")[0];
  };

  // Filtered rows based on filterText
  const filteredRows = customers
    ? customers.filter(
        (row) =>
          row?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
          row?.ownerName?.toLowerCase().includes(filterText.toLowerCase()) ||
          row?.contact?.email
            ?.toLowerCase()
            .includes(filterText.toLowerCase()) ||
          row?.contact?.phone
            ?.toLowerCase()
            .includes(filterText.toLowerCase()) ||
          // row.location.toLowerCase().includes(filterText.toLowerCase()) ||
          row?.route?.toLowerCase().includes(filterText.toLowerCase())
      )
    : null;

  const handleSave = (event) => {
    const profileData = {
      customerId: selectedRows[0],
      // name: "",
      // description: "",
      paymentTermsId: paymentTermSelected,
      creditCheck: creditCheck,
      creditLimit: creditAmount,
      overrideCheckCount: overrideCount,
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaUserSrvAPI}/customer/save/profile/org/1`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(profileData),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Profile added successfully!");
        setError(null);
      })
      .catch((error) => {
        setError("Failed to add Profile. Please try again.");
        setMessage(null);
      });

    handleCloseModal();
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    // Reset form fields
    setPriceRule("default");
    // setPaymentTerm([]);
    setCreditCheck(false);
    setCreditAmount("");
    setOverride(false);
    setOverrideCount("");
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5, minWidth: 100 },
    {
      field: "name",
      headerName: "Company Name",
      flex: 1,
      minWidth: 150,
    },
    { field: "ownerName", headerName: "Owner Name", flex: 1, minWidth: 150 },
    {
      field: "type",
      headerName: "Customer Type",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
      valueGetter: (params, row) => row.contact?.email,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      type: "number",
      flex: 1,
      minWidth: 150,
      valueGetter: (params, row) =>
        row.contact?.phone ? row.contact?.phone : row.contact?.mobile,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      minWidth: 200,
      valueGetter: (params, row) => row.defaultLocation?.address?.address_1,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      type: "number",
      flex: 0.5,
      minWidth: 100,
      valueGetter: (params, row) => row.defaultLocation?.address?.postalCode,
    },
    { field: "location", headerName: "Location", flex: 1, minWidth: 150 },
    { field: "route", headerName: "Route", flex: 1, minWidth: 150 },
    // {
    //   field: "location",
    //   headerName: "Location",
    //   flex: 0.5,
    //   minWidth: 100,
    //   // valueGetter: (params, row) => row.defaultLocation?.address?.postalCode
    // },
    {
      field: "profile",
      headerName: "Profile",
      flex: 1,
      minWidth: 100,
      // valueGetter: (params, row) => row.defaultLocation?.address?.postalCode,
    },
    {
      field: "taxCategory",
      headerName: "Tax Category",
      flex: 1,
      minWidth: 100,
      // valueGetter: (params, row) => row.defaultLocation?.address?.postalCode,
    },
  ];

  useEffect(() => {
    fetchCustomers();
    fetchPaymentTerms();
    setLoading(true);
  }, []);

  return (
    <Box m="20px">
      <Header title="Customer List" subtitle="List of Registered Customers" />
      <Box display="flex" justifyContent="flex" mb="20px">
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../CreateCustomer")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          + Add Customer
        </Button>
        {/* Button enabled when at least one checkbox is selected */}
        <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1} // Enabled only if at least one row is selected
          sx={{
            backgroundColor:
              selectedRows.length > 0
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => {
            navigate("/ViewCustomer", {
              state: { selectedRows, customers }, // Passing selectedRows state to ViewItem component
            });
          }}
        >
          View Customer
        </Button>

        {/* Button enabled when exactly one checkbox is selected */}
        <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1} // Enabled only if exactly one row is selected
          onClick={handleOpenModal}
          sx={{
            backgroundColor:
              selectedRows.length === 1
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Manage Profile
        </Button>
        {/* TextField for filtering */}
        <TextField
          label="Search"
          variant="outlined"
          value={filterText}
          onChange={(e) => {
            setFilterText(e.target.value);
          }}
          sx={{ width: "30%", margin: "10px" }}
        />
      </Box>
      <Box
        m="0 0 0 0"
        height="78vh"
        // checkboxSelection
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
            whiteSpace: "normal", // Allows text wrapping
            wordWrap: "break-word", // Ensures text breaks properly
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredRows} // Ensure this contains valid data with unique IDs
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(idSelection) => {
            setSelectedRows(idSelection);
          }}
          // onSelectionModelChange={(newSelection) => {
          //   setSelectedRows(newSelection);
          // }}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {/* Close button */}
          <IconButton
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "text.secondary", // Optional: Adjust the color if needed
            }}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
          <h2>Manage Profile</h2>
          <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
            <TextField
              fullWidth
              required
              id="priceRule"
              name="priceRule"
              label="Price Rule"
              value={priceRule}
              onChange={(e) => setPriceRule(e.target.value)}
              select
              SelectProps={{
                native: true,
              }}
              margin="normal"
              sx={{ mr: 2 }}
            >
              <option value="default">Default</option>
              <option value="rule 1">Rule 1</option>
              <option value="rule 2">Rule 2</option>
            </TextField>

            <TextField
              fullWidth
              required
              id="paymentTerm"
              name="paymentTerm"
              label="Payment Term"
              value={paymentTermSelected}
              onChange={(e) => setPaymentTermSelected(e.target.value)}
              select
              SelectProps={{
                native: true,
              }}
              margin="normal"
            >
              <option value="-1">-- Select Payment Term --</option>
              {paymentTerm?.map((term) => (
                <option key={term.id} value={term.id}>
                  {term.description}
                </option>
              ))}
            </TextField>
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={creditCheck}
                  onChange={() => setCreditCheck(!creditCheck)}
                />
              }
              label="Credit Check"
            />
          </Box>
          {creditCheck && (
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                required
                id="creditAmount"
                name="creditAmount"
                label="Credit Amount"
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
                type="number"
                margin="normal"
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={override}
                  onChange={() => setOverride(!override)}
                />
              }
              label="Override"
            />
          </Box>
          {override && (
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                required
                id="overrideCount"
                name="overrideCount"
                label="Override Count"
                value={overrideCount}
                onChange={(e) => setOverrideCount(e.target.value)}
                type="number"
                margin="normal"
              />
            </Box>
          )}

<Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
            <TextField
              fullWidth
              required
              id="priceRule"
              name="priceRule"
              label="Item category"
              value={priceRule}
              onChange={(e) => setPriceRule(e.target.value)}
              select
              SelectProps={{
                native: true,
              }}
              margin="normal"
              sx={{ mr: 2 }}
            >
              <option value="default">Default</option>
              <option value="rule 1">Rule 1</option>
              <option value="rule 2">Rule 2</option>
            </TextField>

            <TextField
              fullWidth
              required
              id="paymentTerm"
              name="paymentTerm"
              label="Favourite List"
              value={paymentTermSelected}
              onChange={(e) => setPaymentTermSelected(e.target.value)}
              select
              SelectProps={{
                native: true,
              }}
              margin="normal"
            >
              <option value="-1">-- Select Payment Term --</option>
              {paymentTerm?.map((term) => (
                <option key={term.id} value={term.id}>
                  {term.description}
                </option>
              ))}
            </TextField>
          </Box>

          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[400],
                fontSize: "14px",
                fontWeight: "bold",
                margin: "10px",
                display: "flex",
                justifyContent: "center",
              }}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomerList;
