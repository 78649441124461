import {
  Box,
  Button,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
  InputAdornment,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableHead,
  TableBody,
  Checkbox,
} from "@mui/material";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { iepaProd } from "../../constants/global";
import { useNavigate } from "react-router-dom";
import React from "react";

const UserList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  const [selectedRoute, setSelectedRoute] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]); // User data
  const [routes, setRoutes] = useState([]); // Route data
  const [error, setError] = useState(null); // Error handling
  const navigate = useNavigate(); // For page navigation
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterText, setFilterText] = useState("");

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5, minWidth: 100 },
    { field: "username", headerName: "UserName", flex: 1, minWidth: 150 },
    {
      field: "displayName",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      valueGetter: (params, row) => row?.contact?.displayName,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
      valueGetter: (params, row) => row?.contact?.email,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 150,
      valueGetter: (params, row) => row?.contact?.phone,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      minWidth: 200,
      valueGetter: (params, row) => row?.address?.address_1,
    },
    { field: "routesIds", headerName: "Route", flex: 1, minWidth: 150 },
  ];

  const fetchUsers = () => {
    fetch(`${iepaProd.iepaUserSrvAPI}/user/all-users/org/1`)
      .then((response) => {
        // console.log(response.json());
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        return response.json();
      })
      .then((data) => setUsers(data))
      .catch((error) => setError("Error fetching user data"));
  };
  useEffect(() => {
    fetchUsers();
    // fetch(`${iepaProd.iepaUserSrvAPI}/user/all-users/org/1`)
    //   .then((response) => {
    //     // console.log(response.json());
    //     if (!response.ok) {
    //       throw new Error("Failed to fetch users");
    //     }
    //     return response.json();
    //   })
    //   .then((data) => setUsers(data))
    //   .catch((error) => setError("Error fetching user data"));
  }, []);

  // Filtered rows based on search query
  const filteredRows = users.filter((users) => {
    const searchTerm = filterText.toLowerCase();
    return (
      users.id?.toString().includes(searchTerm) ||
      // users.id?.toLowerCase().includes(searchTerm) ||
      users.username?.toLowerCase().includes(searchTerm) ||
      users.contact.displayName?.toString().includes(searchTerm) ||
      users.contact.email?.toString().includes(searchTerm) ||
      users.contact.phone?.toString().includes(searchTerm) ||
      users.address.address_1?.toString().includes(searchTerm) ||
      users.route?.toString().includes(searchTerm)
    );
  });

  const fetchRoutes = () => {
    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/org/1`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setRoutes(data);
        } else {
          console.error("Invalid route data format:", data);
          setError("Error fetching route data");
        }
      })
      .catch((error) => {
        console.error("Error fetching route data:", error);
        setError("Error fetching route data");
      });
  };
  useEffect(() => {
    fetchRoutes();
    // fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/org/1`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (Array.isArray(data)) {
    //       setRoutes(data);
    //     } else {
    //       console.error("Invalid route data format:", data);
    //       setError("Error fetching route data");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching route data:", error);
    //     setError("Error fetching route data");
    //   });
  }, []);

  const handleAssign = () => {
    if (selectedRows.length === 1) {
      const user = users.find((u) => u.id === selectedRows[0]);
      setSelectedUser(user);
      setOpenAssignModal(true);
    }
  };

  useEffect(() => {
    if (!openAssignModal) {
      setSelectedRoute({}); // Clear selections when the modal closes
    }
  }, [openAssignModal]);

  const handleSaveRoutes = async () => {
    const selectedRouteIds = Object.keys(selectedRoute).filter(
      (id) => selectedRoute[id]
    );

    const updatedRoute = {
      id: selectedUser.id,
      routesIds: selectedRouteIds.join(","),
      orgId: "1",
      createdBy: 1,
    };

    try {
      const response = await fetch(
        `${iepaProd.iepaUserSrvAPI}/user/assign/route/org/1`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updatedRoute),
        }
      );

      if (response.ok) {
        alert("Route saved successfully!");
      } else {
        alert("Failed to save route. Please try again.");
      }
    } catch (error) {
      alert("A network error occurred. Please check your connection.");
    }

    fetchUsers();
    setOpenAssignModal(false);
  };

  const handleRouteChange = (routeId) => {
    setSelectedRoute((prev) => ({
      ...prev,
      [routeId]: !prev[routeId],
    }));
  };

  const filteredRoutes = routes.filter(
    (route) =>
      route?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      route?.shortName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      route?.code?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box m="20px">
      <Header title="Manage User" subtitle="List of Registered Users" />
      <Box display="flex" justifyContent="flex" mb="20px">
        {/* <Button
          color="secondary"
          variant="contained"
          onClick={() => window.history.back()}
          sx={{
            backgroundColor: "black",
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Go Back
        </Button> */}

        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../AddUser")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          + Add User
        </Button>

        <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1}
          onClick={handleAssign}
          sx={{
            backgroundColor:
              selectedRows.length === 1
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Assign
        </Button>

        <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1} // Enabled only if at least one row is selected
          sx={{
            backgroundColor:
              selectedRows.length > 0
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => {
            navigate("/ViewUser", {
              state: { selectedRows, users }, // Passing selectedRows state to ViewItem component
            });
          }}
        >
          View User
        </Button>

        {/* TextField for filtering */}
        <TextField
          label="Search"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{ width: "30%", margin: "10px" }}
        />
      </Box>

      <Box
        height="78vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "1px solid grey" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.blueAccent[900],
          },
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          // onSelectionModelChange={(ids) => setSelectedRows(ids)}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      <Modal open={openAssignModal} onClose={() => setOpenAssignModal(false)}>
        <Box
          sx={{
            width: "80%",
            maxWidth: "600px",
            margin: "100px auto",
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <h3>Assign Route</h3>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <TableContainer sx={{ maxHeight: "300px", overflow: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Short Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRoutes.map((route) => (
                  <TableRow key={route.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedRoute[route.id] || false}
                        onChange={() => handleRouteChange(route.id)}
                      />
                    </TableCell>
                    <TableCell>{route.code}</TableCell>
                    <TableCell>{route.name}</TableCell>
                    <TableCell>{route.shortName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[400],
              fontSize: "14px",
              fontWeight: "bold",
              margin: "10px",
            }}
            variant="contained"
            onClick={handleSaveRoutes}
          >
            Save
          </Button>
          <Button
            onClick={() => setOpenAssignModal(false)}
            variant="outlined"
            sx={{ mt: 0, ml: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserList;
