import {
  Box,
  Button,
  TextField,
  useTheme,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { React, useState } from "react";

import { useNavigate } from "react-router-dom"; // Assuming you use react-router-dom for routing
import { iepaProd } from "../../constants/global";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const ReturnOrder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate(); // For page navigation
  const steps = [
    "Product Info",
    "Dimensions and Pricing",
    "Flags and Additional Info",
  ];
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Form");
    const data = {
      itemName: values.ItemName,
      description: values.description,
      sku: values.sku,
      type: values.type,
      category: "",
      brand: values.brand,
      unitLength: 1.0,
      unitWidth: 1.0,
      unitHeight: 10.0,
      dimensionUom: "INCHES",
      colour: "RED",
      standardMrp: values.standardMrp,
      orderableFlag: 1,
      availableOnCredit: 1,
      uom: values.uom,
      sellableFlag: 1,
      unitWeight: 300,
      weightUom: "GRAM",
      sellLooseItemsFlag: 1,
      status: "ACTIVE",
      shippableFlag: values.shippableFlag,
      returnableFlag: values.returnableFlag,
      orderableOnWeb: 1,
      exportRestrictionFlag: 0,
      importRestrictionFlag: 0,
      code: values.sku,
      orgId: "1",
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/items/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          // Handle HTTP error status (like 500, 400, etc.)
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // Only attempt to parse JSON if response is OK
      })
      .then((data) => {
        setMessage("Item added successfully!");
        setError(null);
        resetForm();
      })
      .catch((error) => {
        setError(`Failed to add Item. Please try again. Error: ${error.message}`);
        setMessage(null);
      });
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleDownloadTemplate = () => {
    const csvData = [
      [
        "description",
        "sku",
        "type",
        "unitLength",
        "unitWidth",
        "unitHeight",
        "sizeUom",
        "standardMrp",
        "orderableFlag",
        "availableOnCredit",
        "eoaDate",
        "activeStartDate",
        "activeEndDate",
        "eosDate",
        "uom",
        "sellableFlag",
        "unitWeight",
        "weightUom",
        "sellLooseItemsFlag",
        "minOrderQuantity",
        "maxOrderQuantity",
        "status",
        "standardLotSize",
        "shippableFlag",
        "returnableFlag",
        "perishableFlag",
        "orderableOnWeb",
        "contents",
        "contentPrice",
        "code",
        "exportRestrictionFlag",
        "importRestrictionFlag",
        "taxCode",
      ],
    ];
    const csvString = csvData.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, 'product_template.csv');
  };

  const [values, setValues] = useState({
    shippableFlag: false,
    returnableFlag: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Convert value to boolean true/false
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value === "true", // The value should be a boolean
    }));
  };

  const handleCsvUpload = (event) => {
    const file = event.target.files[0];
    console.log(file);
  };

  const handleCancel = () => {
    navigate('/Delivery'); // Redirect to the 'Delivery' page (update the path as needed)
  };

  const columns = [
    {
        field: "itemCode",
        headerName: "Item Code",
        flex: 1, // More space for longer names
        cellClassName: "name-column--cell",
    },
    {
        field: "itemName",
        headerName: "Item Name",
        flex: 1,
    },
    
    {
        field: "quantity",
        headerName: "Quantity",
        flex: 1,
        editable: true,
    },
    {
        field: "price",
        headerName: "Price",
        flex: 1,
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
      editable: true,
  },
    
];


  return (
    <Box m="20px">
      <Header title="Return Order" subtitle="Return Order Details" />

      <Card>
        <CardContent>
        <Box
                m="0 0 0 0"
                height="78vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "1px solid grey",
                        whiteSpace: "normal", // Allows text wrapping
                        wordWrap: "break-word", // Ensures text breaks properly
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                        fontSize: "14px",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[900],
                        height: "5vh",
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    //   rows={filteredRows}
                    columns={columns}
                    checkboxSelection
                    //   onRowSelectionModelChange={(newSelection) =>
                    //     setSelectedRows(newSelection)
                    //   }
                    // onSelectionModelChange={(newSelection) =>
                    //   setSelectedRows(newSelection)
                    // }
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
                <Box display="flex" gap={2} >
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.primary[400],
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                    variant="contained"
                  >
                    Save
                  </Button>

                  <Button
                    type="button" // Change to 'button' instead of 'submit' since you're not submitting the form
                    variant="outlined"
                    sx={{
                      mt: "10px", // Aligns it vertically with the "Save" button
                      ml: "10px", // Ensures proper horizontal space between buttons
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                    onClick={handleCancel} // Handle cancel action
                  >
                    Cancel
                  </Button>
                </Box>
          
        </CardContent>
      </Card>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  ItemNo: yup.string().required("Required"),
  ItemName: yup.string().required("Required"),
  description: yup.string().required("Required"),
  type: yup.string().required("Required"),
  // uom: yup.string().required("Required"),
  brand: yup.string().required("Required"),
  standardMrp: yup.number().required("Required"),
  shippableFlag: yup.string().required("Required"),
  returnableFlag: yup.string().required("Required"),
});

const initialValues = {
  description: "",
  sku: "",
  type: "",
  unitOfMeasure: "",
  unitWeight: "",
  standardMrp: "",
  shippableFlag: "",
  returnableFlag: "",
};

export default ReturnOrder;