import { useState, useEffect, useRef, useContext, Typography } from "react";
import { Box, IconButton, useTheme, Card, InputBase } from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AppsIcon from "@mui/icons-material/Apps";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "./Menu";
import SideNav from "./SideNav";
import React from "react";
import UserMenu from "./UserMenu";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [showCard, setShowCard] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const sideNavRef = useRef(null);
  const menuRef = useRef(null);
  const userMenuRef = useRef(null);

  const handleIconClick = () => {
    setShowCard(!showCard);
  };

  const toggleSideNav = () => {
    setIsSideNavOpen((prevState) => !prevState);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen((prevState) => !prevState);
  };

  // Mouse enter and leave handlers for closing SideNav
  const handleMouseEnter = (e) => {
    // Prevent closing if the mouse is over the SideNav
    if (sideNavRef.current && sideNavRef.current.contains(e.target)) {
      setIsSideNavOpen(true);
    }
    if (userMenuRef.current && userMenuRef.current.contains(e.target)) {
      setIsUserMenuOpen(true);
    }
  };

  const handleMouseLeave = (e) => {
    // Close SideNav if mouse leaves the SideNav area
    if (sideNavRef.current && !sideNavRef.current.contains(e.target)) {
      setIsSideNavOpen(false);
    }
    if (userMenuRef.current && !userMenuRef.current.contains(e.target)) {
      setIsUserMenuOpen(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      backgroundColor={colors.primary[400]}
      sx={{
        boxShadow: "3px 1px 10px #a5a6a8",
        zIndex: 1100,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
      }}
    >
      {/* Left Section: Apps Icon */}
      <Box display="flex" sx={{ gap: 1 }}>
        <IconButton onClick={handleIconClick} className="apps-icon">
          <AppsIcon />
        </IconButton>
        {showCard && (
          <Card
            refs={menuRef}
            sx={{
              position: "absolute",
              top: "50px",
              left: "0",
              zIndex: 1200,
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
              width: "150px",
            }}
          >
            <Menu />
          </Card>
        )}
      </Box>

      {/* Search Bar */}
      <Box
        backgroundColor={colors.primary[400]}
        borderRadius="10px"
        border="1px solid #a5a6a8"
        display="flex"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* Right Section: Icons */}
      <Box display="flex" alignItems="center">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={toggleUserMenu}>
          <PersonOutlinedIcon />
        </IconButton>
        <IconButton>IEPA</IconButton>

        <IconButton onClick={toggleSideNav}>
          {isSideNavOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Box>

      {/* Side Navigation */}
      <SideNav
        refs={sideNavRef}
        isOpen={isSideNavOpen}
        onClose={() => setIsSideNavOpen(false)}
        onMouseEnter={handleMouseEnter} // Handle mouse enter
        onMouseLeave={handleMouseLeave} // Handle mouse leave
      />
      {/* User Menu Navigation */}
      {
        <UserMenu
          refs={userMenuRef}
          isOpen={isUserMenuOpen}
          onClose={() => setIsUserMenuOpen(false)}
          onMouseEnter={handleMouseEnter} // Handle mouse enter
          onMouseLeave={handleMouseLeave} // Handle mouse leave
        />
      }
    </Box>
  );
};

export default Topbar;
