import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  MenuItem,
  IconButton,
} from "@mui/material";

import { useTheme } from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../../constants/global";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const ViewItem = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0); // To manage active tab
  const navigate = useNavigate(); // For page navigation

  const [loading, setLoading] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error
  const [locationOptions, setLocationOptions] = useState(null);
  const [routeOptions, setRouteOptions] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cities, setCities] = useState(null);
  const location = useLocation();
  const [itemLocations, setItemLocations] = useState([]);

  const { selectedRows, products } = location.state || {};

  const fetchItem = (itemId) => {
    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/items/${itemId}/org/1`)
      .then((response) => response.json())
      .then((data) => {
        // Log the data to check structure
        console.log(data);
        setSelectedProduct(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError("Error fetching Item data: " + error.message);
        }
      });
  };

  // When selectedRows change, update the selectedProduct
  useEffect(() => {
    if (selectedRows.length > 0) {
      // Assuming selectedRows contains an array with one object that has an 'id' field
      const selectedId = selectedRows[0];
      // setItemId(selectedRows[0]);
      fetchItem(selectedRows[0]);
      fetchItemLocations();
      // const product = products.find((p) => p.id === selectedId);
      // setSelectedProduct(product);
      // } else {
      // setSelectedProduct(null); // Reset if no product is selected
    }
  }, [selectedRows, products]); // Re-run whenever selectedRows or products change

  // Handle dynamic initial values based on selectedProduct

  const fetchItemLocations = async () => {
    // setCities(defaultCities);
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/container/status/active/org/1`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to check structure
        setItemLocations(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError(
            "Error fetching Item Locations information: " + error.message
          );
        }
      });
  };

  const handleClose = () => {
    navigate("/ProductList"); // Redirect to /CustomerList
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSaveItem = (values, { resetForm }) => {
    console.log(values);

    const data = {
      id: selectedRows[0],
      itemName: values.itemName,
      description: values.itemDescription,
      sku: values.itemSku,
      type: values.itemType,
      uom: values.itemMeasure,
      standardMrp: values.itemMrp,
      brand: values.brand,
      taxCode: values.taxCode,
      status: "ACTIVE",
      orgId: "1",
      updatedBy: 1,
    };

    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/items/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          // Handle HTTP error status (like 500, 400, etc.)
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // Only attempt to parse JSON if response is OK
      })
      .then((data) => {
        setMessage("Item updated successfully!");
        setError(null);
        resetForm();
      })
      .catch((error) => {
        setError(
          `Failed to update an Item. Please try again. Error: ${error.message}`
        );
        setMessage(null);
      });
  };

  const initialItemValues = {
    itemName: selectedProduct?.itemName,
    itemDescription: selectedProduct?.description,
    itemSku: selectedProduct?.sku,
    itemType: selectedProduct?.type,
    itemMeasure: selectedProduct?.uom,
    itemMrp: selectedProduct?.standardMrp,
    brand: selectedProduct?.brand,
    taxCode: selectedProduct?.taxCode,
  };
  const tabContent = [
    {
      label: "Item Details",
      content: selectedProduct ? (
        <Formik
          initialValues={initialItemValues}
          validationSchema={overviewSchema}
          onSubmit={handleSaveItem}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="itemName"
                  name="itemName"
                  label="Item Name"
                  value={values.itemName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemName && Boolean(errors.itemName)}
                  helperText={touched.itemName && errors.itemName}
                />
                <TextField
                  fullWidth
                  required
                  id="itemDescription"
                  name="itemDescription"
                  label="Item Description"
                  value={values.itemDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.itemDescription && Boolean(errors.itemDescription)
                  }
                  helperText={touched.itemDescription && errors.itemDescription}
                />
                <TextField
                  fullWidth
                  id="itemSku"
                  name="itemSku"
                  label="SKU"
                  value={values.itemSku}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemSku && Boolean(errors.itemSku)}
                  helperText={touched.itemSku && errors.itemSku}
                />
                <TextField
                  fullWidth
                  id="itemType"
                  name="itemType"
                  label="Type"
                  value={values.itemType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemType && Boolean(errors.itemType)}
                  helperText={touched.itemType && errors.itemType}
                />
                <TextField
                  fullWidth
                  required
                  id="brand"
                  label="Brand"
                  value={values.brand || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.brand && Boolean(errors.brand)}
                  helperText={touched.brand && errors.brand}
                />
                <TextField
                  fullWidth
                  id="itemMeasure"
                  name="itemMeasure"
                  label="Unit of Measure"
                  value={values.itemMeasure}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemMeasure && Boolean(errors.itemMeasure)}
                  helperText={touched.itemMeasure && errors.itemMeasure}
                />
                <TextField
                  fullWidth
                  id="itemWeight"
                  label="Unit Weight"
                  value={values.itemWeight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemWeight && Boolean(errors.itemWeight)}
                  helperText={touched.itemWeight && errors.itemWeight}
                />

                <TextField
                  fullWidth
                  id="itemMrp"
                  label="Standard MRP"
                  value={values.itemMrp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemMrp && Boolean(errors.itemMrp)}
                  helperText={touched.itemMrp && errors.itemMrp}
                />
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>
                <Button
                  type="reset"
                  disabled={!dirty || isSubmitting}
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      ) : (
        <Typography>Loading...</Typography>
      ),
    },
    {
      label: "Item Location Details",
      content: selectedProduct ? (
        <Formik
          initialValues={initialValues}
          validationSchema={contactSchema}
          onSubmit={handleSaveItem}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="building"
                  label="Building"
                  value={values.building}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.building && Boolean(errors.building)}
                  helperText={touched.building && errors.building}
                />
                <TextField
                  fullWidth
                  required
                  id="floor"
                  label="Floor"
                  value={values.floor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.floor && Boolean(errors.floor)}
                  helperText={touched.floor && errors.floor}
                />
                <TextField
                  fullWidth
                  required
                  id="door"
                  label="Door #"
                  value={values.door}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.door && Boolean(errors.door)}
                  helperText={touched.door && errors.door}
                />
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Typography>Loading...</Typography>
      ),
    },
    {
      label: "Item Parameters",
      content: selectedProduct ? (
        <Formik
          initialValues={initialValues}
          validationSchema={addressSchema}
          onSubmit={handleSaveItem}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  select
                  id="itemCatg"
                  name="itemCatg"
                  label="Item category Templates"
                  value={values.itemCatg}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemCatg && Boolean(errors.itemCatg)}
                  helperText={touched.itemCatg && errors.itemCatg}
                >
                  <MenuItem value="1">Bakery Items</MenuItem>
                  <MenuItem value="2">Diary Products</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  //  required
                  select
                  id="taxCategory"
                  name="taxCategory"
                  label="Tax Category"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities?.map((city, index) => (
                    <MenuItem key={index} value={city?.city}>
                      {city?.city}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  //  required
                  select
                  id="itemCategory"
                  name="itemCategory"
                  label="Item Category"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities?.map((city, index) => (
                    <MenuItem key={index} value={city?.city}>
                      {city?.city}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Typography>Loading...</Typography>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="View Item" subtitle="View/Edit Item Details" />
      <Box display="flex" justifyContent="flex" mb="20px">
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <Typography
            sx={{ fontWeight: "bold", fontSize: "16px", marginRight: "5px" }}
          >
            Close
          </Typography>{" "}
          <CloseIcon />
        </IconButton>
        {/* <Button
          color="secondary"
          variant="contained"
          onClick={() => window.history.back()} // Navigates back in history
          sx={{
            backgroundColor: "black",
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Go Back
        </Button> */}
        <Button
          color="secondary"
          variant="contained"
          // onClick={() => navigate("../ProductList")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Edit
        </Button>
      </Box>
      <Card>
        <CardContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabContent.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{
                  color: activeTab === index ? "black" : colors.blueAccent[700], // Active tab color
                  fontWeight: activeTab === index ? "bold" : "normal", // Optional: Bold for active tab
                }}
              />
            ))}
          </Tabs>

          <Box mt="20px">{tabContent[activeTab].content}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const overviewSchema = yup.object().shape({
  itemName: yup.string().required("Item Name is required"),
  itemDescription: yup.string().required("Item description is required"),
  itemMrp: yup.string().required("Standard MRP is required"),
});

const contactSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNo: yup
    .string()
    .matches(/^\d+$/, "Phone number is not valid")
    .required("Phone No is required"),
});

const addressSchema = yup.object().shape({
  address: yup.string().required("Address is required"),
  pincode: yup
    .string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits")
    .required("Pincode is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
});

const initialValues = {
  customerNo: "",
  companyName: "",
  ownerName: "",
  email: "",
  phoneNo: "",
  address: "",
  pincode: "",
  city: "",
  state: "",
};

export default ViewItem;
