import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";

import { useTheme } from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../../constants/global";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const AddProduct = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0); // To manage active tab
  const navigate = useNavigate(); // For page navigation

  const [loading, setLoading] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const defaultCities = [{ city: "Select City", state: "Select State" }];
  const [cities, setCities] = useState(defaultCities);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error
  const [locationOptions, setLocationOptions] = useState(null);
  const [routeOptions, setRouteOptions] = useState(null);

  const [contactId, setContactId] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const location = useLocation();

  const { selectedRows, products } = location.state || {};

  // When selectedRows change, update the selectedProduct
  useEffect(() => {
    if (!!selectedRows && selectedRows.length === 1) {
      // Assuming selectedRows contains an array with one object that has an 'id' field
      const selectedId = selectedRows[0];
      const product = products.find((p) => p.id === selectedId);
      setSelectedProduct(product);
    } else {
      setSelectedProduct(null); // Reset if no product is selected
    }
  }, [selectedRows, products]); // Re-run whenever selectedRows or products change
  const [values, setValues] = useState({
    shippableFlag: false,
    returnableFlag: false,
  });

  const [file, setFile] = useState(null);

  const handleItemDetailsFormSubmit = (values, { resetForm }) => {
    const data = {
      code: values.code,
      itemName: values.ItemName,
      description: values.description,
      sku: values.sku,
      type: values.type,
      category: "",
      brand: values.brand,
      unitLength: 1.0,
      unitWidth: 1.0,
      unitHeight: 10.0,
      dimensionUom: "INCHES",
      colour: "RED",
      standardMrp: values.standardMrp,
      orderableFlag: 1,
      availableOnCredit: 1,
      uom: values.uom,
      sellableFlag: 1,
      unitWeight: 300,
      weightUom: "GRAM",
      sellLooseItemsFlag: 1,
      status: "ACTIVE",
      shippableFlag: values.shippableFlag,
      returnableFlag: values.returnableFlag,
      orderableOnWeb: 1,
      exportRestrictionFlag: 0,
      importRestrictionFlag: 0,
      orgId: "1",
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/items/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          // Handle HTTP error status (like 500, 400, etc.)
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // Only attempt to parse JSON if response is OK
      })
      .then((data) => {
        setMessage("Item added successfully!");
        setError(null);
        resetForm();
      })
      .catch((error) => {
        setError(
          `Failed to add Item. Please try again. Error: ${error.message}`
        );
        setMessage(null);
      });
  };

  const handleClose = () => {
    navigate("/ProductList"); // Redirect to /CustomerList
  };
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  useEffect(() => {
    // fetchCityState();
    setLoading(true);
  }, []);
  console.log("initialValues", initialValues);
  const tabContent = [
    {
      label: "User Details",
      content: (
        <Formik
          onSubmit={handleItemDetailsFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="ItemName"
                  name="ItemName"
                  label="ItemName"
                  value={values.ItemName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ItemName && Boolean(errors.ItemName)}
                  helperText={touched.ItemName && errors.ItemName}
                />
                <TextField
                  fullWidth
                  id="itemSku"
                  label="SKU"
                  value={values.itemSku}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemSku && Boolean(errors.itemSku)}
                  helperText={touched.itemSku && errors.itemSku}
                />
                <TextField
                  fullWidth
                  id="itemType"
                  label="Type"
                  value={values.itemType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemType && Boolean(errors.itemType)}
                  helperText={touched.itemType && errors.itemType}
                />
                <TextField
                  fullWidth
                  // required
                  id="description"
                  name="description"
                  label="Description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  // required
                  id="brand"
                  name="brand"
                  label="Brand"
                  value={values.brand}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.brand && Boolean(errors.brand)}
                  helperText={touched.brand && errors.brand}
                  margin="normal"
                />

                <FormControl
                  fullWidth
                  // required
                  margin="normal"
                  sx={{ mr: 2 }}
                  error={touched.shippableFlag && Boolean(errors.shippableFlag)}
                >
                  <InputLabel id="shippableFlag-label">
                    Shippable Flag
                  </InputLabel>
                  <Select
                    labelId="shippableFlag-label"
                    id="shippableFlag"
                    name="shippableFlag"
                    value={values.shippableFlag} // Use boolean value directly
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Shippable Flag"
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                  {touched.shippableFlag && errors.shippableFlag && (
                    <FormHelperText>{errors.shippableFlag}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  // required
                  margin="normal"
                  error={
                    touched.returnableFlag && Boolean(errors.returnableFlag)
                  }
                >
                  <InputLabel id="returnableFlag-label">
                    Returnable Flag
                  </InputLabel>
                  <Select
                    labelId="returnableFlag-label"
                    id="returnableFlag"
                    name="returnableFlag"
                    value={values.returnableFlag} // Use boolean value directly
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Returnable Flag"
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                  {touched.returnableFlag && errors.returnableFlag && (
                    <FormHelperText>{errors.returnableFlag}</FormHelperText>
                  )}
                </FormControl>
                <TextField
                  fullWidth
                  id="itemMeasure"
                  label="Unit of Measure"
                  value={values.itemMeasure}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemMeasure && Boolean(errors.itemMeasure)}
                  helperText={touched.itemMeasure && errors.itemMeasure}
                />
                <TextField
                  fullWidth
                  id="itemWeight"
                  label="Unit Weight"
                  value={values.itemWeight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemWeight && Boolean(errors.itemWeight)}
                  helperText={touched.itemWeight && errors.itemWeight}
                />

                <TextField
                  fullWidth
                  id="itemMrp"
                  label="Standard MRP"
                  value={values.itemMrp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemMrp && Boolean(errors.itemMrp)}
                  helperText={touched.itemMrp && errors.itemMrp}
                />
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      ),
    },
    {
      label: "Item Location Details",
      content: (
        <Formik
          initialValues={initialValues}
          // validationSchema={contactSchema}
          // onSubmit={handleContactFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  // required
                  id="building"
                  label="Building"
                  value={values.building}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.building && Boolean(errors.building)}
                  helperText={touched.building && errors.building}
                />
                <TextField
                  fullWidth
                  // required
                  id="floor"
                  label="Floor"
                  value={values.floor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.floor && Boolean(errors.floor)}
                  helperText={touched.floor && errors.floor}
                />
                <TextField
                  fullWidth
                  // required
                  id="door"
                  label="Door No"
                  value={values.door}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.door && Boolean(errors.door)}
                  helperText={touched.door && errors.door}
                />
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
    {
      label: "Item Parameters",
      content: (
        <Formik
          initialValues={initialValues}
          // validationSchema={addressSchema}
          // onSubmit={handleAddressFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  select
                  id="itemCatg"
                  name="itemCatg"
                  label="Item category Templates"
                  value={values.itemCatg}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.itemCatg && Boolean(errors.itemCatg)}
                  helperText={touched.itemCatg && errors.itemCatg}
                >
                  <MenuItem value="1">Bakery Items</MenuItem>
                  <MenuItem value="2">Diary Products</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  //  required
                  select
                  id="taxCategory"
                  name="taxCategory"
                  label="Tax Category"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.city}>
                      {city.city}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  //  required
                  select
                  id="itemCategory"
                  name="itemCategory"
                  label="Item Category"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.city}>
                      {city.city}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
  ];
  const handleDownloadTemplate = () => {
    const csvData = [
      [
        "description",
        "sku",
        "type",
        "unitLength",
        "unitWidth",
        "unitHeight",
        "sizeUom",
        "standardMrp",
        "orderableFlag",
        "availableOnCredit",
        "eoaDate",
        "activeStartDate",
        "activeEndDate",
        "eosDate",
        "uom",
        "sellableFlag",
        "unitWeight",
        "weightUom",
        "sellLooseItemsFlag",
        "minOrderQuantity",
        "maxOrderQuantity",
        "status",
        "standardLotSize",
        "shippableFlag",
        "returnableFlag",
        "perishableFlag",
        "orderableOnWeb",
        "contents",
        "contentPrice",
        "code",
        "exportRestrictionFlag",
        "importRestrictionFlag",
        "taxCode",
      ],
    ];
    const csvString = csvData.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, 'product_template.csv');
  };

  const handleCsvUpload = async (event) => {
    const file = event.target.files[0];
    console.log(file);

    let formData = new FormData();
    formData.append("file", file);

    try {
      fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/items/upload/csv`, {
        method: "POST",
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },

        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            // Handle HTTP error status (like 500, 400, etc.)
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json(); // Only attempt to parse JSON if response is OK
        })
        .then((data) => {
          setMessage("Items added successfully!");
          setError(null);
          // resetForm();
        })
        .catch((error) => {
          setError(
            `Failed to add Item. Please try again. Error: ${error.message}`
          );
          setMessage(null);
        });
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Box m="20px">
      <Header title="Add Item" subtitle="Add Item Details" />
      <Box display="flex" justifyContent="flex" mb="20px">
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <Typography
            sx={{ fontWeight: "bold", fontSize: "16px", marginRight: "5px" }}
          >
            Close
          </Typography>{" "}
          <CloseIcon />
        </IconButton>
        {/* <Button
          color="secondary"
          variant="contained"
          onClick={() => window.history.back()} // Navigates back in history
          sx={{
            backgroundColor: "black",
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Go Back
        </Button> */}
        {/* <Button
          color="secondary"
          variant="contained"
          // onClick={() => navigate("../ProductList")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Edit
        </Button> */}
      </Box>

      <Card>
        <CardContent>
          <Box display="flex" justifyContent="space-between" mb="20px" gap={2}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              {tabContent.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  sx={{
                    color:
                      activeTab === index ? "black" : colors.blueAccent[700], // Active tab color
                    fontWeight: activeTab === index ? "bold" : "normal", // Optional: Bold for active tab
                  }}
                />
              ))}
            </Tabs>
            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDownloadTemplate}
              >
                Download CSV Template
              </Button>
              <Button variant="contained" color="secondary" component="label">
                Import CSV
                <input
                  type="file"
                  hidden
                  accept=".csv"
                  onChange={handleCsvUpload}
                />
              </Button>
            </Box>
          </Box>
          <Box mt="20px">{tabContent[activeTab].content}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  ItemNo: yup.string().required("Required"),
  ItemName: yup.string().required("Required"),
  description: yup.string().required("Required"),
  type: yup.string().required("Required"),
  // uom: yup.string().required("Required"),
  brand: yup.string().required("Required"),
  standardMrp: yup.number().required("Required"),
  shippableFlag: yup.string().required("Required"),
  returnableFlag: yup.string().required("Required"),
});

const initialValues = {
  description: "",
  sku: "",
  type: "",
  unitOfMeasure: "",
  unitWeight: "",
  standardMrp: "",
  shippableFlag: "",
  returnableFlag: "",
};

export default AddProduct;
