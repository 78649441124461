import {
    Box,
    Button,
    Modal,
    TextField,
    Switch,
    FormControlLabel,
    Snackbar
  } from "@mui/material";
  
  import { DataGrid, GridToolbar } from "@mui/x-data-grid";
  import { tokens } from "../../theme";
  // import { mockDataCustomers } from "../../data/productData";
  import Header from "../../components/Header";
  import { useTheme } from "@mui/material";
  import { useState, useEffect } from "react";
  // import CustomerData from "../../data/CustomerData";
  import { iepaProd } from "../../constants/global";
  import { useNavigate } from "react-router-dom"; // As
  
  const LoadList = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  
    const [openModal, setOpenModal] = useState(false);
    const [priceRule, setPriceRule] = useState("default");
    const [paymentTerm, setPaymentTerm] = useState([]);
    const [creditCheck, setCreditCheck] = useState(false);
    const [creditAmount, setCreditAmount] = useState("");
    const [override, setOverride] = useState(false);
    const [overrideCount, setOverrideCount] = useState("");
    const [savedDetails, setSavedDetails] = useState([]);
    const [filterText, setFilterText] = useState(""); // Filter text state
    const [customers, setCustomers] = useState(null);
    const [error, setError] = useState(null); //Error
    const navigate = useNavigate(); // For page navigation
    const [paymentTermSelected, setPaymentTermSelected] = useState(null);
    const [message, setMessage] = useState(null);
  
    const [rows, setRows] = useState(customers);
    const [loading, setLoading] = useState(false);
  
    const [cityState, setCityState] = useState(null);
    const [cities, setCities] = useState(null);
    const [states, setStates] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
    const [alertMessage, setAlertMessage] = useState(""); // State for alert message
  

     <Snackbar
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
            message={alertMessage} // Show alert message
            autoHideDuration={3000} // Auto hide after 3 seconds
          />
    // const [opt, setOpt] = useState(null);
    const fetchCityState = async (pincode) => {
      setCityState(null);
      console.log("Fetching Customers");
      fetch(
        `${iepaProd.iepaUserSrvAPI}/iepa/api/city/state/list/pincode/${pincode}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data); // Log the data to check structure
          // setCustomers(data);
          setCities(data);
        })
        .catch((error) => {
          if (error.message.substring("JSON input") <= 0) {
            setError("Error fetching City State information: " + error.message);
          }
        });
    };
  
    const fetchCustomers = async () => {
      setRows(null);
      console.log("Fetching Customers");
      fetch(`${iepaProd.iepaUserSrvAPI}/customer/all-customers/org/1`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data); // Log the data to check structure
          setCustomers(data);
          setRows(data);
        })
        .catch((error) => {
          if (error.message.substring("JSON input") <= 0) {
            setError("Error fetching Customer data: " + error.message);
          }
        });
    };
    // const [orders, setOrders] = useState([]);
  
    const fetchPaymentTerms = async () => {
      console.log("Fetching Payment Terms");
      // const opt = [];
      fetch(`${iepaProd.iepaOrderMgmtAPI}/orm/payment_terms/org/1`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data); // Log the data to check structure
          setPaymentTerm(data);
        })
        .catch((error) => {
          if (error.message.substring("JSON input") <= 0) {
            setError("Error fetching Payment Terms data: " + error.message);
          }
        });
    };
  
    const formatDate = (date = new Date()) => {
      return date.split("T")[0];
    };
  
    // Filtered rows based on filterText
    const filteredRows = customers
      ? customers.filter(
          (row) =>
            row?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
            row?.ownerName?.toLowerCase().includes(filterText.toLowerCase()) ||
            row?.contact?.email
              ?.toLowerCase()
              .includes(filterText.toLowerCase()) ||
            row?.contact?.phone
              ?.toLowerCase()
              .includes(filterText.toLowerCase()) ||
            // row.location.toLowerCase().includes(filterText.toLowerCase()) ||
            row?.route?.toLowerCase().includes(filterText.toLowerCase())
        )
      : null;
  
    const handleSave = (event) => {
      // const newDetail = {
      //   priceRule,
      //   paymentTerm,
      //   creditAmount: creditCheck ? creditAmount : null,
      //   overrideCount: override ? overrideCount : null,
      // };
      // setSavedDetails((prev) => [...prev, newDetail]);
  
      console.log("Payment Terms Selected::" + paymentTermSelected);
      console.log(
        "Row ID Selected ::" +
          selectedRows +
          "\ncreditCheck::" +
          creditCheck +
          "\ncreditAmount:: " +
          creditAmount +
          "\noverrideCount::" +
          overrideCount
      );
  
      const profileData = {
        customerId: selectedRows[0],
        // name: "",
        // description: "",
        paymentTermsId: paymentTermSelected,
        creditCheck: creditCheck,
        creditLimit: creditAmount,
        overrideCheckCount: overrideCount,
        createdBy: 1,
      };
  
      fetch(`${iepaProd.iepaUserSrvAPI}/customer/save/profile/org/1`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profileData),
      })
        .then((response) => response.json())
        .then((data) => {
          setMessage("Profile added successfully!");
          setError(null);
        })
        .catch((error) => {
          setError("Failed to add Profile. Please try again.");
          setMessage(null);
        });
  
      handleCloseModal();
    };
  
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
      setOpenModal(false);
      // Reset form fields
      setPriceRule("default");
      // setPaymentTerm([]);
      setCreditCheck(false);
      setCreditAmount("");
      setOverride(false);
      setOverrideCount("");
    };
  
    const columns = [
      { field: "orderNo", headerName: "Order No", flex: 0.5, minWidth: 100 },
      {
        field: "deliveryOrderNo",
        headerName: "Delivery Order No",
        flex: 1,
        minWidth: 150,
      },
      { field: "itemNo", headerName: "Item No", flex: 1, minWidth: 100 },
      {
        field: "itemDesc",
        headerName: "Item Description",
        flex: 1,
        minWidth: 150,
        valueGetter: (params, row) => row.contact?.email,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        type: "number",
        flex: 1,
        minWidth: 80,
        valueGetter: (params, row) =>
          row.contact?.phone ? row.contact?.phone : row.contact?.mobile,
      },
      {
        field: "location",
        headerName: "Item Location",
        flex: 1,
        minWidth: 100,
        valueGetter: (params, row) => row.defaultLocation?.address?.address_1,
      },
      {
        field: "approvedQty",
        headerName: "Approved Quantity",
        type: "number",
        flex: 0.5,
        minWidth: 150,
        valueGetter: (params, row) => row.defaultLocation?.address?.postalCode,
      },
      { field: "route", headerName: "Route", flex: 1, minWidth: 100 },
      { field: "status", headerName: "Status", flex: 1, minWidth: 100 },
    ];

    const approvedColumns = [
        
        { field: "itemNo", headerName: "Item No", flex: 1, minWidth: 50 },
        {
          field: "itemDesc",
          headerName: "Item Description",
          flex: 1,
          minWidth: 100,
          valueGetter: (params, row) => row.contact?.email,
        },
        {
          field: "quantity",
          headerName: "Quantity",
          type: "number",
          flex: 1,
          minWidth: 80,
          valueGetter: (params, row) =>
            row.contact?.phone ? row.contact?.phone : row.contact?.mobile,
        },
        {
          field: "location",
          headerName: "Item Location",
          flex: 1,
          minWidth: 100,
          valueGetter: (params, row) => row.defaultLocation?.address?.address_1,
        },
        
        { field: "route", headerName: "Route", flex: 1, minWidth: 100 },
        { field: "status", headerName: "Status", flex: 1, minWidth: 100 },
      ];
      
    useEffect(() => {
      fetchCustomers();
      fetchPaymentTerms();
      setLoading(true);
      // setRows(customers);
      // fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/org/1`)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     console.log(data); // Log the data to check structure
      //     // setCustomers(data);
      //   })
      //   .catch((error) => setError("Error fetching data: " + error.message));
    }, []);
  
    const handleGenerateTripSheet = () => {
      setOpenSnackbar(true); // Show snackbar notification
      setAlertMessage("Order created"); // Set the alert message
      // handleGenerateOrder();
      navigate("../TripSheet", {
        state: {
          selectedCustomer: customers,
          // selectedProducts: selectedProducts,
        },
      }); // Redirect to Invoice page
    };
  
    return (
      <Box m="20px">
        <Header title="Load List" subtitle="List of Loaded Orders" />
        <Box display="flex" justifyContent="flex" mb="20px">
          
  
          {/* <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("../CreateCustomer")}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[400],
              fontSize: "14px",
              fontWeight: "bold",
              margin: "10px",
            }}
          >
            + Add Customer
          </Button> */}
          {/* Button enabled when at least one checkbox is selected */}
          {/* <Button
            color="secondary"
            variant="contained"
            disabled={selectedRows.length === 0} // Enabled only if at least one row is selected
            sx={{
              backgroundColor:
                selectedRows.length > 0
                  ? colors.blueAccent[700]
                  : colors.grey[400],
              color: colors.primary[400],
              fontSize: "14px",
              fontWeight: "bold",
              margin: "10px",
            }}
          >
            Disable Customer
          </Button> */}
  
          {/* Button enabled when exactly one checkbox is selected */}
          {/* <Button
            color="secondary"
            variant="contained"
            disabled={selectedRows.length !== 1} // Enabled only if exactly one row is selected
            onClick={handleOpenModal}
            sx={{
              backgroundColor:
                selectedRows.length === 1
                  ? colors.blueAccent[700]
                  : colors.grey[400],
              color: colors.primary[400],
              fontSize: "14px",
              fontWeight: "bold",
              margin: "10px",
            }}
          >
            Manage Profile
          </Button> */}
          {/* TextField for filtering */}
          {/* <TextField
            label="Search"
            variant="outlined"
            value={filterText}
            onChange={(e) => {
              console.log("Event Id selected::" + e.target.value);
              setFilterText(e.target.value);
            }}
            sx={{ width: "30%", margin: "10px" }}
          /> */}
        </Box>
        <Box
          m="-25px 0 0 0"
          height="50vh"
          // checkboxSelection
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "1px solid grey",
              whiteSpace: "normal", // Allows text wrapping
              wordWrap: "break-word", // Ensures text breaks properly
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontSize: "14px",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[900],
              height: "5vh",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={filteredRows} // Ensure this contains valid data with unique IDs
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(idSelection) => {
              console.log("IdSelected::" + idSelection);
              setSelectedRows(idSelection);
            }}
            // onSelectionModelChange={(newSelection) => {
            //   console.log("Checked!");
            //   setSelectedRows(newSelection);
            // }}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>


        <Box display="flex" justifyContent="center" mb="20px">
       
    <Button
          color="primary"
          variant="contained"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
         Approve
        </Button>

        <Button
          color="primary"
          variant="contained"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
         Add
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
         Remove
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
         Update
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenerateTripSheet} // Handle trip generation
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
         Generate TripSheet
        </Button>
        </Box>

        <Box
          m="25px 0 0 0"
          height="50vh"
          // checkboxSelection
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "1px solid grey",
              whiteSpace: "normal", // Allows text wrapping
              wordWrap: "break-word", // Ensures text breaks properly
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontSize: "14px",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[900],
              height: "5vh",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
             <Header title="Approved List" subtitle="List of Approved Orders" />
          <DataGrid
            rows={filteredRows} // Ensure this contains valid data with unique IDs
            columns={approvedColumns}
            checkboxSelection
            onRowSelectionModelChange={(idSelection) => {
              console.log("IdSelected::" + idSelection);
              setSelectedRows(idSelection);
            }}
            // onSelectionModelChange={(newSelection) => {
            //   console.log("Checked!");
            //   setSelectedRows(newSelection);
            // }}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <h2>Manage Profile</h2>
            <div>
              <label>Price Rules:</label>
              <select
                value={priceRule}
                onChange={(e) => setPriceRule(e.target.value)}
              >
                <option value="default">Default</option>
                <option value="rule 1">Rule 1</option>
                <option value="rule 2">Rule 2</option>
              </select>
            </div>
            <div>
              <label>Payment Terms:</label>
              <select
                // value={opt}
                onChange={(e) => setPaymentTermSelected(e.target.value)}
                // options={opt || []}
              >
                <option value="-1">-- Select the Payment Term --</option>
                {paymentTerm?.map((term) => (
                  <option value={term.id}>{term.description}</option>
                ))}
              </select>
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={creditCheck}
                  onChange={() => setCreditCheck(!creditCheck)}
                />
              }
              label="Credit Check"
            />
            {creditCheck && (
              <TextField
                label="Credit Amount"
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
                type="number"
                fullWidth
                sx={{ mb: 2 }}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={override}
                  onChange={() => setOverride(!override)}
                  label="Override"
                />
              }
              label="Override"
            />
            {override && (
              <TextField
                label="Override Count"
                value={overrideCount}
                onChange={(e) => setOverrideCount(e.target.value)}
                type="number"
                fullWidth
                sx={{ mb: 2 }}
              />
            )}
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
  
            <Box mt={2}>
              <h3>Saved Details:</h3>
              {/* {savedDetails.map((detail, index) => (
                <div key={index}>
                  <p>Price Rule: {detail.priceRule}</p>
                  <p>Payment Term: {detail.paymentTerm}</p>
                  <p>Credit Amount: {detail.creditAmount}</p>
                  <p>Override Count: {detail.overrideCount}</p>
                  <hr />
                </div>
              ))} */}
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  };
  
  export default LoadList;
  