import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  MenuItem,
  IconButton,
} from "@mui/material";

import { useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../../constants/global";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const ViewItemLocation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0); // To manage active tab
  const navigate = useNavigate(); // For page navigation

  const [loading, setLoading] = useState(false);
  // const [loadingLocations, setLoadingLocations] = useState(false);
  // const defaultCities = [{ city: "Select City", state: "Select State" }];
  // const [cities, setCities] = useState(defaultCities);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error
  // const [locationOptions, setLocationOptions] = useState(null);
  // const [routeOptions, setRouteOptions] = useState(null);

  const [selectedId, setSelectedId] = useState(null);
  // const [addressId, setAddressId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  // const { location } = useLocation();

  const { state } = useLocation();

  const { selectedRows, products } = state;

  // When selectedRows change, update the selectedProduct
  useEffect(() => {
    if (selectedRows && selectedRows.length === 1) {
      // Assuming selectedRows contains an array with one object that has an 'id' field
      setSelectedId(selectedRows[0]);
      // console.log("selectedId::" + selectedRows[0]);
      const product = products.find((p) => p.id === selectedRows[0]);
      // console.log(product);
      setSelectedProduct(product);
      handleLocationValues(product);
    }
    // } else {
    //   setSelectedProduct(null); // Reset if no product is selected
    // }
  }, []); // Re-run whenever selectedRows or products change

  const handleLocationValues = (selectedProduct) => {
    initialValues = {
      building: selectedProduct?.building,
      floor: selectedProduct?.floor,
      door: selectedProduct?.name,
    };
  };
  // Handle dynamic initial values based on selectedProduct
  let initialValues = {
    building: selectedProduct?.building,
    floor: selectedProduct?.floor,
    door: selectedProduct?.name,
  };

  const handleClose = () => {
    navigate("/ItemLocList"); // Redirect to /CustomerList
  };

  const handleFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Item Location Form");
    const data = {
      id: selectedId,
      building: values.building,
      floor: values.floor,
      name: values.door,
      orgId: "1",
      updatedBy: 1,
    };
    // console.log(data);
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/container/update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage("Address added successfully!");
        setError(null);
        handleClose();
        // console.log("Address Id:" + data.id);

        // setAddressId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabContent = [
    {
      label: "Item Location Details",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={overviewSchema}
          onSubmit={handleFormSubmit}
          enableReinitialize="true"
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="building"
                  label="Building"
                  value={values?.building || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.building && Boolean(errors.building)}
                  helperText={touched.building && errors.building}
                />
                <TextField
                  fullWidth
                  required
                  id="floor"
                  name="floor"
                  label="Floor"
                  value={values?.floor || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.floor && Boolean(errors.floor)}
                  helperText={touched.floor && errors.floor}
                />
                <TextField
                  fullWidth
                  required
                  id="door"
                  name="door"
                  label="Door No."
                  value={values?.door || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.door && Boolean(errors.door)}
                  helperText={touched.door && errors.door}
                />
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>

                <Button
                  type="reset"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="View Item Location" subtitle="View/Edit Item Location" />
      <Box display="flex" justifyContent="flex" mb="20px">
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <Typography
            sx={{ fontWeight: "bold", fontSize: "16px", marginRight: "5px" }}
          >
            Close
          </Typography>{" "}
          <CloseIcon />
        </IconButton>
        {/* <Button
          color="secondary"
          variant="contained"
          onClick={() => window.history.back()} // Navigates back in history
          sx={{
            backgroundColor: "black",
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Go Back
        </Button> */}
        <Button
          color="secondary"
          variant="contained"
          // onClick={() => navigate("../ProductList")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Edit
        </Button>
      </Box>
      <Card>
        <CardContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabContent.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{
                  color: activeTab === index ? "black" : colors.blueAccent[700], // Active tab color
                  fontWeight: activeTab === index ? "bold" : "normal", // Optional: Bold for active tab
                }}
              />
            ))}
          </Tabs>

          <Box mt="20px">{tabContent[activeTab].content}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const overviewSchema = yup.object().shape({
  building: yup.string().required("Building is required"),
  floor: yup.string().required("Floor is required"),
  door: yup.string().required("Door detail is required"),
});

export default ViewItemLocation;
