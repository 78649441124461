import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Checkbox,
  TableContainer,
  InputAdornment,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { iepaLocal, iepaProd } from "../../constants/global";
import { useNavigate } from "react-router-dom";
import ApiCall from "../../components/ApiCall";

import axios from "axios";

const RouteList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [assignedLocations, setAssignedLocations] = useState([]);
  const navigate = useNavigate();
  const [routes, setRoutes] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState({});
  const [filterText, setFilterText] = useState("");

  const apicall = ApiCall();

  const loadLocations = () => {
    // const response = axios.get(
    //   `${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/destinations/org/1`
    // );
    // setFilteredLocations(response);
    // console.log("Received the output filteredLocations::" + filteredLocations);

    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/destinations/org/1`
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setFilteredLocations(data); // Set the response as the routes array
        } else {
          console.error("Expected an array of routes, but got:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    const mydata = apicall.get(
      null,
      `${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/org/1`,
      "Routes"
    );

    loadLocations();

    // setFilteredLocations(
    //   axios.get(
    //     `${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/destinations/org/1`
    //   )
    // );
    //   apicall.get(
    //     null,
    //     `${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/destinations/org/1`,
    //     "Route Destinations"
    //   )
    // );

    // setRoutes(mydata);

    // setRoutes(mydata);

    // setFilteredLocations(mydestinations);

    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/org/1`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setRoutes(data); // Set the response as the routes array
        } else {
          console.error("Expected an array of routes, but got:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Filtered rows based on search query
  const filteredRows = routes.filter((routes) => {
    const searchTerm = filterText ? filterText.toLowerCase() : "";
    return (
      //routes.id.includes(searchTerm) ||
      routes.routeCode?.toLowerCase().includes(searchTerm) ||
      routes.name?.toString().includes(searchTerm) ||
      routes.shortName?.toString().includes(searchTerm) ||
      routes.activeStartDate?.toString().includes(searchTerm) ||
      routes.activeEndDate?.toString().includes(searchTerm) ||
      routes.temporary?.toString().includes(searchTerm)
    );
  });

  const handleAssign = (route) => {
    setSelectedRoute(route);
    setOpenAssignModal(true);
  };

  const handleView = (route) => {
    setSelectedRoute(route);
    setOpenViewModal(true);
    fetchAssignedLocations(route.id);
  };

  const fetchAssignedLocations = async (routeID) => {
    try {
      const response = await fetch(
        `${iepaProd.iepaInventorySrvAPI}/inventory/routes/${routeID}/destinations/org/1`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch assigned locations");
      }
      const data = await response.json();
      console.log(data);
      setAssignedLocations(data);
    } catch (error) {
      console.error("Error fetching assigned locations:", error);
      setAssignedLocations([]);
    }
  };

  const handleSaveLocations = async () => {
    const selectedLocationIds = Object.keys(selectedLocations).filter(
      (id) => selectedLocations[id]
    );

    const updatedRoute = {
      id: selectedRoute.id,
      assignDestinations: selectedLocationIds.map((id) => parseInt(id)),
      orgId: "1",
      createdBy: "1",
    };

    try {
      const response = await fetch(
        `${iepaProd.iepaInventorySrvAPI}/inventory/routes/destination/assign/org/1`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedRoute),
        }
      );

      if (response.ok) {
        alert("Locations assigned successfully!");
      } else {
        alert("Failed to save locations. Please try again.");
      }
    } catch (error) {
      alert("A network error occurred. Please try again.");
    }

    setOpenAssignModal(false);
  };

  const columns = [
    { field: "id", headerName: "Sl.No", width: 80 },
    { field: "routeCode", headerName: "Route Code" },
    { field: "name", headerName: "Route Name", flex: 1 },
    { field: "shortName", headerName: "Short Name", flex: 1 },
    { field: "activeStartDate", headerName: "Start Date" },
    { field: "activeEndDate", headerName: "End Date" },
    { field: "temporary", headerName: "Temporary" },
  ];

  return (
    <Box m="20px">
      <Header title="Manage Routes" subtitle="List of Routes" />
      <Box display="flex" mb="20px">
        {/* <Button
          color="secondary"
          variant="contained"
          onClick={() => window.history.back()}
          sx={{
            backgroundColor: "black",
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Go Back
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../CreateRoute")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          + Add Routes
        </Button>
        <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1}
          sx={{
            backgroundColor:
              selectedRows.length === 1
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => handleAssign(selectedRows[0])}
        >
          Assign Locations
        </Button>
        <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1}
          sx={{
            backgroundColor:
              selectedRows.length === 1
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => handleView(selectedRows[0])}
        >
          View Locations
        </Button>
        <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length !== 1}
          sx={{
            backgroundColor:
              selectedRows.length === 1
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => {
            navigate("/ViewRoutes", {
              state: { selectedRows: selectedRows[0], routes }, // Passing selectedRows state to ViewItem component
            });
          }}
        >
          View Routes
        </Button>
        {/* TextField for filtering */}
        <TextField
          label="Search"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{ width: "30%", margin: "10px" }}
        />
      </Box>
      <Box
        // m="-25px 0 0 0"
        height="78vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
            whiteSpace: "normal", // Allows text wrapping
            wordWrap: "break-word", // Ensures text breaks properly
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) => {
            const selectedRowData = filteredRows.filter(
              (row) => row.id.toString() == newSelection
            );
            setSelectedRows(selectedRowData);
          }}
          // onSelectionModelChange={(newSelection) =>
          //   setSelectedRows(newSelection)
          // }
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Modal open={openAssignModal} onClose={() => setOpenAssignModal(false)}>
        <Box
          sx={{
            width: {
              xs: "90%", // 90% width on small screens
              sm: "600px", // 600px width on small screens and up
            },
            height: {
              xs: "70vh", // 70% height of the viewport on small screens
              sm: "50vh", // 50% height on larger screens
              md: "70vh", // 40% height on medium screens and up
            },
            margin: "100px auto", // Centers the modal horizontally
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: 24, // Adds shadow for better appearance
            overflowY: "auto", // To enable scrolling if content overflows
          }}
        >
          <h3>Assign Locations</h3>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">🔍</InputAdornment>
              ),
            }}
          />
          <TableContainer sx={{ maxHeight: "300px", overflow: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLocations?.map((location) => (
                  <TableRow key={location?.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedLocations[location?.id] || false}
                        onChange={() => {
                          setSelectedLocations((prev) => ({
                            ...prev,
                            [location?.id]: !prev[location?.id],
                          }));
                        }}
                      />
                    </TableCell>
                    <TableCell>{location?.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={handleSaveLocations}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Save
          </Button>
          <Button
            onClick={() => setOpenAssignModal(false)}
            variant="outlined"
            sx={{ mt: 2, ml: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      <Modal open={openViewModal} onClose={() => setOpenViewModal(false)}>
        <Box
          sx={{
            width: "600px",
            margin: "100px auto",
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <h3>View Locations</h3>
          <p>
            <strong>Route Name:</strong> {selectedRoute?.name || "N/A"}
          </p>
          <p>
            <strong>Assigned Locations:</strong>{" "}
            {/* {assignedLocations.join(assignedLocations.name)} */}
            {assignedLocations?.map((location) => location.name).join(", ")}
          </p>
          <Button onClick={() => setOpenViewModal(false)} variant="contained">
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default RouteList;
