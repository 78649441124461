import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputAdornment,
  Grid,
} from "@mui/material";

import { useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../../constants/global";
import { useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const Delivery = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0); // To manage active tab
  const navigate = useNavigate(); // For page navigation

  const [loading, setLoading] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const defaultCities = [{ city: "Select City", state: "Select State" }];
  const [cities, setCities] = useState(defaultCities);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error
  const [locationOptions, setLocationOptions] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [routeOptions, setRouteOptions] = useState(null);

  const [contactId, setContactId] = useState(null);
  const [addressId, setAddressId] = useState(null);

  const [selectedLocationOption, setSelectedLocationOption] = useState("");
  const [selectedCustomerOption, setSelectedCustomerOption] = useState("");
  const [selectedOrderOption, setSelectedOrderOption] = useState("");
  const [deliveryLines, setDeliveryLines] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterText, setFilterText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Filtered rows based on search query
  const filteredRows = deliveryLines?.filter((deliveryLine) => {
    const searchTerm = filterText.toLowerCase();
    return (
      //   deliveryLine?.id?.toString().includes(searchTerm) ||
      //   deliveryLine?.toLowerCase().includes(searchTerm) ||
      deliveryLine?.van?.vanNumber.toString().includes(searchTerm) ||
      deliveryLine?.quantity?.toString().includes(searchTerm)
      //   deliveryLine?.item?.toString().includes(searchTerm) ||
      //   deliveryLine?.address_1?.toString().includes(searchTerm) ||
      //   deliveryLine?.route?.toString().includes(searchTerm)
    );
  });

  const fetchCustomers = (routeDestinationId) => {
    fetch(
      `${iepaProd.iepaUserSrvAPI}/customer/names/destination/${routeDestinationId}/org/1`
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setCustomerOptions(data);
          console.log(data);
        } else {
          console.error("Invalid Customer Names data:", data);
          setError("Error fetching Customer Names data");
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Names data:", error);
        setError("Error fetching Customer Names data");
      });
  };

  const handleChangeLocation = (event) => {
    if (event.target.value) {
      //   console.log(event.target.value);
      fetchCustomers(event.target.value);
      setSelectedLocationOption(event.target.value);
    }
  };

  const fetchOrders = (customerId) => {
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/loadingsheet/orders/customer/${customerId}/org/1`
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setOrderOptions(data);
        } else {
          console.error("Invalid Order Numbers data:", data);
          setError("Error fetching Order Numbers data");
        }
      })
      .catch((error) => {
        console.error("Error fetching Order Numbers data:", error);
        setError("Error fetching Order Numbers data");
      });
  };

  const handleChangeCustomer = (event) => {
    if (event.target.value) {
      //   console.log(event.target);
      setSelectedCustomerOption(event.target.value);
      setSelectedCustomer(
        customerOptions.find((customer) => customer.id == event.target.value)
      );

      fetchOrders(event.target.value);
    }
  };

  const handleChangeOrder = (event) => {
    if (event.target.value) {
      setSelectedOrderOption(event.target.value);
    }
  };

  const fetchDeliveryLines = () => {
    fetch(
      `${
        iepaProd.iepaInventorySrvAPI
      }/inventory/warehouse/loadingsheet/lines/location/${
        selectedLocationOption ? selectedLocationOption : 0
      }/customer/${selectedCustomerOption ? selectedCustomerOption : 0}/order/${
        selectedOrderOption ? selectedOrderOption : 0
      }/org/1`
    )
      .then((response) => response.json())
      .then((data) => {
        // if (Array.isArray(data)) {
        setDeliveryLines(data);
        console.log(data);
        // } else {
        //   console.error("Invalid Delivery lines data:", data);
        //   setError("Error fetching Delivery lines data");
        // }
      })
      .catch((error) => {
        console.error("Error fetching Delivery lines data:", error);
        setError("Error fetching Delivery lines data");
      });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    fetchDeliveryLines();
  };

  const fetchLocations = () => {
    setLoadingLocations(true);
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/destinations/org/1`
    )
      .then((response) => response.json())

      .then((data) => {
        console.log(data);
        if (data) {
          setLocationOptions(
            data.map((location) => ({
              value: location.id,
              label: location.name,
              route: location.route.id,
              routeName: location.route.name,
            }))
          );
          setRouteOptions(
            data.map((location) => ({
              value: location.route.id,
              label: location.route.name,
            }))
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching locations:", error);
        setLocationOptions([]);
      })
      .finally(() => setLoadingLocations(false));
  };

  const handleAddressFormSubmit = (values, { resetForm }) => {
    const data = {
      address_1: values.address,
      city: values.city,
      state: values.state,
      country: values.type,
      postalCode: values.pincode,
      orgId: "1",
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaUserSrvAPI}/customer/save/address`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Address added successfully!");
        setError(null);
        resetForm();
        console.log("Address Id:" + data.id);

        setAddressId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  const handleContactFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Address Form");
    const data = {
      email: values.email,
      phone: values.phone,
      orgId: "1",
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaUserSrvAPI}/customer/save/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Contact added successfully!");
        setError(null);
        resetForm();
        console.log("Contact Id:" + data.id);

        setContactId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCustomerFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Customer Form");
    const data = {
      customerNumber: values.customerNo,
      name: values.companyName,
      ownerName: values.ownerName,
      defaultLocation: {
        shipToFlag: 1,
        soldToFlag: 1,
        billToFlag: 1,
        address: { id: addressId },
      },
      contact: { id: contactId },
      orgId: "1",
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaUserSrvAPI}/customer/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Address added successfully!");
        setError(null);
        resetForm();
        console.log("Address Id:" + data.id);

        setAddressId(data.id);
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  const handleMarkDelivery = () => {
    console.log(selectedRows);

    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/loadingsheet/lines/delivered/org/1`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedRows),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage("Order Delivery status was updated successfully!");
        setError(null);
        fetchDeliveryLines();
      })
      .catch((error) => {
        setError("Failed to update Order Delivery status. Please try again.");
        setMessage(null);
      });
  };

  const onPinChange = (e) => {
    if (e.target.value && e.target.value.length === 6) {
      //   fetchCityState(e.target.value);
    }
  };
  useEffect(() => {
    // fetchCityState();
    fetchLocations();
    setLoading(true);
  }, []);

  const tabContent = [
    {
      label: "Overview",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={overviewSchema}
          onSubmit={handleCustomerFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="customerNo"
                  label="Customer No"
                  value={values.customerNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.customerNo && Boolean(errors.customerNo)}
                  helperText={touched.customerNo && errors.customerNo}
                />
                <TextField
                  fullWidth
                  required
                  id="companyName"
                  label="Company Name"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />
                <TextField
                  fullWidth
                  required
                  id="ownerName"
                  label="Owner Name"
                  value={values.ownerName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ownerName && Boolean(errors.ownerName)}
                  helperText={touched.ownerName && errors.ownerName}
                />

                <Box display="flex" gap={2}>
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.primary[400],
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                    variant="contained"
                  >
                    Save
                  </Button>

                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.primary[400],
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                    variant="contained"
                    onClick={() => {
                      resetForm(); // Reset form when clicked
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
    {
      label: "Contact",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={contactSchema}
          onSubmit={handleContactFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  required
                  id="phoneNo"
                  label="Phone No"
                  value={values.phoneNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phoneNo && Boolean(errors.phoneNo)}
                  helperText={touched.phoneNo && errors.phoneNo}
                />
              </Box>
              <Box display="flex" gap={2}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
    {
      label: "Address",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={addressSchema}
          onSubmit={handleAddressFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="address"
                  label="Address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                />
                <TextField
                  fullWidth
                  required
                  id="pincode"
                  label="Pincode"
                  value={values.pincode}
                  // onChange={handleChange}
                  onChange={(e) => {
                    handleChange(e);
                    onPinChange(e);
                  }}
                  onBlur={handleBlur}
                  error={touched.pincode && Boolean(errors.pincode)}
                  helperText={touched.pincode && errors.pincode}
                />
                <TextField
                  fullWidth
                  required
                  select
                  id="city"
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.city}>
                      {city.city}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  required
                  select
                  id="state"
                  name="state"
                  label="State"
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.state}>
                      {city.state}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value="0">Select State</MenuItem> */}
                </TextField>

                <TextField
                  fullWidth
                  required
                  id="route"
                  label="Route"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Box>
              <Box display="flex" gap={2}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
    {
      label: "Assignment",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={addressSchema}
          onSubmit={handleAddressFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  //  required
                  select
                  id="location"
                  name="location"
                  label="Location"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.city}>
                      {city.city}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  //  required
                  select
                  id="profile"
                  name="profile"
                  label="Profile"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.city}>
                      {city.city}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  //  required
                  select
                  id="taxCategory"
                  name="taxCategory"
                  label="Tax Category"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                >
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.city}>
                      {city.city}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                >
                  Save
                </Button>

                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
  ];

  const columns = [
    { field: "update", headerName: "Update", width: 80 }, // Fixed small width
    {
      field: "id",
      headerName: "#",
      flex: 1, // More space for longer names
      cellClassName: "name-column--cell",
    },
    {
      field: "vanNumber",
      headerName: "Vehicle No",
      flex: 1,
      valueGetter: (params, row) => (row.van ? row.van?.vanNumber : ""),
    },

    {
      field: "particulars",
      headerName: "Particulars",
      flex: 1,
      valueGetter: (params, row) => (row.item ? row.item?.description : ""),
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      flex: 1,
      valueGetter: (params, row) => (row.van ? row.van?.type : ""),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      valueGetter: (params, row) => (row.item ? row.item?.standardMrp : ""),
    },
    {
      field: "deb",
      headerName: "Deb",
      flex: 1,
    },
  ];
  return (
    <Box m="20px">
      <Header title="Delivery Details" subtitle="Delievry details page" />
      <Box display="flex" justifyContent="flex" mb="20px">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
            maxWidth: "600px",
            margin: "auto",
            padding: "20px",
          }}
        >
          {/* Dropdown 1 */}
          <Select
            value={selectedLocationOption}
            onChange={handleChangeLocation}
            displayEmpty
            fullWidth
            sx={{ padding: "10px", backgroundColor: "#f0f0f0" }}
            size="small"
          >
            <MenuItem value="" disabled>
              ----- Select Location -----
            </MenuItem>
            {locationOptions?.map((destination, index) => (
              <MenuItem key={destination.value} value={destination.value}>
                {destination.label}
              </MenuItem>
            ))}

            {/* <MenuItem value="" disabled>
              Select Location
            </MenuItem>
            <MenuItem value="option1">Location 1</MenuItem>
            <MenuItem value="option2">Location 2</MenuItem>
            <MenuItem value="option3">Location 3</MenuItem> */}
          </Select>

          {/* Dropdown 2 */}
          <Select
            value={selectedCustomerOption}
            onChange={handleChangeCustomer}
            displayEmpty
            fullWidth
            sx={{ padding: "10px", backgroundColor: "#f0f0f0" }}
            size="small"
          >
            <MenuItem value="" disabled>
              ----- Select Customer -----
            </MenuItem>
            {customerOptions?.map((customer, index) => (
              <MenuItem key={customer.id} value={customer.id}>
                {customer.name}
              </MenuItem>
            ))}
            {/* <MenuItem value="option1">Customer A</MenuItem>
            <MenuItem value="option2">Customer B</MenuItem>
            <MenuItem value="option3">Customer C</MenuItem> */}
          </Select>

          {/* Dropdown 3 */}
          <Select
            value={selectedOrderOption}
            onChange={handleChangeOrder}
            displayEmpty
            fullWidth
            sx={{ padding: "10px", backgroundColor: "#f0f0f0" }}
            size="small"
          >
            <MenuItem value="" disabled>
              ----- Select Order -----
            </MenuItem>
            {orderOptions?.map((order, index) => (
              <MenuItem key={order.id} value={order.orderNumber}>
                {order.orderNumber}
              </MenuItem>
            ))}
            {/* <MenuItem value="option2">Undelivered</MenuItem> */}
          </Select>

          {/* Optional button for action */}
          <Button
            variant="contained"
            // type="submit"
            sx={{
              backgroundColor: "#0077b6",
              color: "white",
              fontWeight: "bold",
              marginTop: "16px",
            }}
            onClick={handleSearchChange}
          >
            Search
          </Button>

          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">&nbsp;</Typography>
            <Grid container spacing={2}>
              {/* Check if selectedCustomer exists */}
              {/* {selectedCustomer ? ( */}
              <>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Customer No: </strong>
                    {selectedCustomer?.can}
                  </Typography>
                  <Typography>
                    <strong>Company Name: </strong>
                    {selectedCustomer?.name}
                  </Typography>
                  <Typography>
                    <strong>Owner Name: </strong>
                    {selectedCustomer?.ownerName}
                  </Typography>
                  <Typography>
                    <strong>Email: </strong>
                    {selectedCustomer?.contact?.email}
                  </Typography>
                  <Typography>
                    <strong>Phone: </strong>
                    {selectedCustomer?.contact?.phone}
                  </Typography>
                  <Typography>
                    <strong>Location: </strong>
                    {selectedCustomer?.defaultLocation?.routeDestinationName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Address: </strong>
                    {selectedCustomer?.defaultLocation?.address?.address_1}
                  </Typography>
                  <Typography>
                    <strong>Pincode: </strong>
                    {selectedCustomer?.defaultLocation?.address?.postalCode}
                  </Typography>
                  <Typography>
                    <strong>City: </strong>
                    {selectedCustomer?.defaultLocation?.address?.city}
                  </Typography>
                  <Typography>
                    <strong>State: </strong>
                    {selectedCustomer?.defaultLocation?.address?.state}
                  </Typography>
                  <Typography>
                    <strong>Profile: </strong>
                    {selectedCustomer?.defaultLocation?.address?.postalCode}
                  </Typography>
                  <Typography>
                    <strong>Tax Category: </strong>
                    {selectedCustomer?.taxCode}
                  </Typography>
                </Grid>
              </>
              {/* ) : (
                                <Grid item xs={12}>
                                    <Typography sx={{ margin: "10px" }}>
                                        <strong>No customer selected.</strong>
                                    </Typography>
                                </Grid>
                            )} */}
            </Grid>
          </Box>

          {/* <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h1>Collection Details</h1>
                        <TextField
                            label="Search here..."
                            variant="outlined"
                            sx={{ width: "100%", marginTop: "20px" }}  // Set width to 100% to match other form fields
                        />
                    </Box> */}
        </Box>
      </Box>

      <Box
        m="-25px 0 0 0"
        height="78vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
            whiteSpace: "normal", // Allows text wrapping
            wordWrap: "break-word", // Ensures text breaks properly
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) =>
            setSelectedRows(newSelection)
          }
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Button
          color="primary"
          variant="contained"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={handleMarkDelivery}
        >
          Delivery
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => navigate("../ReturnOrder")}
        >
          Return
        </Button>
      </Box>
    </Box>
  );
};

const overviewSchema = yup.object().shape({
  customerNo: yup.string().required("Customer No is required"),
  companyName: yup.string().required("Company Name is required"),
  ownerName: yup.string().required("Owner Name is required"),
});

const contactSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNo: yup
    .string()
    .matches(/^\d+$/, "Phone number is not valid")
    .required("Phone No is required"),
});

const addressSchema = yup.object().shape({
  address: yup.string().required("Address is required"),
  pincode: yup
    .string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits")
    .required("Pincode is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
});

const initialValues = {
  customerNo: "",
  companyName: "",
  ownerName: "",
  email: "",
  phoneNo: "",
  address: "",
  pincode: "",
  city: "",
  state: "",
};

export default Delivery;
