import React, { useState, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { iepaProd } from "../../constants/global";
import { useNavigate } from "react-router-dom";

const Locations = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  const [products, setProducts] = useState([]);
  const navigate = useNavigate(); // For page navigation
  const [locations, setLocations] = useState([]);
  const [filterText, setFilterText] = useState("");

  // Fetch initial data from the API
  useEffect(() => {
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/routes/destinations/all/org/1`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch locations.");
        }
        return response.json();
      })
      .then((data) => {
        setLocations(data);
      })
      .catch((error) => console.error(error));
  }, []);

  // Filtered rows based on search query
  const filteredRows = locations.filter((location) => {
    const searchTerm = filterText.toLowerCase();
    return (
      location.name?.toLowerCase().includes(searchTerm) ||
      location.code?.toLowerCase().includes(searchTerm) ||
      location.zipCode?.toString().includes(searchTerm)
    );
  });

  const columns = [
    { field: "id", headerName: "Sl.No", width: 80 }, // Fixed small width
    {
      field: "code",
      headerName: "Location Code",
      flex: 1, // More space for longer names
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Location Name",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Header title="Manage Locations" subtitle="View Locations" />
      <Box display="flex" justifyContent="flex" mb="20px">
        {/* <Button
          color="secondary"
          variant="contained"
          onClick={() => window.history.back()} // Navigates back in history
          sx={{
            backgroundColor: "black",
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Go Back
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../CreateLocation")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          + Add Locations
        </Button>
        {/* Button enabled when at least one checkbox is selected */}
        <Button
          color="secondary"
          variant="contained"
          disabled={selectedRows.length === 0} // Enabled only if at least one row is selected
          sx={{
            backgroundColor:
              selectedRows.length > 0
                ? colors.blueAccent[700]
                : colors.grey[400],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
          onClick={() => {
            navigate("/ViewLocations", {
              state: { selectedRows: selectedRows, locations } // Passing selectedRows state to ViewItem component
            });
          }}
        >
          View Locations
        </Button>
        {/* TextField for filtering */}
        <TextField
          label="Search"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{ width: "30%", margin: "10px" }}
        />
      </Box>
      <Box
        // m="-25px 0 0 0"
        height="78vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
            whiteSpace: "normal", // Allows text wrapping
            wordWrap: "break-word", // Ensures text breaks properly
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) =>
            setSelectedRows(newSelection)
          }
          // onSelectionModelChange={(newSelection) =>
          //   setSelectedRows(newSelection)
          // }
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Locations;
