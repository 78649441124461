import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme"; // Import tokens for colors
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom"; // Link for navigating to the invoice
import { useEffect } from "react";
import { Refresh } from "@mui/icons-material";
import { getValueFormatter } from "@nivo/core";
import { Typography } from "@mui/material";
import { iepaProd } from "../../constants/global";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";

const OrderList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); // Use tokens for color theme

  const [selectedRows, setSelectedRows] = useState([]);
  const [products, setProducts] = useState([]); // Start with an empty products array

  const [error, setError] = useState(null); //Error
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [orders, setOrders] = useState([]);
  const [rows, setRows] = useState(orders);

  const navigate = useNavigate(); // For page navigation

  const fetchOrders = async () => {
    setRows(null);

    fetch(`${iepaProd.iepaOrderMgmtAPI}/orm/order/entered/org/1`)
      .then((response) => response.json())
      .then((data) => {
        setOrders(data);
        setRows(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError("Error fetching Order data: " + error.message);
        }
      });
  };

  const formatDate = (date = new Date()) => {
    return date.split("T")[0];
  };

  const columns = [
    { field: "orderNumber", headerName: "Order ID", flex: 1 },
    {
      field: "requestDate",
      headerName: "Date",
      flex: 1,
      valueFormatter: (value) => formatDate(value),
    },
    { field: "customerName", headerName: "Customer Name", flex: 1 },
    { field: "totalAmount", headerName: "Estimated Value", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 }, // Added Status column
    {
      field: "viewEstimate",
      headerName: "View Estimate",
      flex: 1,
      renderCell: (params) => (
        <Link
          to={`/invoice`}
          state={{
            selectedCustomer: null,
            selectedProducts: null,
            orderNumber: params.id,
          }}
        >
          <button
            style={{
              padding: "5px 10px",
              backgroundColor: colors.blueAccent[700], // Using color token
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            View
          </button>
        </Link>
      ),
    },
  ];

  const handleSelectionChange = (newSelection) => {
    // const selectedRowsData = newSelection.map((id) =>
    //   rows.find((row) => row.id === id)
    // );
    //console.log("newSelection:: " + newSelection);

    setSelectedRows(newSelection);
  };

  const handleApproveOrders = () => {
    // const updatedRows = rows.map((row, index) => {
    //   console.log(
    //     "Row Selected " +
    //       row.id +
    //       " Index::" +
    //       index +
    //       " SelectedRows: " +
    //       selectedRows
    //   );

    //   if (selectedRows.includes(row.id)) {
    //     return { ...row, status: "Approved" }; // Update status to Approved
    //   }

    //   return row;
    // });

    // selectedRows.forEach((item) => {
    //   console.log("Need to Approve Order# " + item); // Update status to Approved
    // });

    // setRows(updatedRows); // Set updated rows with new statuses

    const orderData = {
      updateOrderList: selectedRows,
      createdBy: 1,
    };

    approveOrders(orderData);
  };

  const approveOrders = (orderData) => {
    fetch(`${iepaProd.iepaOrderMgmtAPI}/orm/order/approve/org/1`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Orders were Approved successfully!");
        setError(null);
        fetchOrders();
        //resetForm();
      })
      .catch((error) => {
        setError("Failed to approve Orders. Please try again.");
        setMessage(null);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchOrders();
    setRows(orders);
    // this.setState({ rows: orders });
  }, []);

  return (
    <Box m="20px">
      <Header title="Manage orders" subtitle="View Manage orders" />
      <Box display="flex" mb="20px">
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../TakeOrder")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          + Add New Order
        </Button>
        <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleApproveOrders}
          style={{
            backgroundColor: colors.greenAccent[500],
            color: "white",
            padding: "10px 20px",
          }}
        >
          Approve Order
        </Button>
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleApproveOrders}
          style={{
            backgroundColor: colors.greenAccent[500],
            color: "white",
            padding: "10px 20px",
          }}
        >
          Modify Order
        </Button>
      </Box>

        <TextField
          label="Search"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{ width: "30%", margin: "10px" }}
        />
      </Box>

      <Box
        // m="-25px 0 0 0"
        height="70vh" // Adjusted height for the table
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[900], // Header color
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          getRowId={(rows) => rows.orderNumber}
          rows={rows} // Use state rows
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection // Enables checkbox selection
          onRowSelectionModelChange={(ids) => {
            handleSelectionChange(ids);
            // const selectedIDs = new Set(ids);
            // const selectedRowData = rows.filter((row) =>
            //   selectedIDs.has(row.id.toString())
            // );
            //console.log(ids);
          }} // Track selected rows
        />
      </Box>
      {message && <Typography color="success.main">{message}</Typography>}
      {error && <Typography color="error.main">{error}</Typography>}
      {/* <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleApproveOrders}
          style={{
            backgroundColor: colors.greenAccent[500],
            color: "white",
            padding: "10px 20px",
          }}
        >
          Approve Order
        </Button>
      </Box> */}
    </Box>
  );
};

export default OrderList;
