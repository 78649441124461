import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme"; // Assuming you use the same tokens for colors
import Header from "../../components/Header";
import "./Report.css";

const Report = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 2 },
    { field: "value", headerName: "Value", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },
  ];

  const sampleData = [
    { id: 1, name: "Sample Data 1", value: 100, date: "2024-01-01" },
    { id: 2, name: "Sample Data 2", value: 200, date: "2024-01-02" },
    { id: 3, name: "Sample Data 3", value: 300, date: "2024-01-03" },
  ];

  const getReportData = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return sampleData; // Replace with real data for Report 1
      case 1:
        return sampleData.map((item) => ({ ...item, value: item.value * 2 })); // Modify for Report 2
      case 2:
        return sampleData.map((item) => ({ ...item, name: item.name.toUpperCase() })); // Modify for Report 3
      case 3:
        return sampleData.map((item) => ({ ...item, date: "2024-12-31" })); // Modify for Report 4
      default:
        return [];
    }
  };

  const handlePrint = () => {
    // Extract table content dynamically
    const rows = getReportData(selectedTab);
    const printableContent = `
      <table style="width: 100%; border-collapse: collapse; font-family: Arial, sans-serif;">
        <thead>
          <tr>
            ${columns
              .map(
                (col) =>
                  `<th style="border: 1px solid #ddd; padding: 8px; background-color: #f4f4f4;">${col.headerName}</th>`
              )
              .join("")}
          </tr>
        </thead>
        <tbody>
          ${rows
            .map(
              (row) =>
                `<tr>${columns
                  .map(
                    (col) =>
                      `<td style="border: 1px solid #ddd; padding: 8px;">${row[col.field] || ""}</td>`
                  )
                  .join("")}</tr>`
            )
            .join("")}
        </tbody>
      </table>
    `;

    // Open a new window for printing
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f4f4f4;
            }
          </style>
        </head>
        <body>
          <h3>${["Van Stock", "Loading Sheet", "Delivery Order", "All Orders"][selectedTab]}</h3>
          ${printableContent}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <Box m="20px" className="report-container">
      <Header title="Add User" subtitle="Enter User Details" />
      
      <Box display="flex" gap={2}>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => window.history.back()} // Navigates back in history
        sx={{
          backgroundColor: "black",
          color: colors.primary[400],
          fontSize: "14px",
          fontWeight: "bold",
          margin: "10px",
          width:"100px",
        }}
      >
        Go Back
      </Button>
                    <TextField
                      sx={{ flex: 1 }}
                      id="startDate"
                      label="Start Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                    />
                    <TextField
                      sx={{ flex: 1 }}
                      id="endDate"
                      label="End Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                    />
                    </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Report Tabs">
          <Tab label="Van Stock" />
          <Tab label="Loading Sheet" />
          <Tab label="Delivery Order" />
          <Tab label="All Orders" />
        </Tabs>
      </Box>
      <Box className="report-table-container">
        <DataGrid
          rows={getReportData(selectedTab)}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
        />
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handlePrint}
          style={{
            backgroundColor: colors.greenAccent[500],
            color: "white",
            padding: "10px 20px",
          }}
        >
          Print Report
        </Button>
      </Box>
    </Box>
  );
};

export default Report;
