import {
  Box,
  Button,
  TextField,
  useTheme,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Paper,
  InputAdornment,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { tokens } from "../../theme";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { React, useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Switch } from "@mui/material"; // Import the Switch component
import Header from "../../components/Header";
import { Description } from "@mui/icons-material";
import { iepaProd } from "../../constants/global";
import { useNavigate } from "react-router-dom";

const CreateItemLoc = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [routes, setRoutes] = useState(initialRoutes);

  const [warehouse, setWarehouse] = useState([]);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // For page navigation

  const fetchWarehouse = () => {
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/status/active/org/1`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to check structure
        setWarehouse(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError("Error fetching Warehouse data: " + error.message);
        }
      });
  };

  useEffect(() => {
    fetchWarehouse();
  }, []);

  const handleFormSubmit = (values, { resetForm }) => {
    const data = {
      code: values.LocCode,
      name: values.LocName,
      description: values.LocDescription,
      type: values.LocType,
      warehouse: {
        id: values.WarehouseId,
      },
      orgId: "1",
      createdBy: 1,
    };

    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/warehouse/container/save`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMessage("Item Location added successfully!");
        setError(null);
        resetForm();
      })
      .catch((error) => {
        setError("Failed to add Item Location. Please try again.");
        setMessage(null);
      });
  };

  useEffect(() => {}, []);

  return (
    <Box m="20px">
      <Header
        title="Create Item Location (Warehouse)"
        subtitle="Enter the New warehouse Details"
      />
      <Button
        color="secondary"
        variant="contained"
        onClick={() => window.history.back()} // Navigates back in history
        sx={{
          backgroundColor: "black",
          color: colors.primary[400],
          fontSize: "14px",
          fontWeight: "bold",
          margin: "10px",
        }}
      >
        Go Back
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => navigate("../ItemLocList ")}
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.primary[400],
          fontSize: "14px",
          fontWeight: "bold",
          margin: "10px",
        }}
      >
        Manage Item Location
      </Button>
      <Card>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={routeSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box mt="16px" display="flex" flexDirection="column">
                  <Box display="flex" gap={1}>
                    <TextField
                      sx={{ flex: 1 }}
                      id="WarehouseId"
                      name="WarehouseId"
                      label="Warehouse"
                      select
                      value={values.WarehouseId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.WarehouseId && Boolean(errors.WarehouseId)}
                      helperText={touched.WarehouseId && errors.WarehouseId}
                      margin="normal"
                    >
                      {warehouse.map((warehouse, index) => (
                        <MenuItem
                          key={index}
                          value={warehouse.id}
                          selected={index === "0"}
                          // selected={index == 0 ? "true" : ""}
                        >
                          {warehouse.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <Box display="flex" gap={2}>
                    <TextField
                      sx={{ flex: 1 }}
                      id="LocCode"
                      label="Location Code"
                      value={values.LocCode}
                      onChange={handleChange}
                      onSelect={handleChange}
                      onBlur={handleBlur}
                      error={touched.LocCode && Boolean(errors.LocCode)}
                      helperText={touched.LocCode && errors.LocCode}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      sx={{ flex: 1 }}
                      id="LocName"
                      label="Location Name"
                      value={values.LocName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.LocName && Boolean(errors.LocName)}
                      helperText={touched.LocName && errors.LocName}
                      margin="normal"
                      fullWidth
                    />
                  </Box>
                  <Box display="flex" gap={2}>
                    <TextField
                      sx={{ flex: 1 }}
                      id="LocDescription"
                      label="Description"
                      value={values.LocDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.LocDescription && Boolean(errors.LocDescription)
                      }
                      helperText={
                        touched.LocDescription && errors.LocDescription
                      }
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      fullWidth
                      sx={{ flex: 1 }}
                      id="LocType"
                      name="LocType"
                      label="Type"
                      select
                      value={values.LocType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.LocType && Boolean(errors.LocType)}
                      helperText={touched.LocType && errors.LocType}
                      margin="normal"
                    >
                      <MenuItem value="CANTILEVER">CANTILEVER</MenuItem>
                      <MenuItem value="CARTON_FLOW">CARTON_FLOW</MenuItem>
                      <MenuItem value="DOUBLE_DEEP">DOUBLE_DEEP</MenuItem>
                      <MenuItem value="DRIVE_IN">DRIVE_IN</MenuItem>
                      <MenuItem value="MEZANNINE_FLOORING">
                        MEZANNINE_FLOORING
                      </MenuItem>
                      <MenuItem value="MOBILE_SHELVING">
                        MOBILE_SHELVING
                      </MenuItem>
                      <MenuItem value="MULTI_TIER_RACKING">
                        MULTI_TIER_RACKING
                      </MenuItem>
                      <MenuItem value="SELECTIVE_SHELVING">
                        SELECTIVE_SHELVING
                      </MenuItem>
                      <MenuItem value="STATIC_SHELVING">
                        STATIC_SHELVING
                      </MenuItem>
                      <MenuItem value="PALLET_RACKING">PALLET_RACKING</MenuItem>
                      <MenuItem value="PUSH_BACK">PUSH_BACK</MenuItem>
                      <MenuItem value="WIRE_PARTITIONS">
                        WIRE_PARTITIONS
                      </MenuItem>
                      <MenuItem value="OTHERS">OTHERS</MenuItem>
                    </TextField>
                  </Box>
                  {message && (
                    <Typography color="success.main">{message}</Typography>
                  )}
                  {error && <Typography color="error.main">{error}</Typography>}
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    mt: 2,
                  }}
                >
                  Add Location
                </Button>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

// Initial values for the form
const initialValues = {
  LocCode: "",
  LocName: "",
  LocDescription: "",
  LocType: "",
  WarehouseId: "",
};

// Validation schema
const routeSchema = yup.object().shape({
  LocCode: yup.string().required("Code is required"),
  LocName: yup.string().required("Name is required"),
  LocDescription: yup.string().required("Description is required"),
  LocType: yup.string().required("Type is required"),
  WarehouseId: yup.string().required("Warehouse is required"),
  // startDate: yup.date().required("Start Date is required"),
  // endDate: yup.date().required("End Date is required"),
});

// Sample routes
const initialRoutes = [];

export default CreateItemLoc;
