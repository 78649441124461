import {
  Box,
  Button,
  TextField,
  useTheme,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Paper,
  InputAdornment,
  Typography,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { React, useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Switch } from "@mui/material"; // Import the Switch component
import Header from "../../components/Header";
import { Description } from "@mui/icons-material";
import { iepaProd } from "../../constants/global";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const CreateRoute = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [routes, setRoutes] = useState(initialRoutes);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [assignedLocations, setAssignedLocations] = useState([]);
  const navigate = useNavigate(); // For page navigation

  useEffect(() => {
    fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/routes/destinations/all/org/1`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch locations.");
        }
        return response.json();
      })
      .then((data) => {
        setLocations(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleFormSubmit = (values, { resetForm }) => {
    const data = {
      name: values.routeName,
      shortName: values.routeShortName,
      description: "route description",
      code: values.routeCode,
      type: values.temporary,
      activeStartDate: values.startDate,
      activeEndDate: values.endDate,
      orgId: "1",
      createdBy: 1,
    };

    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Route added successfully!");
        setError(null);
        resetForm();
      })
      .catch((error) => {
        setError("Failed to add Route. Please try again.");
        setMessage(null);
      });
  };

  // Separate state for toggle reporting
  const [reportingStatus, setReportingStatus] = useState({});

  const handleLocationChange = (locationId) => {
    setSelectedLocations((prev) => ({
      ...prev,
      [locationId]: !prev[locationId], // Toggle the location selection
    }));
  };

  // New handler for the switch toggle
  const handleReportingChange = (locationId) => {
    setReportingStatus((prev) => ({
      ...prev,
      [locationId]: !prev[locationId], // Toggle the reporting status
    }));
  };

  const filteredLocations = locations.filter(
    (location) =>
      location.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      location.pincode.includes(searchTerm)
  );

  useEffect(() => {
    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/org/1`)
      .then((response) => response.json())
      .then((data) => {
        // Check if data is an array before setting it
        if (Array.isArray(data)) {
          setRoutes(data);
        } else {
          console.error("Expected an array of routes, but got:", data);
          setError("Error: Invalid data format");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data: " + error.message);
      });
  }, []);

  const handleClose = () => {
    navigate("/RouteList"); // Redirect to /CustomerList
  };

  return (
    <Box m="20px">
      <Header title="Create Route" subtitle="Enter the New Route Details" />
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "16px", marginRight: "5px" }}
        >
          Close
        </Typography>
        <CloseIcon />
      </IconButton>

      <Card>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={routeSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mt="16px" display="flex" flexDirection="column">
                  <Box display="flex" gap={2}>
                    <TextField
                      sx={{ flex: 1 }}
                      id="routeCode"
                      label="Route Code"
                      value={values.routeCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.routeCode && Boolean(errors.routeCode)}
                      helperText={touched.routeCode && errors.routeCode}
                      margin="normal"
                    />
                    <TextField
                      sx={{ flex: 1 }}
                      id="routeName"
                      label="Route Name"
                      value={values.routeName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.routeName && Boolean(errors.routeName)}
                      helperText={touched.routeName && errors.routeName}
                      margin="normal"
                    />
                    <TextField
                      sx={{ flex: 1 }}
                      id="routeShortName"
                      label="Route Short Name"
                      value={values.routeShortName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.routeShortName && Boolean(errors.routeShortName)
                      }
                      helperText={
                        touched.routeShortName && errors.routeShortName
                      }
                      margin="normal"
                    />
                  </Box>
                  <Box display="flex" gap={2}>
                    <TextField
                      sx={{ flex: 1 }}
                      id="startDate"
                      label="Start Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.startDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.startDate && Boolean(errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      margin="normal"
                    />
                    <TextField
                      sx={{ flex: 1 }}
                      id="endDate"
                      label="End Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.endDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.endDate && Boolean(errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      margin="normal"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.temporary}
                          onChange={handleChange}
                          name="temporary"
                        />
                      }
                      label="Temporary (Custom Route)"
                    />
                  </Box>
                  {message && (
                    <Typography color="success.main">{message}</Typography>
                  )}
                  {error && <Typography color="error.main">{error}</Typography>}
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    mt: 2,
                  }}
                >
                  Add Route
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

// Initial values for the form
const initialValues = {
  routeCode: "",
  routeName: "",
  routeShortName: "",
  startDate: "",
  endDate: "",
  temporary: false,
};

// Validation schema
const routeSchema = yup.object().shape({
  routeCode: yup.string().required("Route Code is required"),
  routeName: yup.string().required("Route Name is required"),
  routeShortName: yup.string().required("Route Short Name is required"),
  // startDate: yup.date().required("Start Date is required"),
  // endDate: yup.date().required("End Date is required"),
});

// Sample routes
const initialRoutes = [];

// Pre-defined locations for selection
// Pre-defined locations for selection
const initialLocations = [];

export default CreateRoute;
