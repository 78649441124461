import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  TextField,
  Snackbar, // Import Snackbar for notifications
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete"; // Import Delete icon
import "./TakeOrder.css";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
// import { mockDataCustomers, mockDataProducts } from "../../data/productData"; // Import customer and product data
import { useEffect } from "react";
import CustomerData from "../../data/CustomerData";
import { iepaProd } from "../../constants/global";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../../components/Header";

const TakeOrder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [products, setProducts] = useState([]); // Start with an empty products array
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null); //Error

  // State for customer selection
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openProductDialog, setOpenProductDialog] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [quantities, setQuantities] = useState({}); // To store quantities for selected products
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
  const [alertMessage, setAlertMessage] = useState(""); // State for alert message
  const [message, setMessage] = useState(null);

  const [loading, setLoading] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState("");
  const [searchProduct, setSearchProduct] = useState("");

  const navigate = useNavigate(); // Hook to programmatically navigate

  const handleSelectCustomer = () => {
    setOpenDialog(true); // Open customer selection dialog
  };
  const handleClose = () => {
    navigate("/OrderList"); // Redirect to /CustomerList
  };

  const handleProductSelect = () => {
    setOpenProductDialog(true); // Open product selection dialog
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setOpenDialog(false); // Close dialog after selecting customer
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseProductDialog = () => {
    setOpenProductDialog(false);
  };

  const handleStarClick = (product) => {
    setFavoriteProducts((prevFavorites) =>
      prevFavorites.includes(product)
        ? prevFavorites.filter((p) => p !== product)
        : [...prevFavorites, product]
    );
  };

  const handleQuantityChange = (productId, quantity) => {
    const quantityValue = parseInt(quantity) || 0; // Default to 0 if invalid
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantityValue,
    }));

    if (quantityValue > 0) {
      // Add or update the product in the selected products list
      setSelectedProducts((prevSelected) => {
        const existingProduct = prevSelected.find((p) => p.id === productId);
        const product = products.find((p) => p.id === productId);
        const updatedProduct = {
          ...product,
          quantity: quantityValue,
        };

        if (existingProduct) {
          // Update the existing product's quantity
          return prevSelected.map((p) =>
            p.id === productId ? updatedProduct : p
          );
        }

        // Add the new product
        return [...prevSelected, updatedProduct];
      });
    } else {
      // Remove the product if quantity is 0 or less
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((p) => p.id !== productId)
      );
    }
  };

  const handleDeleteProduct = (productId) => {
    // Remove the product from the selected products list
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((p) => p.id !== productId)
    );
  };

  const handleGenerateInvoice = () => {
    setOpenSnackbar(true); // Show snackbar notification
    setAlertMessage("Order created"); // Set the alert message
    handleGenerateOrder();
    navigate("../Invoice", {
      state: {
        selectedCustomer: selectedCustomer,
        selectedProducts: selectedProducts,
      },
    }); // Redirect to Invoice page
  };

  const handleProductModalOk = () => {
    setOpenProductDialog(false); // Close the product dialog
  };

  const handleProductModalCancel = () => {
    setSelectedProducts([]); // Remove all added products
    setQuantities({}); // Clear quantities
    setOpenProductDialog(false); // Close the product dialog
  };

  const total = selectedProducts.reduce(
    (acc, product) => acc + product.quantity * product.standardMrp,
    0
  );

  const fetchCustomers = () => {
    fetch(`${iepaProd.iepaUserSrvAPI}/customer/active/all/org/1`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to check structure
        setCustomers(data);
      })
      .catch((error) =>
        setError("Error fetching Customer data: " + error.message)
      );
  };

  const fetchProducts = () => {
    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/items/active/all`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to check structure
        setProducts(data);
      })
      .catch((error) =>
        setError("Error fetching Products data: " + error.message)
      );
  };

  useEffect(() => {
    setLoading(true);

    //setCustomers(<CustomerData></CustomerData>);
    //console.log("Customer Data from JSX" + customers);
    fetchCustomers();
    fetchProducts();
  }, []);

  // Calculate total amount
  const totalAmount = selectedProducts.reduce(
    (acc, product) => acc + product.quantity * product.standardMrp,
    0
  );
  const gstAmount = totalAmount * 0.18; // Assuming 18% GST
  const finalAmount = totalAmount + gstAmount;

  const formatDate = (date = new Date()) => {
    return date.toISOString().split("T")[0];
  };

  const handleGenerateOrder = async () => {
    //Order Lines
    const orderLines = selectedProducts.map((product, index) => ({
      lineNumber: index + 1,
      itemId: product.id,
      orderedItemSku: product.sku,
      quantity: product.quantity,
      orderQuantityUom: "EA",
      pricingQuantity: product.quantity,
      sellingPrice: product.standardMrp,
      listPrice: product.standardMrp,
      taxAmount: product.standardMrp * 0.18, //GST Standard 18%
      discountAmount: 0,
      shippingAmount: 0,
      lineAmount: product.quantity * product.standardMrp,
      soldToSiteId: selectedCustomer?.defaultLocation?.id,
      shipToSiteId: selectedCustomer?.defaultLocation?.id,
      billToSiteId: selectedCustomer?.defaultLocation?.id,
      orgId: "1",
      createdBy: 1,
      salesRepId: 1,
      status: "ENTERED",
      currencyCode: "INR",
      taxCurrencyCode: "INR",
    }));

    //Order Header
    console.log("OrderLines::" + orderLines);
    const orderData = {
      customerId: selectedCustomer.id,
      type: "PURCHASE",
      status: "ENTERED",
      subTotalAmount: totalAmount,
      discount: 0,
      netAmount: totalAmount,
      taxAmount: gstAmount,
      shippingAmount: 0,
      totalAmount: finalAmount,
      requestDate: formatDate(),
      pricingDate: formatDate(),
      shippingDate: null,
      bookedDate: formatDate(),
      soldToSiteId: selectedCustomer.defaultLocation.id,
      shipToSiteId: selectedCustomer.defaultLocation.id,
      billToSiteId: selectedCustomer.defaultLocation.id,
      salesRepId: 1,
      orderLines: orderLines,
      priority: 0,
      createdBy: 1,
      orgId: "1",
    };

    // console.log(formatDate());
    //Save the Order
    saveOrder(orderData);
  };

  const saveOrder = async (orderData) => {
    // console.log("Going to Save the Order");

    fetch(`${iepaProd.iepaOrderMgmtAPI}/orm/order/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Order added successfully!");
        setError(null);
        //resetForm();
      })
      .catch((error) => {
        setError("Failed to add Order. Please try again.");
        setMessage(null);
      });
  };

  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchCustomer.toLowerCase())
  );

  const filteredProducts = products.filter((product) =>
    product.itemName.toLowerCase().includes(searchProduct.toLowerCase())
  );
  return (
    <Box m="20px">
      {/*style={{ backgroundColor: "#FFF", minHeight: "100vh", padding: "20px" }}*/}
      <Header title="Add Orders" subtitle="Create a New Order" />
            <IconButton onClick={handleClose} sx={{
	    position: 'absolute',
	    top: 10,
	    right: 10,
	    }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', marginRight: '5px' }}>
          Close
        </Typography> <CloseIcon />
      </IconButton>

      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSelectCustomer} // Open customer selection dialog
          >
            Select Customer
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleProductSelect} // Open product selection dialog
            style={{ marginLeft: "10px" }} // Add margin to separate buttons
          >
            Select Product
          </Button>
          <Typography variant="h6">&nbsp;</Typography>
          <Grid container spacing={2}>
            {selectedCustomer ? (
              <>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Company Name: </strong> {selectedCustomer?.name}
                  </Typography>
                  <Typography>
                    <strong>Owner Name: </strong>
                    {selectedCustomer?.contact?.displayName}
                  </Typography>
                  <Typography>
                    <strong>Email: </strong> {selectedCustomer?.contact?.email}
                  </Typography>
                  <Typography>
                    <strong>Location: </strong>
                    {selectedCustomer.defaultLocation?.address?.landmark}
                  </Typography>
                  <Typography>
                    <strong>Route: </strong>
                    {selectedCustomer?.defaultLocation?.routeName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Phone: </strong> {selectedCustomer?.contact?.phone}
                  </Typography>
                  <Typography>
                    <strong>Address: </strong>
                    {selectedCustomer?.defaultLocation?.address?.address_1}
                  </Typography>
                  <Typography>
                    <strong>Pincode: </strong>
                    {selectedCustomer?.defaultLocation?.address?.postalCode}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Typography sx={{ margin: "10px" }}>
                <strong>No customer selected.</strong>
              </Typography>
            )}
          </Grid>
        </CardContent>
      </Card>

      <Card
        sx={{ mb: 2, maxHeight: "60vh", overflowY: "auto" }}
        style={{ backgroundColor: "#ebedf0" }}
      >
        <CardContent>
          <div className="product-container">
            <div className="scrollable-content">
              {selectedProducts.map((product, index) => (
                <div
                  key={index}
                  className="product-details"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "10px",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography style={{ flex: 1 }}>
                    <strong>{product.productName}</strong> (SKU: {product.sku})
                    - Qty: {product.quantity} - Price: Rs.{product.standardMrp}{" "}
                    - Total: Rs.{product.quantity * product.standardMrp}
                  </Typography>
                  <div>
                    <IconButton
                      onClick={() => handleDeleteProduct(product.id)}
                      style={{ marginLeft: "10px" }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleStarClick(product)}
                      style={{
                        marginLeft: "10px",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      {favoriteProducts.includes(product) ? (
                        <StarIcon />
                      ) : (
                        <StarBorderIcon />
                      )}
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="total-container"
              style={{ textAlign: "right", paddingTop: "10px" }}
            >
              <Typography variant="h6">
                <strong>Total: </strong>Rs. {total}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
      <Button
        color="primary"
        variant="contained"
        onClick={handleGenerateInvoice} // Handle invoice generation
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.primary[400],
          fontSize: "14px",
          fontWeight: "bold",
          width: "100%",
        }}
      >
        Generate Estimate
      </Button>

      {/* Snackbar for notification */}
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={alertMessage} // Show alert message
        autoHideDuration={3000} // Auto hide after 3 seconds
      />

      {/* Customer Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Select Customer</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search Customer"
            value={searchCustomer}
            onChange={(e) => setSearchCustomer(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <List>
            {filteredCustomers.map((customer) => (
              <ListItem
                button
                key={customer.id}
                onClick={() => handleCustomerSelect(customer)}
              >
                <ListItemText primary={customer.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Product Dialog */}
      <Dialog
        open={openProductDialog}
        onClose={handleCloseProductDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Select Products</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search Product"
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <List>
            {filteredProducts.map((product) => (
              <ListItem
                key={product.id}
                style={{
                  backgroundColor:
                    quantities[product.id] > 0 ? "#e0f7fa" : "white",
                }}
              >
                <ListItemText primary={product.itemName} />
                <TextField
                  type="number"
                  label="Quantity"
                  value={quantities[product.id] || ""}
                  onChange={(e) =>
                    handleQuantityChange(product.id, e.target.value)
                  }
                  style={{ width: "70px", marginLeft: "10px" }}
                  inputProps={{ min: 0 }} // Prevent negative input
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProductDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TakeOrder;
