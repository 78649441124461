import React from "react";
import {
    Box,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "./Invoice.css";
import { useState, useEffect } from "react";
import { iepaProd } from "../../constants/global";

const TripSheet = () => {
    const { state } = useLocation();

    const location = useLocation();

    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [order, setOrder] = useState(null);
    const [orderCustomer, setOrderCustomer] = useState(null);

    // const [orderItems, setOrderItems] = useState(null);

    // let order = null;
    // let orderCustomer = null;

    console.log("Location state::" + state);

    //const { navigation } = this.props;
    //const customerP = navigation.getElementById("customer");
    // const { customerP, orderP } = this.props;
    const { selectedCustomer, selectedProducts, orderNumber, customers } = state;

    console.log('customers', customers)

    // const orderDataFetched = null;

    const fetchOrder = (orderNumber) => {
        if (selectedProducts != null || order != null || !orderNumber) {
            return;
        }
        //console.log("Fetch the Order#" + orderNumber + " selectedOrder::" + order);
        fetch(
            `${iepaProd.iepaOrderMgmtAPI}/orm/order/order_number/${orderNumber}/org/1`
        )
            .then((response) => response.json())
            .then((data) => {
                // Log the data to check structure
                console.log(data);

                setOrder(data);
                // setOrder(data);
                // this.setState({ selectedProducts: data.orderLines });
                //console.log("selectedOrder::" + order);

                return order;
                // return data;
            })
            .catch((error) => {
                if (error.message.substring("JSON input") <= 0) {
                    setError("Error fetching Customer data: " + error.message);
                }
            });
    };

    const fetchCustomer = (customerId) => {
        console.log(orderCustomer?.name);
        console.log(selectedCustomer?.name);
        if (selectedCustomer != null || orderCustomer != null) {
            return;
        }

        fetch(`${iepaProd.iepaUserSrvAPI}/customer/id/${customerId}/org/1`)
            .then((response) => response.json())
            .then((data) => {
                setOrderCustomer(data);
                console.log(data); // Log the data to check structure
                // selectedCustomer = data;
                // this.setState({ selectedCustomer: data });
                // setOrderCustomer(data);
                return orderCustomer;
            })
            .catch((error) =>
                setError("Error fetching Customer data: " + error.message)
            );
    };

    // Calculate total amount
    const totalAmount = selectedProducts
        ? selectedProducts.reduce(
            (acc, product) => acc + product.quantity * product.standardMrp,
            0
        )
        : order
            ? order.orderLines.reduce(
                (acc, product) => acc + product.quantity * product.listPrice,
                0
            )
            : 0;
    const gstAmount = totalAmount * 0.18; // Assuming 18% GST
    const finalAmount = totalAmount + gstAmount;

    const formatDate = (date = new Date()) => {
        return date.toISOString().split("T")[0];
    };

    //Order Lines
    const orderLines = selectedProducts
        ? selectedProducts.map((product, index) => ({
            lineNumber: index + 1,
            itemId: product.id,
            orderedItemSku: product.sku,
            quantity: product.quantity,
            orderQuantityUom: "EA",
            pricingQuantity: product.quantity,
            sellingPrice: product.standardMrp,
            listPrice: product.standardMrp,
            taxAmount: product.standardMrp * 0.18, //GST Standard 18%
            discountAmount: 0,
            shippingAmount: 0,
            lineAmount: product.quantity * product.standardMrp,
            orgId: "1",
            createdBy: 1,
            salesRepId: 1,
            status: "ENTERED",
            currencyCode: "INR",
            taxCurrencyCode: "INR",
        }))
        : null;

    //Order Header
    //console.log("OrderLines::" + orderLines);
    const orderData = selectedCustomer
        ? {
            customerId: selectedCustomer?.id,
            type: "PURCHASE",
            status: "ENTERED",
            subTotalAmount: totalAmount,
            discount: 0,
            netAmount: totalAmount,
            taxAmount: gstAmount,
            shippingAmount: 0,
            totalAmount: finalAmount,
            requestDate: formatDate(),
            pricingDate: formatDate(),
            shippingDate: null,
            bookedDate: formatDate(),
            soldToSiteId: selectedCustomer?.defaultLocation?.id,
            shipToSiteId: selectedCustomer?.defaultLocation?.id,
            billToSiteId: selectedCustomer?.defaultLocation?.id,
            salesRepId: 1,
            orderLines: orderLines,
            createdBy: 1,
            orgId: "1",
        }
        : null;

    // console.log("OrderData::" + JSON.stringify(orderData));

    // console.log(formatDate());

    const saveOrder = () => {
        fetch(`${iepaProd.iepaOrderMgmtAPI}/orm/order/save`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(orderData),
        })
            .then((response) => response.json())
            .then((data) => {
                setMessage("Order added successfully!");
                setError(null);
                //resetForm();
            })
            .catch((error) => {
                setError("Failed to add Order. Please try again.");
                setMessage(null);
            });
    };

    const handleInit = () => {
        if (orderNumber) {
            // const customerDetails = orderNumber ?
            // console.log("Trying to fetch the Order with Number:" + orderNumber);
            fetchOrder(orderNumber);

            // console.log("Got the Order with Id:" + order);

            if (order) {
                //selectedProducts = selectedOrder.orderLines;
                // setOrderItems(selectedOrder.orderLines);
                // selectedProducts = selectedOrder.orderLines;
                console.log("Fetch the Customer with Id:" + order.customerId);
                fetchCustomer(order.customerId);
                // console.log("Order Customer:: " + orderCustomer);
            }
        }
    };
    useEffect(() => {
        // handleInit();
        //saveOrder();
    });
    const handlePrint = () => {
        const printContent = document.getElementById("invoice");
        const printWindow = window.open("", "", "height=600,width=800");
        printWindow.document.write("<html><head><title>Invoice</title>");
        printWindow.document.write(
            '<link rel="stylesheet" href="/path/to/your/Invoice.css" />'
        ); // Add the correct path to your CSS file
        printWindow.document.write("</head><body >");
        printWindow.document.write(printContent.innerHTML);
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        printWindow.print();
    };
    handleInit();
    // const { selectedCustomer, selectedProducts } = state;
    return (
        <Box m="40px">
            <div id="invoice" className="invoice-container">
                <Typography variant="h4" align="center" className="company-name">
                    Skanda Sales
                </Typography>
                <Typography variant="h6" align="center">
                    Orders
                </Typography>

                <div className="customer-details">
                    <Typography variant="h6">
                        <strong>Customer Name:</strong>{" "}
                        {selectedCustomer?.name
                            ? selectedCustomer?.name
                            : orderCustomer?.name}{" "}
                        <br />
                        <strong>Owner Name:</strong>{" "}
                        {selectedCustomer?.ownerName
                            ? selectedCustomer?.ownerName
                            : orderCustomer?.ownerName}{" "}
                        <br />
                        <strong>Email:</strong>{" "}
                        {selectedCustomer?.contact?.email
                            ? selectedCustomer?.contact?.email
                            : orderCustomer?.contact?.email}{" "}
                        <br />
                        <strong>Phone:</strong>{" "}
                        {selectedCustomer?.contact?.phone
                            ? selectedCustomer?.contact?.phone
                            : orderCustomer?.contact?.phone}{" "}
                        <br />
                    </Typography>
                    <Typography variant="h6" align="right">
                        <strong>Address:</strong>{" "}
                        {selectedCustomer?.defaultLocation?.address?.address_1
                            ? selectedCustomer?.defaultLocation?.address?.address_1
                            : orderCustomer?.defaultLocation?.address?.address_1}{" "}
                        <br />
                        <strong>Pincode:</strong>{" "}
                        {selectedCustomer?.defaultLocation?.address?.postalCode
                            ? selectedCustomer?.defaultLocation?.address?.postalCode
                            : orderCustomer?.defaultLocation?.address?.postalCode}{" "}
                        <br />
                        <strong>Location:</strong>{" "}
                        {selectedCustomer?.defaultLocation?.routeDestinationName
                            ? selectedCustomer?.defaultLocation?.routeDestinationName
                            : orderCustomer?.defaultLocation?.routeDestinationName}{" "}
                        <br />
                        <strong>Route:</strong>{" "}
                        {selectedCustomer?.defaultLocation?.routeName
                            ? selectedCustomer?.defaultLocation?.routeName
                            : orderCustomer?.defaultLocation?.routeName}{" "}
                        <br />
                    </Typography>
                </div>

                <Typography variant="h6" className="order-details-title">
                    Order Details
                </Typography>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <strong>Item No.</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Item Description</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Quantity</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Item Location</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Status</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedProducts
                                ? selectedProducts.map((product, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{product.itemName}</TableCell>
                                        <TableCell>{product.sku}</TableCell>
                                        <TableCell>{product.quantity}</TableCell>
                                        <TableCell>Rs. {product.standardMrp}</TableCell>
                                        <TableCell>
                                            Rs. {product.quantity * product.standardMrp}
                                        </TableCell>
                                    </TableRow>
                                ))
                                : order
                                    ? order.orderLines.map((product, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{product.itemName}</TableCell>
                                            <TableCell>{product.orderedItemSku}</TableCell>
                                            <TableCell>{product.quantity}</TableCell>
                                            <TableCell>Rs. {product.listPrice}</TableCell>
                                            <TableCell>
                                                Rs. {product.quantity * product.listPrice}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    : ""}
                            <TableRow>
                                <TableCell colSpan={4} align="right">
                                    <strong>Subtotal:</strong>
                                </TableCell>
                                <TableCell>Rs. {totalAmount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} align="right">
                                    <strong>GST (18%):</strong>
                                </TableCell>
                                <TableCell>Rs. {gstAmount.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} align="right">
                                    <strong>Final Amount:</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Rs. {finalAmount.toFixed(2)}</strong>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="h6" className="gst-details">
                    <strong>GST Registration Number:</strong> 29XXXXXXXXXX <br />
                    <strong>GST Payable:</strong> Rs. {gstAmount.toFixed(2)}
                </Typography>

                <Typography variant="h6" className="terms-title">
                    Terms and Conditions
                </Typography>
                <Typography className="terms-and-conditions">
                    1. The payment is due within 30 days of the invoice date.
                    <br />
                    2. All goods remain the property of Skanda Sales until paid in full.
                    <br />
                    3. Please quote the invoice number in all correspondence.
                    <br />
                    4. For any discrepancies, please contact us within 7 days of receipt.
                    <br />
                    5. Subject to our terms of sale which are available on request.
                    <br />
                </Typography>
            </div>

            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrint}
                    style={{ marginTop: "10px", marginLeft: "120px", marginRight: "10px", marginBottom: "10px" }}
                >
                    Print Tripsheet
                </Button>

                <Link to="/LoadList">
                    <Button variant="contained" color="primary">
                        Close
                    </Button>
                </Link>
            </Box>
        </Box>
    );
};

export default TripSheet;
