import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  IconButton,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Header from "../../components/Header";
import { iepaProd } from "../../constants/global";

const CreateTaxation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0); // To manage active tab
  const navigate = useNavigate(); // For page navigation

  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error

  // Initial rows for each tab
  const initialRowsTaxCategory = [
    {
      id: 1,
      seqNo: "",
      taxCatgName: "",
      activeStatus: false,
      startDate: "",
      endDate: "",
    },
  ];

  const initialRowsTaxName = [
    {
      id: 1,
      seqNo: "",
      taxName: "",
      activeStatus: false,
      startDate: "",
      endDate: "",
    },
  ];

  const initialRowsTaxRate = [
    {
      id: 1,
      seqNo: "",
      taxRateName: "",
      taxRate: "",
      maxTaxValue: "",
      activeStatus: false,
      startDate: "",
      endDate: "",
    },
  ];

  const [rowsTaxCategory, setRowsTaxCategory] = useState(
    initialRowsTaxCategory
  );
  const [rowsTaxName, setRowsTaxName] = useState(initialRowsTaxName);
  const [rowsTaxRate, setRowsTaxRate] = useState(initialRowsTaxRate);

  const [selectedRowsTaxCategory, setSelectedRowsTaxCategory] = useState([]);
  const [selectedRowsTaxName, setSelectedRowsTaxName] = useState([]);
  const [selectedRowsTaxRate, setSelectedRowsTaxRate] = useState([]);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState([]);

  const handleReset = () => {
    switch (activeTab) {
      case 0:
        setRowsTaxCategory(initialRowsTaxCategory);
        break;
      case 1:
        setRowsTaxName(initialRowsTaxName);
        break;
      case 2:
        setRowsTaxRate(initialRowsTaxRate);
        break;
      default:
        break;
    }
  };

  const handleTextValues = (textFieldName, params) => {
    const updatedRows = [...getRowsForActiveTab()];
    const index = updatedRows.findIndex((row) => row.id === params.row.id);
    const targetRow = updatedRows[index];
    targetRow[textFieldName] = params.row[textFieldName];
  };
  const handleCategorySubmit = (values) => {
    console.log("Submitting the Create Tax Category Form");

    let tagRates = [];
    rowsTaxRate.map((rate) => {
      tagRates.push({
        name: rate.taxRateName,
        activeStartDate: rate.activeStartDate,
        activeEndDate: rate.activeStartDate,
        status: rate.activeStatus,
        rate: rate.taxRate,
        orgId: "1",
        createdBy: 1,
      });
    });

    let taxRegime = [];
    rowsTaxName.map((taxName) => {
      taxRegime.push({
        name: taxName.taxName,
        activeStartDate: taxName.activeStartDate,
        activeEndDate: taxName.activeStartDate,
        status: taxName.activeStatus,
        taxRates: tagRates,
        currencyCode: "INR",
        orgId: "1",
        createdBy: 1,
      });
    });

    const taxRealm = {
      name: rowsTaxCategory[0].taxCatgName,
      activeStartDate: rowsTaxCategory[0].activeStartDate,
      activeEndDate: rowsTaxCategory[0].activeStartDate,
      status: rowsTaxCategory[0].activeStatus,
      taxRegime: taxRegime,
      orgId: "1",
      createdBy: 1,
    };

    console.log(taxRealm);

    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/tax/realm/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(taxRealm),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Address added successfully!");
        setError(null);
        handleClose();
      })
      .catch((error) => {
        setError("Failed to add Address. Please try again.");
        setMessage(null);
      });
  };

  // Define columns for the DataGrid
  const columns = [
    { field: "id", headerName: "Seq No.", width: 100 },
    {
      field: "taxCatgName",
      headerName: "Tax Category Name",
      flex: 2,
      width: 200,
      editable: true,
      renderCell: (params) => {
        handleTextValues("taxCatgName", params);
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      minWidth: 180,
      editable: true,
      renderCell: (params) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={params.row.startDate}
              onChange={(newDate) => {
                const updatedRows = [...getRowsForActiveTab()];
                const index = updatedRows.findIndex(
                  (row) => row.id === params.row.id
                );
                updatedRows[index].startDate = newDate;
                updateRowsForActiveTab(updatedRows);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      minWidth: 180,
      editable: true,
      renderCell: (params) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={params.row.endDate}
              onChange={(newDate) => {
                const updatedRows = [...getRowsForActiveTab()];
                const index = updatedRows.findIndex(
                  (row) => row.id === params.row.id
                );
                updatedRows[index].endDate = newDate;
                updateRowsForActiveTab(updatedRows);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      field: "activeStatus",
      headerName: "Active / Inactive Status",
      flex: 1,
      width: 180,
      editable: true,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.activeStatus}
          onChange={(e) => {
            const updatedRows = [...getRowsForActiveTab()];
            const index = updatedRows.findIndex(
              (row) => row.id === params.row.id
            );
            updatedRows[index].activeStatus = e.target.checked;
            updateRowsForActiveTab(updatedRows);
          }}
        />
      ),
    },
  ];

  const taxNameColumns = [
    { field: "id", headerName: "Seq No.", width: 100 },
    {
      field: "taxName",
      headerName: "Tax Name",
      flex: 2,
      width: 200,
      editable: true,
      renderCell: (params) => {
        handleTextValues("taxName", params);
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      minWidth: 180,
      editable: true,
      renderCell: (params) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={params.row.startDate}
              onChange={(newDate) => {
                const updatedRows = [...getRowsForActiveTab()];
                const index = updatedRows.findIndex(
                  (row) => row.id === params.row.id
                );
                updatedRows[index].startDate = newDate;
                updateRowsForActiveTab(updatedRows);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      minWidth: 180,
      editable: true,
      renderCell: (params) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={params.row.endDate}
              onChange={(newDate) => {
                const updatedRows = [...getRowsForActiveTab()];
                const index = updatedRows.findIndex(
                  (row) => row.id === params.row.id
                );
                updatedRows[index].endDate = newDate;
                updateRowsForActiveTab(updatedRows);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      field: "activeStatus",
      headerName: "Active / Inactive Status",
      flex: 1,
      width: 180,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.activeStatus}
          onChange={(e) => {
            const updatedRows = [...getRowsForActiveTab()];
            const index = updatedRows.findIndex(
              (row) => row.id === params.row.id
            );
            updatedRows[index].activeStatus = e.target.checked;
            updateRowsForActiveTab(updatedRows);
          }}
        />
      ),
    },
  ];

  const taxRateColumns = [
    { field: "id", headerName: "Seq No.", width: 100 },
    {
      field: "taxRateName",
      headerName: "Tax Rate Name",
      flex: 2,
      width: 200,
      editable: true,
      renderCell: (params) => {
        handleTextValues("taxRateName", params);
      },
    },
    {
      field: "taxRate",
      headerName: "Tax Rate",
      flex: 2,
      width: 200,
      editable: true,
      renderCell: (params) => {
        handleTextValues("taxRate", params);
      },
    },
    {
      field: "maxTaxValue",
      headerName: "Max Tax Value",
      flex: 2,
      width: 200,
      editable: true,
      renderCell: (params) => {
        handleTextValues("maxTaxValue", params);
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      minWidth: 180,
      editable: true,
      renderCell: (params) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={params.row.startDate}
              onChange={(newDate) => {
                const updatedRows = [...getRowsForActiveTab()];
                const index = updatedRows.findIndex(
                  (row) => row.id === params.row.id
                );
                updatedRows[index].startDate = newDate;
                updateRowsForActiveTab(updatedRows);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      minWidth: 180,
      editable: true,
      renderCell: (params) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={params.row.endDate}
              onChange={(newDate) => {
                const updatedRows = [...getRowsForActiveTab()];
                const index = updatedRows.findIndex(
                  (row) => row.id === params.row.id
                );
                updatedRows[index].endDate = newDate;
                updateRowsForActiveTab(updatedRows);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      field: "activeStatus",
      headerName: "Active / Inactive Status",
      flex: 1,
      width: 180,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.activeStatus}
          onChange={(e) => {
            const updatedRows = [...getRowsForActiveTab()];
            const index = updatedRows.findIndex(
              (row) => row.id === params.row.id
            );
            updatedRows[index].activeStatus = e.target.checked;
            updateRowsForActiveTab(updatedRows);
          }}
        />
      ),
    },
  ];

  // Get the rows for the active tab
  const getRowsForActiveTab = () => {
    switch (activeTab) {
      case 0:
        return rowsTaxCategory;
      case 1:
        return rowsTaxName;
      case 2:
        return rowsTaxRate;
      default:
        return [];
    }
  };

  const getAddNewForActiveTab = () => {
    switch (activeTab) {
      case 0:
        return initialRowsTaxCategory;
      case 1:
        return initialRowsTaxName;
      case 2:
        return initialRowsTaxRate;
      default:
        return [];
    }
  };

  // Update the rows for the active tab
  const updateRowsForActiveTab = (updatedRows) => {
    switch (activeTab) {
      case 0:
        setRowsTaxCategory(updatedRows);
        break;
      case 1:
        setRowsTaxName(updatedRows);
        break;
      case 2:
        setRowsTaxRate(updatedRows);
        break;
      default:
        break;
    }
  };

  const handleTabChange = (event, newValue) => {
    const updatedRows = getRowsForActiveTab();
    // console.log(updatedRows);
    setActiveTab(newValue);
    updateRowsForActiveTab(updatedRows);
  };

  const handleAddRow = (event) => {
    const rows = getRowsForActiveTab();
    const newId = rows.length ? Math.max(...rows.map((row) => row.id)) + 1 : 1;

    // const newRow = {
    //   id: newId,
    //   seqNo: "",
    //   taxCatgName: "",
    //   activeStatus: false,
    //   startDate: "",
    //   endDate: "",
    // };

    let newRow = getAddNewForActiveTab();

    newRow[0].id = newId;

    const updatedRows = [...rows, newRow[0]];
    updateRowsForActiveTab(updatedRows);
  };

  const handleDeleteRow = () => {
    // Get the selected row IDs based on the active tab
    const selectedRowIds = getSelectedRowIds();

    if (selectedRowIds.length === 0) {
      alert("Please select rows to delete.");
      return;
    }

    // Store the selected rows to be deleted
    setRowsToDelete(selectedRowIds);

    // Open the confirmation modal
    setOpenConfirmDelete(true);
  };

  const handleConfirmDelete = () => {
    // Get rows based on the active tab
    const rows = getRowsForActiveTab();

    // Filter out the rows that are selected for deletion
    const updatedRows = rows.filter((row) => !rowsToDelete.includes(row.id));

    // Update the appropriate rows state based on the active tab
    updateRowsForActiveTab(updatedRows);

    // Close the confirmation modal
    setOpenConfirmDelete(false);

    // Clear selected rows to delete
    setRowsToDelete([]);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDelete(false); // Just close the modal without deleting
    setRowsToDelete([]); // Clear selected rows to delete
  };

  const getSelectedRowIds = () => {
    switch (activeTab) {
      case 0:
        return selectedRowsTaxCategory;
      case 1:
        return selectedRowsTaxName;
      case 2:
        return selectedRowsTaxRate;
      default:
        return [];
    }
  };

  const handleClose = () => {
    navigate("/Taxation");
  };

  const tabContent = [
    {
      label: "Tax Category",
      content: (
        <>
          <Button
            onClick={handleAddRow}
            color="primary"
            variant="contained"
            disabled={true}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[400],
              fontSize: "14px",
              fontWeight: "bold",
              margin: "10px",
            }}
          >
            Add Row
          </Button>
          <Button
            onClick={handleDeleteRow}
            color="secondary"
            variant="contained"
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.primary[400],
              fontSize: "14px",
              fontWeight: "bold",
              margin: "10px",
            }}
          >
            Delete Row
          </Button>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rowsTaxCategory}
              columns={columns}
              rowsPerPageOptions={[5]}
              disableRowSelectionOnClick
              checkboxSelection
              onRowSelectionModelChange={(newSelection) => {
                setSelectedRowsTaxCategory(newSelection);
              }}
              components={{ Toolbar: GridToolbar }}
              // editable:true
              // editMode="row"
            />
            {/* Confirmation Delete Modal */}
            <Dialog
              open={openConfirmDelete}
              onClose={handleCancelDelete}
              aria-labelledby="confirm-delete-title"
              aria-describedby="confirm-delete-description"
              sx={{
                "& .MuiDialog-paper": {
                  overflow: "hidden", // Prevent horizontal scrolling
                },
              }}
            >
              <DialogTitle
                id="confirm-delete-title"
                sx={{ position: "relative" }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Confirm Deletion
                </Typography>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCancelDelete}
                  sx={{ position: "absolute", top: 8, right: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent sx={{ padding: "16px" }}>
                <Typography variant="body1">
                  Are you sure you want to delete the selected rows?
                </Typography>
              </DialogContent>

              <DialogActions
                sx={{ justifyContent: "space-between", padding: "16px" }}
              >
                <Button
                  onClick={handleConfirmDelete}
                  color="primary"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "0 8px", // Equal spacing between buttons
                  }}
                >
                  Yes, Delete
                </Button>
                <Button
                  onClick={handleCancelDelete}
                  color="primary"
                  variant="outlined"
                  sx={{
                    margin: "0 8px", // Equal spacing between buttons
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Box display="flex" gap={2}>
            <Button
              type="submit"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[400],
                fontSize: "14px",
                fontWeight: "bold",
                margin: "10px",
              }}
              onClick={() => {
                handleCategorySubmit(); // Reset form when clicked
              }}
              variant="contained"
            >
              Save
            </Button>

            <Button
              type="submit"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[400],
                fontSize: "14px",
                fontWeight: "bold",
                margin: "10px",
              }}
              variant="contained"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>
        </>
      ),
    },
    {
      label: "Tax Name",
      content: (
        <>
          <Button
            onClick={handleAddRow}
            color="primary"
            variant="contained"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[400],
              fontSize: "14px",
              fontWeight: "bold",
              margin: "10px",
            }}
          >
            Add Row
          </Button>
          <Button
            onClick={handleDeleteRow}
            color="secondary"
            variant="contained"
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.primary[400],
              fontSize: "14px",
              fontWeight: "bold",
              margin: "10px",
            }}
          >
            Delete Row
          </Button>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rowsTaxName}
              columns={taxNameColumns}
              rowsPerPageOptions={[5]}
              disableRowSelectionOnClick
              checkboxSelection
              onRowSelectionModelChange={(newSelection) => {
                setSelectedRowsTaxName(newSelection);
              }}
              components={{ Toolbar: GridToolbar }}
            />

            {/* Confirmation Delete Modal */}
            <Dialog
              open={openConfirmDelete}
              onClose={handleCancelDelete}
              aria-labelledby="confirm-delete-title"
              aria-describedby="confirm-delete-description"
              sx={{
                "& .MuiDialog-paper": {
                  overflow: "hidden", // Prevent horizontal scrolling
                },
              }}
            >
              <DialogTitle
                id="confirm-delete-title"
                sx={{ position: "relative" }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Confirm Deletion
                </Typography>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCancelDelete}
                  sx={{ position: "absolute", top: 8, right: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent sx={{ padding: "16px" }}>
                <Typography variant="body1">
                  Are you sure you want to delete the selected rows?
                </Typography>
              </DialogContent>

              <DialogActions
                sx={{ justifyContent: "space-between", padding: "16px" }}
              >
                <Button
                  onClick={handleConfirmDelete}
                  color="primary"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "0 8px", // Equal spacing between buttons
                  }}
                >
                  Yes, Delete
                </Button>
                <Button
                  onClick={handleCancelDelete}
                  color="primary"
                  variant="outlined"
                  sx={{
                    margin: "0 8px", // Equal spacing between buttons
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      ),
    },
    {
      label: "Tax Rate",
      content: (
        <>
          <Button
            onClick={handleAddRow}
            color="primary"
            variant="contained"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[400],
              fontSize: "14px",
              fontWeight: "bold",
              margin: "10px",
            }}
          >
            Add Row
          </Button>
          <Button
            onClick={handleDeleteRow}
            color="secondary"
            variant="contained"
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.primary[400],
              fontSize: "14px",
              fontWeight: "bold",
              margin: "10px",
            }}
          >
            Delete Row
          </Button>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rowsTaxRate}
              columns={taxRateColumns}
              rowsPerPageOptions={[5]}
              disableRowSelectionOnClick
              checkboxSelection
              onRowSelectionModelChange={(newSelection) => {
                setSelectedRowsTaxRate(newSelection);
              }}
              components={{ Toolbar: GridToolbar }}
            />

            {/* Confirmation Delete Modal */}
            <Dialog
              open={openConfirmDelete}
              onClose={handleCancelDelete}
              aria-labelledby="confirm-delete-title"
              aria-describedby="confirm-delete-description"
              sx={{
                "& .MuiDialog-paper": {
                  overflow: "hidden", // Prevent horizontal scrolling
                },
              }}
            >
              <DialogTitle
                id="confirm-delete-title"
                sx={{ position: "relative" }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Confirm Deletion
                </Typography>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCancelDelete}
                  sx={{ position: "absolute", top: 8, right: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent sx={{ padding: "16px" }}>
                <Typography variant="body1">
                  Are you sure you want to delete the selected rows?
                </Typography>
              </DialogContent>

              <DialogActions
                sx={{ justifyContent: "space-between", padding: "16px" }}
              >
                <Button
                  onClick={handleConfirmDelete}
                  color="primary"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "0 8px", // Equal spacing between buttons
                  }}
                >
                  Yes, Delete
                </Button>
                <Button
                  onClick={handleCancelDelete}
                  color="primary"
                  variant="outlined"
                  sx={{
                    margin: "0 8px", // Equal spacing between buttons
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Add/Edit Taxation" />
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 10, right: 10 }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "16px", marginRight: "5px" }}
        >
          Close
        </Typography>
        <CloseIcon />
      </IconButton>
      <Card>
        <CardContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabContent.map((tab, index) => (
              <Tab
                index={index}
                key={index}
                label={tab.label}
                sx={{
                  color: activeTab === index ? "black" : colors.blueAccent[700],
                  fontWeight: activeTab === index ? "bold" : "normal",
                }}
              />
            ))}
          </Tabs>

          <Box mt="20px">{tabContent[activeTab].content}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CreateTaxation;
