import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // As

const Logout = () => {
  const navigate = useNavigate(); // For page navigation
  const [logoutUser, setLogoutUser] = useState([]);

  const handleLogout = () => {
    // console.log("User Logged out!");
    localStorage.clear();
    // console.log("User token::" + localStorage.getItem("token"));
    navigate("/");
    window.location.reload(false);
  };

  useEffect(() => {
    handleLogout();

    // Hide the rest of the app when the login page is displayed
  }, []);

  return <div>Logout</div>;
};

export default Logout;
